import { CohortState } from '@om1/cohort-module'
import { cohortListActions } from '@om1/cohort-module/src/state'
import { withRouter } from '@om1/platform-routing'
import { connectRedux } from '@om1/platform-utils'
import { PlatformSettingsState } from '../state'
import { rwaConditionsActions } from '../state/rwa-conditions'
import { rwaInsightsReportActions } from '../state/rwa-insights-reports'
import { AddRWAConditionModalComponent } from './AddRWAConditionModalComponent'

export function createAddRWAConditionModal<TState extends { cohort: CohortState; platformSettings: PlatformSettingsState }>() {
    return connectRedux(
        withRouter(AddRWAConditionModalComponent, { namespace: 'rwa-conditions', dataFetchAction: 'cohortsGetSystemCohorts' }),
        (state: TState) => {
            return { ...state.cohort, ...state.platformSettings.rwaInsightsReports }
        },
        { ...cohortListActions, ...rwaConditionsActions, ...rwaInsightsReportActions }
    )
}

export type AddRWAConditionModal = ReturnType<typeof createAddRWAConditionModal>
