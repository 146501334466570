import { connectRedux } from '@om1/platform-utils'
import { get } from 'lodash'
import { cohortBlocksEditActions, CohortState, CriterionNode } from '../../../state'
import { findNode } from '../../../state/edit/blocks/cohort-blocks-edit-reducer'
import { ObservationScoreEditDialogComponent } from './ObservationScoreEditDialogComponent'
export function createObservationScoreEditDialogComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        ObservationScoreEditDialogComponent,
        (state: TState) => {
            const nodeId = state.cohort.edit.blocks.ui.observationScoreEditDialog.target.nodeId

            return {
                criteria: nodeId ? (findNode(state.cohort.edit.blocks.tree, nodeId) as CriterionNode) : undefined,
                resultTypeMap: get(state.cohort.edit.blocks.ui.refLabels, ['patient_observation', 'result_type'], {})
            }
        },
        cohortBlocksEditActions
    )
}

export type ObservationScoreEditDialog = ReturnType<typeof createObservationScoreEditDialogComponent>
