import { PlatformPermissions } from '@om1/platform-utils'
import { ComponentType, ReactElement } from 'react'

export enum CohortRoutes {
    COHORTBUILD = 'cohort-builder',
    DATASETS = 'dataset',
    CUSTOM = 'custom',
    PRINT = 'print',
    EDIT = 'edit',
    REPORTS = 'reports',
    DEMOGRAPHICS = 'demographics',
    MEDICATIONS = 'medications',
    DIAGNOSES = 'diagnoses',
    OBSERVATIONS = 'observations',
    DASHBOARD = 'dashboard',
    WIZARD = 'wizard',
    CHAT = 'chat'
}

// Route definition
export type PlatformRoute = {
    route: string
    component: ComponentType<any>
    permissions: PlatformPermissions[]
    name?: ReactElement<any, any>
    icon?: ReactElement<any, any>
}

export function toRoutingConfigObject<T extends { route: string }>(
    routes: T[],
    matchPathValue: string,
    matchUrlValue: string
): (T & { url: string; matchRoute: { path: string } })[] {
    return routes.map((routeRecord) => {
        const routeProps = { path: `${matchPathValue}/${routeRecord.route}` }
        const url = `${matchUrlValue}/${routeRecord.route}`
        return { matchRoute: routeProps, url, ...routeRecord }
    })
}
