import { ExplorerAssistantConversationActions, ExplorerAssistantConversationActionTypes } from './explorer-assistant-conversation-actions'
import { ExplorerAssistantConversationState, initialExplorerAssistantConversationState } from './explorer-assistant-conversation-state'

export function explorerAssistantConversationReducer(
    previousState: ExplorerAssistantConversationState | undefined,
    action: ExplorerAssistantConversationActions
): ExplorerAssistantConversationState {
    const state = previousState || initialExplorerAssistantConversationState

    switch (action.type) {
        case ExplorerAssistantConversationActionTypes.SET_ACTIVE_CONVERSATION: {
            return { ...state, activeConversation: action.payload }
        }
        case ExplorerAssistantConversationActionTypes.CLEAR_ACTIVE_CONVERSATION: {
            return { ...state, activeConversation: undefined }
        }
        case ExplorerAssistantConversationActionTypes.GET_CONVERSATIONS_SUCCESS: {
            const updatedConversations = state.conversations.map((existing) => {
                const updated = action.payload.data.find((conv) => conv.id === existing.id)
                return updated || existing
            })
            const newConversations = action.payload.data.filter((conv) => !state.conversations.some((existing) => existing.id === conv.id))
            return {
                ...state,
                conversations: [...updatedConversations, ...newConversations],
                paginateMeta: action.payload.meta
            }
        }
        case ExplorerAssistantConversationActionTypes.SET_CONVERSATIONS_LOADING: {
            return { ...state, isLoading: action.payload }
        }
        case ExplorerAssistantConversationActionTypes.CREATE_CONVERSATION_SUCCESS: {
            return { ...state, conversations: [action.payload, ...state.conversations] }
        }
        case ExplorerAssistantConversationActionTypes.APPEND_RESPONSE: {
            if (!state.activeConversation) return state
            return {
                ...state,
                activeConversation: {
                    ...state.activeConversation,
                    messages: [...state.activeConversation.messages, action.payload]
                }
            }
        }
        case ExplorerAssistantConversationActionTypes.UPDATE_CONVERSATION_SUCCESS: {
            return {
                ...state,
                conversations: state.conversations.map((conv) => (conv.id === action.payload.id ? action.payload : conv)),
                activeConversation:
                    state.activeConversation?.id === action.payload.id
                        ? { ...action.payload, messages: state.activeConversation.messages }
                        : state.activeConversation
            }
        }
        case ExplorerAssistantConversationActionTypes.DELETE_CONVERSATION_SUCCESS: {
            return {
                ...state,
                conversations: state.conversations.filter((conv) => conv.id !== action.payload.id),
                activeConversation: state.activeConversation?.id === action.payload.id ? undefined : state.activeConversation
            }
        }
        case ExplorerAssistantConversationActionTypes.SET_ASSISTANT_IS_RESPONDING: {
            return { ...state, assistantIsResponding: action.payload }
        }
        default: {
            return state
        }
    }
}
