import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { uniq } from 'lodash'
import { FunctionComponent, useCallback, useEffect } from 'react'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { CohortListItem, CriteriaType, DimensionGroupingType, ReportAnalyticsType, getReportTabLabel, reportsActions } from '../../state'
import {
    DIAGNOSIS_REF_FIELD_MAPPER_CONFIG,
    LAB_REF_FIELD_MAPPER_CONFIG,
    MEDICATION_REF_FIELD_MAPPER_CONFIG,
    OBSERVATION_REF_FIELD_MAPPER_CONFIG,
    PROCEDURE_REF_FIELD_MAPPER_CONFIG
} from '../edit/blocks/RefTreeDialog'
import {
    DiagnosisCuiRefFieldMapper,
    DiagnosisGemMappedIcd10RefFieldMapper,
    DiagnosisIcd10RefFieldMapper,
    LabRefFieldMapper,
    MedicationCuiRefFieldMapper,
    MedicationNdcRefFieldMapper,
    MedicationRefFieldMapper,
    ObservationRefFieldMapper,
    ProcedureRefFieldMapper
} from '../edit/utils/ref-field-mappers'
import { createDataTypeReportChart } from '../shared/DataTypeReportChart'
import { createDemographicsReportChart } from './DemographicsReportChart'
import { createReadOnlyCohortComponent } from './ReadOnlyCohort'
import { createReportChartComponent } from './ReportChart'
import { ReportChartConfig } from './ReportChartComponent'
const DataTypeReportChart = createDataTypeReportChart()
const DemographicsReportChart = createDemographicsReportChart()
const ReportChartComponent = createReportChartComponent()
const ReadOnlyCohort = createReadOnlyCohortComponent()

export type CohortReportsComponentProps = RoutedFrameworkComponentProps<
    {},
    { cohortId: string; reportType: ReportAnalyticsType },
    { isOM1User: boolean },
    typeof reportsActions,
    { cohort: CohortListItem }
>

export const DIAGNOSES_REPORT_CONFIG: ReportChartConfig = {
    mappers: DIAGNOSIS_REF_FIELD_MAPPER_CONFIG,
    type: CriteriaType.Diagnosis,
    groupingType: DimensionGroupingType.Default,
    chartLabelKeys: uniq([
        ...DiagnosisIcd10RefFieldMapper.filterLabelOrder,
        ...DiagnosisGemMappedIcd10RefFieldMapper.filterLabelOrder,
        ...DiagnosisCuiRefFieldMapper.filterLabelOrder
    ])
}

export const MEDICATIONS_REPORT_CONFIG: ReportChartConfig = {
    mappers: MEDICATION_REF_FIELD_MAPPER_CONFIG,
    type: CriteriaType.Medication,
    groupingType: DimensionGroupingType.Default,
    chartLabelKeys: uniq([
        ...MedicationRefFieldMapper.filterLabelOrder,
        ...MedicationNdcRefFieldMapper.filterLabelOrder,
        ...MedicationCuiRefFieldMapper.filterLabelOrder
    ])
}

export const OBSERVATIONS_REPORT_CONFIG: ReportChartConfig = {
    mappers: OBSERVATION_REF_FIELD_MAPPER_CONFIG,
    type: CriteriaType.Observation,
    groupingType: DimensionGroupingType.Default,
    chartLabelKeys: uniq(ObservationRefFieldMapper.filterLabelOrder)
}

export const PROCEDURES_REPORT_CONFIG: ReportChartConfig = {
    mappers: PROCEDURE_REF_FIELD_MAPPER_CONFIG,
    type: CriteriaType.Procedure,
    groupingType: DimensionGroupingType.Default,
    chartLabelKeys: uniq(ProcedureRefFieldMapper.filterLabelOrder)
}

export const LAB_TESTS_REPORT_CONFIG: ReportChartConfig = {
    mappers: LAB_REF_FIELD_MAPPER_CONFIG,
    type: CriteriaType.LabTest,
    groupingType: DimensionGroupingType.Default,
    chartLabelKeys: uniq(LabRefFieldMapper.filterLabelOrder)
}

export const CohortReportsComponent: FunctionComponent<CohortReportsComponentProps> = ({ state, props, routing, actions }) => {
    const history = useHistory()
    const match = useRouteMatch()
    const { cohort } = props
    const routingReportType = routing.pathParams.reportType

    const changeReportType = useCallback(
        (reportType: ReportAnalyticsType) => {
            history.push(`${match.url}/${reportType}`)
        },
        [history, match.url]
    )

    useEffect(() => {
        if (!routingReportType || !Object.values(ReportAnalyticsType).includes(routingReportType as ReportAnalyticsType)) {
            const defaultReportType = ReportAnalyticsType.DEMOGRAPHICS
            changeReportType(defaultReportType)
        }
    }, [changeReportType, routingReportType])

    useEffect(() => {
        if (cohort.id) {
            actions.reportsSet({ reports: [] })
            actions.reportsGet({ cohortId: cohort.id })
        }
    }, [cohort.id, actions])

    const reportTabs: {
        [key in ReportAnalyticsType]: {
            type: ReportAnalyticsType
            component: JSX.Element
        }
    } = {
        [ReportAnalyticsType.DEMOGRAPHICS]: {
            type: ReportAnalyticsType.DEMOGRAPHICS,
            component: <DemographicsReportChart cohort={cohort} />
        },
        [ReportAnalyticsType.DATA_TYPE]: {
            type: ReportAnalyticsType.DATA_TYPE,
            component: <DataTypeReportChart cohort={cohort} />
        },
        [ReportAnalyticsType.DIAGNOSES]: {
            type: ReportAnalyticsType.DIAGNOSES,
            component: <ReportChartComponent config={DIAGNOSES_REPORT_CONFIG} reportAnalyticsType={ReportAnalyticsType.DIAGNOSES} cohort={cohort} />
        },
        [ReportAnalyticsType.MEDICATIONS]: {
            type: ReportAnalyticsType.MEDICATIONS,
            component: (
                <ReportChartComponent config={MEDICATIONS_REPORT_CONFIG} reportAnalyticsType={ReportAnalyticsType.MEDICATIONS} cohort={cohort} />
            )
        },
        [ReportAnalyticsType.OBSERVATIONS]: {
            type: ReportAnalyticsType.OBSERVATIONS,
            component: (
                <ReportChartComponent config={OBSERVATIONS_REPORT_CONFIG} reportAnalyticsType={ReportAnalyticsType.OBSERVATIONS} cohort={cohort} />
            )
        },
        [ReportAnalyticsType.PROCEDURES]: {
            type: ReportAnalyticsType.PROCEDURES,
            component: <ReportChartComponent config={PROCEDURES_REPORT_CONFIG} reportAnalyticsType={ReportAnalyticsType.PROCEDURES} cohort={cohort} />
        },
        [ReportAnalyticsType.LAB_TESTS]: {
            type: ReportAnalyticsType.LAB_TESTS,
            component: <ReportChartComponent config={LAB_TESTS_REPORT_CONFIG} reportAnalyticsType={ReportAnalyticsType.LAB_TESTS} cohort={cohort} />
        },
        [ReportAnalyticsType.COHORT]: {
            type: ReportAnalyticsType.COHORT,
            component: <ReadOnlyCohort cohort={cohort} />
        }
    }

    return (
        <Box width='100%' flexDirection='column' marginBottom={1}>
            <Box paddingTop='16px' style={{ backgroundColor: '#f5f5f5' }}>
                <Box boxShadow={2} width={'fit-content'}>
                    {Object.values(reportTabs).map(({ type }) => {
                        if (type === ReportAnalyticsType.COHORT && cohort.isSystem) {
                            return null
                        } else {
                            return (
                                <Button
                                    key={`tabbutton-${type}`}
                                    component={Link}
                                    to={match.url.replace(routingReportType as string, type)}
                                    aria-current={routing.pathParams.reportType === type ? 'true' : 'false'}
                                    sx={{
                                        flex: 1, // Use flex to distribute width evenly
                                        minWidth: '110px',
                                        width: '10vw',
                                        borderRadius: 0,
                                        borderBottomWidth: 3,
                                        borderBottomStyle: 'solid',
                                        borderBottomColor: routing.pathParams.reportType === type ? 'primary.main' : 'transparent',
                                        '&:hover': {
                                            borderBottomColor: routing.pathParams.reportType === type ? 'primary.main' : 'secondary.main'
                                        },
                                        backgroundColor: 'white'
                                    }}
                                >
                                    {getReportTabLabel(type)}
                                </Button>
                            )
                        }
                    })}
                </Box>
            </Box>
            <Box
                sx={{ paddingRight: '60px', paddingLeft: '60px', paddingBottom: '12px', paddingTop: '32px' }}
                boxShadow={2}
                p={2}
                zIndex={1}
                position='relative'
                style={{ backgroundColor: '#ffffff' }}
            >
                {routing.pathParams.reportType && reportTabs[routing.pathParams.reportType as string].component}
            </Box>
        </Box>
    )
}
