import {
    AssistantConversationDetailDTO,
    AssistantConversationDTO,
    ExplorerAssistantConversationService,
    PaginatedDTO_AssistantConversationDTO_
} from '@om1/falcon-api'
import { DataFetchParams } from '@om1/platform-routing/routing'
import { ActionsUnion, createAction } from '@om1/platform-utils'

export enum ExplorerAssistantConversationActionTypes {
    GET_CONVERSATION = '@@explorer-assistant/conversation/get-conversation',
    GET_CONVERSATIONS = '@@explorer-assistant/conversation/get-conversations',
    GET_CONVERSATIONS_SUCCESS = '@@explorer-assistant/conversation/get-conversations-success',
    CREATE_CONVERSATION = '@@explorer-assistant/conversation/create-conversation',
    CREATE_CONVERSATION_SUCCESS = '@@explorer-assistant/conversation/create-conversation-success',
    SET_ACTIVE_CONVERSATION = '@@explorer-assistant/conversation/set-active-conversation',
    CLEAR_ACTIVE_CONVERSATION = '@@explorer-assistant/conversation/clear-active-conversation',
    SET_CONVERSATIONS_LOADING = '@@explorer-assistant/conversation/set-conversations-loading',
    INVOKE_CONVERSATION = '@@explorer-assistant/conversation/invoke-conversation',
    APPEND_RESPONSE = '@@explorer-assistant/conversation/append-response',
    UPDATE_CONVERSATION = '@@explorer-assistant/conversation/update-conversation',
    DELETE_CONVERSATION = '@@explorer-assistant/conversation/delete-conversation',
    DELETE_CONVERSATION_SUCCESS = '@@explorer-assistant/conversation/delete-conversation-success',
    UPDATE_CONVERSATION_SUCCESS = '@@explorer-assistant/conversation/update-conversation-success',
    SET_ASSISTANT_IS_RESPONDING = '@@explorer-assistant/conversation/set-assistant-is-responding'
}

export const explorerAssistantConversationActions = {
    getConversation: (
        params: DataFetchParams<
            Pick<Parameters<typeof ExplorerAssistantConversationService.getExplorerAssistantConversationIdGet>[0], 'debugMessages'>,
            Pick<Parameters<typeof ExplorerAssistantConversationService.getExplorerAssistantConversationIdGet>[0], 'id'>
        >
    ) => createAction(ExplorerAssistantConversationActionTypes.GET_CONVERSATION, params),
    getConversations: (
        params: DataFetchParams<Parameters<typeof ExplorerAssistantConversationService.indexExplorerAssistantConversationGet>[0], {}>
    ) => createAction(ExplorerAssistantConversationActionTypes.GET_CONVERSATIONS, params),
    getConversationsSuccess: (payload: PaginatedDTO_AssistantConversationDTO_) =>
        createAction(ExplorerAssistantConversationActionTypes.GET_CONVERSATIONS_SUCCESS, payload),
    createConversation: (
        params: Pick<Parameters<typeof ExplorerAssistantConversationService.createExplorerAssistantConversationPost>[0], 'requestBody'>
    ) => createAction(ExplorerAssistantConversationActionTypes.CREATE_CONVERSATION, params),
    createConversationSuccess: (payload: AssistantConversationDTO) =>
        createAction(ExplorerAssistantConversationActionTypes.CREATE_CONVERSATION_SUCCESS, payload),
    setActiveConversation: (payload: AssistantConversationDetailDTO) =>
        createAction(ExplorerAssistantConversationActionTypes.SET_ACTIVE_CONVERSATION, payload),
    clearActiveConversation: () => createAction(ExplorerAssistantConversationActionTypes.CLEAR_ACTIVE_CONVERSATION, undefined),
    setConversationsLoading: (payload: boolean) => createAction(ExplorerAssistantConversationActionTypes.SET_CONVERSATIONS_LOADING, payload),
    invokeConversation: (
        payload: Parameters<typeof ExplorerAssistantConversationService.assistantInvokeExplorerAssistantConversationIdInvokePost>[0]
    ) => createAction(ExplorerAssistantConversationActionTypes.INVOKE_CONVERSATION, payload),
    appendResponse: (payload: Record<string, any>) => createAction(ExplorerAssistantConversationActionTypes.APPEND_RESPONSE, payload),
    updateConversation: (payload: Parameters<typeof ExplorerAssistantConversationService.patchExplorerAssistantConversationIdPatch>[0]) =>
        createAction(ExplorerAssistantConversationActionTypes.UPDATE_CONVERSATION, payload),
    deleteConversation: (payload: Parameters<typeof ExplorerAssistantConversationService.deleteExplorerAssistantConversationIdDelete>[0]) =>
        createAction(ExplorerAssistantConversationActionTypes.DELETE_CONVERSATION, payload),
    deleteConversationSuccess: (payload: { id: string }) =>
        createAction(ExplorerAssistantConversationActionTypes.DELETE_CONVERSATION_SUCCESS, payload),
    updateConversationSuccess: (payload: AssistantConversationDTO) =>
        createAction(ExplorerAssistantConversationActionTypes.UPDATE_CONVERSATION_SUCCESS, payload),
    setAssistantIsResponding: (payload: boolean) => createAction(ExplorerAssistantConversationActionTypes.SET_ASSISTANT_IS_RESPONDING, payload)
}

export type ExplorerAssistantConversationActions = ActionsUnion<typeof explorerAssistantConversationActions>
