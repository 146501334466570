import { yupResolver } from '@hookform/resolvers/yup'
import { t, Trans } from '@lingui/macro'
import { Box, Button, DialogActions, DialogContent, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { isUndefined } from 'lodash'
import { ReactNode, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { cohortBlocksEditActions, CriterionNode, FilterValueQualifier, QualifierOperator, QualifierType } from '../../../state'
import { findQualifierByType } from '../utils/finders'
import { ObservationScoreField } from './ObservationScoreEditDialogComponent'

interface ObservationNumericScoreEditFormValues {
    operator: QualifierOperator
    field: string
    primaryInput: number
    secondaryInput?: number
}

export type ObservationNumericScoreEditDialogComponentProps = FrameworkComponentProps<
    {
        criteria: CriterionNode
    },
    typeof cohortBlocksEditActions
>

export const ObservationNumericScoreEditDialogComponent = ({ state, actions }: ObservationNumericScoreEditDialogComponentProps) => {
    const { criteria } = state

    const validationSchema = Yup.object().shape({
        operator: Yup.string(),
        field: Yup.string().oneOf(Object.values(ObservationScoreField)),
        primaryInput: Yup.number().required(),
        secondaryInput: Yup.number().when('operator', {
            is: (operator: string) => operator === QualifierOperator.Between || operator === QualifierOperator.NotBetween,
            then: (schema) => {
                return schema.typeError(t`Must be a number`).required()
            },
            otherwise: () => Yup.number().notRequired()
        })
    })

    const initialValue = useMemo(() => {
        const existingQualifier = findQualifierByType<FilterValueQualifier>(criteria.qualifiers, QualifierType.FilterQualifierDTO)

        if (!existingQualifier) {
            return {
                operator: QualifierOperator.GreaterThan,
                field: ObservationScoreField.Numeric,
                primaryInput: 0,
                secondaryInput: null
            }
        }

        const { operator, values, field } = existingQualifier

        return {
            operator,
            field: field.replace('_result', ''),
            primaryInput: values[0],
            secondaryInput: values.length > 1 ? values[1] : undefined
        }
    }, [criteria])

    const {
        control,
        handleSubmit,
        register,
        watch,
        formState: { errors }
    } = useForm<ObservationNumericScoreEditFormValues>({
        defaultValues: {
            operator: initialValue.operator,
            field: initialValue.field,
            primaryInput: Number(initialValue.primaryInput) || 0,
            secondaryInput: initialValue.secondaryInput ? Number(initialValue.secondaryInput) : undefined
        },
        resolver: yupResolver(validationSchema)
    })

    const onCancel = () => {
        actions.observationScoreEditDialogTrigger({})
    }

    const onSubmit: SubmitHandler<ObservationNumericScoreEditFormValues> = (formValues: ObservationNumericScoreEditFormValues) => {
        let qualifiers = [...criteria.qualifiers]
        const newQualifier: FilterValueQualifier = formValuesToFilterValueQualifier(formValues)
        qualifiers = qualifiers.filter((q) => q.type !== QualifierType.FilterQualifierDTO)
        qualifiers.push(newQualifier)

        actions.observationScoreEditDialogTrigger({})
        actions.criteriaUpdate({ target: { nodeId: criteria.id }, qualifiers })
    }

    const operatorWatcher = watch('operator')

    const renderSingleInput = (fieldName: 'primaryInput' | 'secondaryInput') => (
        <>
            <Controller
                control={control}
                name={fieldName}
                render={({ field: { onChange, value } }) => (
                    <TextField
                        type='number'
                        {...register(fieldName)}
                        sx={{ marginRight: 1, flex: 1 }}
                        error={!!errors[fieldName]}
                        helperText={errors[fieldName]?.message}
                        data-testid={`input-observation-numeric-value-${fieldName === 'primaryInput' ? 'primary' : 'secondary'}`}
                        onChange={onChange}
                        value={value}
                    />
                )}
            />
        </>
    )

    const renderValueRange = () => (
        <>
            {renderSingleInput('primaryInput')}
            <Box flexShrink={0} marginRight={1}>
                <Trans>and</Trans>
            </Box>
            {renderSingleInput('secondaryInput')}
        </>
    )

    const renderFormFields = () => {
        if (operatorWatcher === QualifierOperator.Between || operatorWatcher === QualifierOperator.NotBetween) {
            return renderValueRange()
        }

        return renderSingleInput('primaryInput')
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Box paddingTop={2} display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                    <Box flexShrink={0} marginRight={1}>
                        <Trans>Observation Score</Trans>
                    </Box>
                    <Controller
                        control={control}
                        name='operator'
                        render={({ field: { onChange, value } }) => (
                            <Select
                                id='operator'
                                labelId='operator-label'
                                sx={{ flex: 1, marginRight: 1 }}
                                required
                                value={value}
                                onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                            >
                                <MenuItem value={QualifierOperator.Equals}>
                                    <Trans>Equals</Trans>
                                </MenuItem>
                                <MenuItem value={QualifierOperator.NotEquals}>
                                    <Trans>Not Equal to</Trans>
                                </MenuItem>
                                <MenuItem value={QualifierOperator.GreaterThan}>
                                    <Trans>More Than</Trans>
                                </MenuItem>
                                <MenuItem value={QualifierOperator.GreaterThanOrEquals}>
                                    <Trans>At Least</Trans>
                                </MenuItem>
                                <MenuItem value={QualifierOperator.LessThan}>
                                    <Trans>Less Than</Trans>
                                </MenuItem>
                                <MenuItem value={QualifierOperator.LessThanOrEquals}>
                                    <Trans>At Most</Trans>
                                </MenuItem>
                                <MenuItem value={QualifierOperator.Between}>
                                    <Trans>Between</Trans>
                                </MenuItem>
                                <MenuItem value={QualifierOperator.NotBetween}>
                                    <Trans>Not Between</Trans>
                                </MenuItem>
                            </Select>
                        )}
                    />
                    {renderFormFields()}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant='text' color='primary' onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </Button>
                <Button variant='contained' type='submit'>
                    <Trans>Save</Trans>
                </Button>
            </DialogActions>
        </form>
    )
}

function formValuesToFilterValueQualifier(formValues: ObservationNumericScoreEditFormValues): FilterValueQualifier {
    const values = isUndefined(formValues.secondaryInput) ? [formValues.primaryInput] : [formValues.primaryInput, formValues.secondaryInput]

    return {
        type: QualifierType.FilterQualifierDTO,
        field: `${formValues.field}_result`,
        operator: formValues.operator,
        values
    }
}
