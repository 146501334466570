/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Trans } from '@lingui/macro'
import { Box, Breadcrumbs } from '@mui/material'
import { CohortRoutes } from '@om1/cohort-module'
import { AssistantConversationDTO } from '@om1/falcon-api/codegen/client/models/AssistantConversationDTO'
import { ExplorerAssistantConversationService } from '@om1/falcon-api/codegen/client/services/ExplorerAssistantConversationService'
import { BreadcrumbLink, InvertContentPadding } from '@om1/platform-ui-kit/src/components/Layout'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import {
    CSSProperties,
    FC,
    FunctionComponent,
    MouseEventHandler,
    MouseEvent as ReactMouseEvent,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react'
import { explorerAssistantConversationActions } from '../state/explorer-assistant-conversation-actions'
import { ExplorerAssistantConversationState } from '../state/explorer-assistant-conversation-state'
import { createActiveConversationComponent } from './ActiveConversation'

const ActiveConversation = createActiveConversationComponent()

export type EditCohortConversationComponentProps = RoutedFrameworkComponentProps<
    Parameters<typeof ExplorerAssistantConversationService.indexExplorerAssistantConversationGet>[0],
    { cohortId?: string },
    ExplorerAssistantConversationState,
    typeof explorerAssistantConversationActions,
    { activeCohortId?: string; activeCohortName?: string }
>
export const EditCohortConversationComponent: FunctionComponent<EditCohortConversationComponentProps> = ({ state, actions, routing, props }) => {
    const cohortId = props.activeCohortId || routing.pathParams.cohortId
    useEffect(() => {
        if (cohortId) {
            actions.getConversations({
                path: {},
                query: { cohortId }
            })
        }
    }, [actions, cohortId])
    const {
        conversations,
        isLoading,
        activeConversation
    }: { conversations: AssistantConversationDTO[]; isLoading: boolean; activeConversation: AssistantConversationDTO | undefined } = state
    const handleConversationClick = (conversation: AssistantConversationDTO) => {
        actions.getConversation({ path: { id: conversation.id }, query: { debugMessages: true } })
    }

    const handleCreateNewConversation = () => {
        if (!cohortId) return
        actions.createConversation({
            requestBody: {
                cohortId
            }
        })
    }

    const breadcrumbs = (
        <Box>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <BreadcrumbLink
                    to={`/${CohortRoutes.COHORTBUILD}/${CohortRoutes.DASHBOARD}/${CohortRoutes.CUSTOM}/${cohortId}`}
                    sx={{ fontWeight: '600', fontSize: '16px', color: '#012D72' }}
                >
                    <Trans>Dashboard</Trans>
                </BreadcrumbLink>
                <BreadcrumbLink
                    to={`/${CohortRoutes.COHORTBUILD}/${CohortRoutes.CUSTOM}/${cohortId}/${CohortRoutes.EDIT}`}
                    sx={{ fontWeight: '600', fontSize: '16px', color: '#012D72' }}
                >
                    Edit
                </BreadcrumbLink>
                <Box sx={{ fontWeight: '600', fontSize: '16px', color: '#707171' }}>
                    <Trans>Chat</Trans>
                </Box>
            </Breadcrumbs>
        </Box>
    )

    return (
        <InvertContentPadding>
            <div style={{ height: '82dvh' }}>
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <>
                        {breadcrumbs}
                        <Split sizes={[20, 80]} minSize={[200, 300]}>
                            <div
                                style={{
                                    height: '90%',
                                    padding: '16px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '8px',
                                    overflow: 'auto'
                                }}
                            >
                                <button
                                    onClick={handleCreateNewConversation}
                                    style={{
                                        padding: '8px 16px',
                                        borderRadius: '4px',
                                        border: '1px solid #e0e0e0',
                                        backgroundColor: '#f5f5f5',
                                        cursor: cohortId ? 'pointer' : 'not-allowed',
                                        opacity: cohortId ? 1 : 0.5,
                                        minHeight: '36px'
                                    }}
                                    disabled={!cohortId}
                                >
                                    Create New Conversation
                                </button>
                                <div style={{ overflow: 'auto', flex: 1 }}>
                                    {conversations
                                        .filter((c) => c.cohortId === cohortId)
                                        .map((conversation, index) => (
                                            <div
                                                key={`${conversation.id}-${conversation.title}-${index}`}
                                                onClick={() => handleConversationClick(conversation)}
                                                style={{
                                                    cursor: 'pointer',
                                                    padding: '8px 12px',
                                                    borderRadius: '4px',
                                                    backgroundColor: activeConversation?.id === conversation.id ? '#e3f2fd' : 'transparent',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px'
                                                }}
                                            >
                                                {activeConversation?.id === conversation.id && (
                                                    <span
                                                        style={{
                                                            width: '8px',
                                                            height: '8px',
                                                            borderRadius: '50%',
                                                            backgroundColor: '#1976d2'
                                                        }}
                                                    />
                                                )}
                                                <span
                                                    style={{
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap'
                                                    }}
                                                >
                                                    {conversation.title || `${conversation.id}`}
                                                </span>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div style={{ overflow: 'auto' }}>{activeConversation && <ActiveConversation />}</div>
                        </Split>
                    </>
                )}
            </div>
        </InvertContentPadding>
    )
}

interface SplitProps {
    sizes?: number[]
    minSize?: number[]
    children: ReactNode[]
    style?: CSSProperties
}

export const Split: FC<SplitProps> = ({ sizes = [50, 50], minSize = [0, 0], children, style }) => {
    const [splitSizes, setSplitSizes] = useState(sizes)
    const containerRef = useRef<HTMLDivElement>(null)
    const isDragging = useRef(false)
    const startX = useRef(0)
    const startSizes = useRef(splitSizes)

    const handleMouseDown = (e: ReactMouseEvent) => {
        isDragging.current = true
        startX.current = e.clientX
        startSizes.current = splitSizes
        document.addEventListener('mousemove', handleMouseMove)
        document.addEventListener('mouseup', handleMouseUp)
    }

    const handleMouseMove = useCallback(
        (e: MouseEvent) => {
            if (!isDragging.current || !containerRef.current) return

            const delta = e.clientX - startX.current
            const containerWidth = containerRef.current.offsetWidth
            const deltaPercentage = (delta / containerWidth) * 100

            const newLeftSize = Math.max(
                (minSize[0] / containerWidth) * 100,
                Math.min(100 - (minSize[1] / containerWidth) * 100, startSizes.current[0] + deltaPercentage)
            )

            setSplitSizes([newLeftSize, 100 - newLeftSize])
        },
        [minSize]
    )

    const handleMouseUp = useCallback(() => {
        isDragging.current = false
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
    }, [handleMouseMove])

    useEffect(() => {
        return () => {
            document.removeEventListener('mousemove', handleMouseMove)
            document.removeEventListener('mouseup', handleMouseUp)
        }
    }, [handleMouseMove, handleMouseUp])

    return (
        <div ref={containerRef} style={{ display: 'flex', width: '100%', height: '98%', ...style }}>
            <div style={{ width: `${splitSizes[0]}%`, minWidth: minSize[0] }}>{children[0]}</div>
            <div
                style={{
                    width: '4px',
                    backgroundColor: '#e0e0e0',
                    cursor: 'col-resize',
                    userSelect: 'none'
                }}
                onMouseDown={handleMouseDown as MouseEventHandler<HTMLDivElement>}
            />
            <div style={{ width: `${splitSizes[1]}%`, minWidth: minSize[1] }}>{children[1]}</div>
        </div>
    )
}
