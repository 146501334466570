import { Trans, t } from '@lingui/macro'
import ErrorIcon from '@mui/icons-material/Error'
import { Button, CircularProgress, Typography } from '@mui/material'
import { Box, SxProps } from '@mui/system'
import BasicTable from '@om1/platform-components/BasicTable'
import ReportChartButtons from '@om1/platform-components/ReportChartButtons'
import { FrameworkComponentProps } from '@om1/platform-utils'
import Highcharts, { AxisLabelsFormatterCallbackFunction, Point } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { get } from 'lodash'
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
    AnalyticsDataState,
    AnalyticsDimension,
    AnalyticsTaskStatus,
    CohortListItem,
    CriteriaType,
    DataTypeAnalyticsData,
    Report,
    ReportAnalyticsType,
    ReportName,
    ReportType,
    ReportsState,
    cohortAnalyticsActions,
    reportsActions
} from '../../state'
import { ChartData } from '../reports/ReportChartComponent'
import { CriteriaTypeLabel } from './CriteriaTypeLabel'

const DataReportSortOrder = {
    has_ehr_data: 1,
    has_ehr_linked_medical_and_rx_data: 2,
    has_ehr_linked_medical_or_rx_data: 3,
    has_medical_claims_data: 4,
    has_rx_claims_data: 5
}

export function mapFieldToDataLabel(field: string | undefined): string {
    switch (field) {
        case AnalyticsDimension.HasEhrLinkedMedicalAndRxClaimsData:
            return t`EHR and Linked Medical and Rx Claims Data`
        case AnalyticsDimension.HasEhrLinkedClaimsData:
            return t`EHR and Linked Medical or Rx Claims Data`
        case AnalyticsDimension.HasEhrData:
            return t`EHR Data`
        case AnalyticsDimension.HasRxClaimsData:
            return t`Rx Claims Data`
        default:
            return t`Medical Claims Data`
    }
}

// Highcharts Y axes don't format with a thousands seperator, so override it in that case.
const axisLabelFormatter: AxisLabelsFormatterCallbackFunction = (context) => {
    let label = context.axis.defaultLabelFormatter.call(context)
    // Use thousands separator for four-digit numbers too
    if (/^[0-9]{4}$/.test(label)) {
        label = Highcharts.numberFormat(Number(context.value), 0)
    }
    return label
}

const CONTAINER_PROPS = {
    style: {
        width: '100%',
        height: '100%',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', // Add shadow to match design
        borderRadius: '8px', // Rounded corners to match design
        overflow: 'hidden'
    }
}

const BAR_CHART_OPTIONS: Highcharts.Options = {
    chart: {
        inverted: true,
        backgroundColor: 'transparent',
        style: {
            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
            fontFamily: "'Arial', sans-serif" // match the font style
        },
        borderRadius: 5,
        animation: false
    },
    title: {
        text: undefined
    },
    xAxis: {
        labels: {
            style: {
                fontSize: '16px'
            }
        }
    },
    yAxis: {
        min: 0,
        max: null,
        title: undefined,
        labels: {
            overflow: 'justify',
            formatter: axisLabelFormatter
        }
    },
    legend: {
        enabled: false
    },
    credits: undefined,
    series: [
        {
            type: 'column',
            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
            color: '#002D72',
            groupPadding: 0,
            dataLabels: {
                enabled: true
            }
        }
    ],
    plotOptions: {
        column: {
            borderRadius: 5, // Match border radius
            borderWidth: 0, // Remove border or set it to match your design
            dataLabels: {
                enabled: true,
                style: {
                    textOutline: '0', // Remove text outline
                    fontWeight: 'bold' // Set to bold or match your design
                }
            }
        }
    }
}

export type DataTypeReportChartComponentProps = FrameworkComponentProps<
    { analytics?: AnalyticsDataState<DataTypeAnalyticsData> | null; reports: ReportsState | null; reportsLoading: boolean },
    typeof reportsActions & typeof cohortAnalyticsActions,
    {
        cohort: CohortListItem
        disableFetching?: boolean
        chartRefProp?: React.MutableRefObject<Highcharts.Chart | null>
    }
>

export const DataTypeReportChartComponent: FunctionComponent<DataTypeReportChartComponentProps> = ({
    state: { analytics, reports, reportsLoading },
    props: { cohort, disableFetching, chartRefProp },
    actions
}) => {
    const [view, setView] = useState<'barChart' | 'tableChart'>('barChart')
    const report = useMemo<Report | undefined>(
        () => get(reports, ['reports'], []).find((rep) => rep.reportType === ReportType.DATA_TYPE && rep.cohortId === cohort.id),
        [cohort.id, reports]
    )
    const innerChartRef = useRef<Highcharts.Chart | null>(null)
    const chartRef = chartRefProp || innerChartRef

    const analyticsLoading = useMemo(() => {
        return (analytics && analytics.status === AnalyticsTaskStatus.Pending) || (report && analytics === undefined)
    }, [analytics, report])

    useEffect(() => {
        if (cohort.id && report && !analytics && !disableFetching) {
            actions.reportTaskGet({ path: { cohortId: report.cohortId, reportId: report.id, reportType: ReportAnalyticsType.DATA_TYPE }, query: {} })
        }
    }, [actions, analytics, cohort.id, disableFetching, report])

    const chartData = useMemo<ChartData>(() => {
        if (!analytics?.data) {
            return {}
        }

        return Object.entries(analytics.data || {})
            .sort((a, b) => DataReportSortOrder[a[0]] - DataReportSortOrder[b[0]])
            .map((item) => [mapFieldToDataLabel(item[0]), item[1]])
            .reduce((agg, item) => {
                agg[item[0]] = item[1]
                return agg
            }, {})
    }, [analytics?.data])

    const handleResize = useCallback(
        (chartRef) => {
            if (chartRef.current) {
                const chartContainer = chartRef.current.container
                if (chartContainer) {
                    const newOptions = {
                        ...chartRef.current.options,
                        chart: {
                            ...chartRef.current.options.chart,
                            width: chartRef.current.options.chart?.width,
                            height: chartRef.current.options.chart?.height
                        }
                    }
                    chartRef.current.update(newOptions, true, true)
                    chartRef.current.reflow()
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    useEffect(() => {
        handleResize(chartRef)

        const resizeListener = () => {
            handleResize(chartRef)
        }

        window.addEventListener('resize', resizeListener)

        return () => {
            window.removeEventListener('resize', resizeListener)
        }
    }, [handleResize, chartRef])

    const containerHeightPixels = 100 * Object.keys(chartData).length
    const containerHeight = `${containerHeightPixels}px`

    const CHART_OPTIONS = useMemo(() => {
        const values = Object.entries(chartData)
        const categories = values.map((value) => value[0])
        const data: any = values.map((value) => value[1])

        let options: Highcharts.Options = {
            ...BAR_CHART_OPTIONS,
            chart: { ...BAR_CHART_OPTIONS.chart, height: containerHeight },
            xAxis: { ...BAR_CHART_OPTIONS.xAxis, categories },
            yAxis: { ...BAR_CHART_OPTIONS.yAxis, title: { text: t`Patient Count`, align: 'middle' } },
            series: [{ ...BAR_CHART_OPTIONS.series![0], name: ReportName.DATA_TYPE, data: data }],
            tooltip: {
                pointFormatter: function () {
                    const point = this as any as Point // use "any" to bypass the type checking for this specific case
                    // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                    return 'Value: ' + point.y!.toLocaleString('en-US')
                }
            }
        }

        return options
    }, [chartData, containerHeight])

    const renderReport = useMemo<ReactNode>(() => {
        if (!disableFetching && (!report || analyticsLoading || (report && analytics === undefined))) {
            return null
        }

        if (analytics?.status === AnalyticsTaskStatus.Failed) {
            return (
                <Box>
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <h3>
                            <Trans>Patient Count by</Trans> <CriteriaTypeLabel criteriaType={CriteriaType.ObservationPeriod} />
                        </h3>
                    </Box>
                    <Box
                        paddingY={4}
                        marginBottom={3}
                        bgcolor='grey.200'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        flexDirection='column'
                        sx={{ position: 'relative', backgroundColor: 'grey.100' }}
                    >
                        <Box
                            width={'100%'}
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            marginBottom={2}
                            sx={{ position: 'relative', backgroundColor: 'grey.100' }}
                        >
                            <ErrorIcon
                                sx={{
                                    width: '120px',
                                    height: '120px',
                                    color: '#002D72'
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                paddingTop: '20px',
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                paddingBottom: '0px',
                                color: '#002D72',
                                fontSize: '20px',
                                opacity: reportsLoading ? '0%' : '100%'
                            }}
                        >
                            <Trans>There was an error loading this report.</Trans>
                        </Box>
                        <Box paddingTop={5}>
                            <Button
                                onClick={() =>
                                    actions.reportTaskGet({
                                        path: { cohortId: report?.cohortId, reportId: report?.id, reportType: ReportAnalyticsType.DATA_TYPE },
                                        query: {}
                                    })
                                }
                                variant='contained'
                            >
                                <Trans>Retry</Trans>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )
        }

        const setViewProp = (view: 'barChart' | 'tableChart') => {
            setView(view)
        }

        const toSafeFilename = (input: string, reportAnalyticsType: string): string => {
            let safeString = input.replace(/[^a-zA-Z0-9-_]/g, '_')
            let safeReportAnalyticsType = reportAnalyticsType.replace(/[^a-zA-Z0-9-_]/g, '_')
            const now = new Date()
            const formattedDate = now.toISOString().split('T')[0].replace(/-/g, '_')
            return `${safeString}_${safeReportAnalyticsType}_${formattedDate}`
        }

        const convertChartDataToCSV = (chartDataArray: [string, number][]): string => {
            const headers = ['"Value"', '"Count"']
            const csvRows = [headers.join(',')]
            chartDataArray.forEach((row) => {
                const quotedRow = row.map(
                    (field) => (typeof field === 'string' ? `"${field.replace(/"/g, '""')}"` : field) // Encapsulate strings in quotes and escape existing quotes
                )
                csvRows.push(quotedRow.join(','))
            })
            return csvRows.join('\n')
        }

        // Function to trigger the download of the CSV file
        const downloadCSV = (csvString: string, fileName: string) => {
            const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }

        const handleDownloadCSV = () => {
            let chartDataArray: [string, number][] = Object.entries(chartData)
            const csvString = convertChartDataToCSV(chartDataArray)
            let filename = `${toSafeFilename(cohort.name, ReportAnalyticsType.DATA_TYPE)}.csv`
            downloadCSV(csvString, filename)
        }

        return (
            <Box sx={{ pageBreakInside: 'avoid', maxWidth: '100%', overflow: 'hidden' }}>
                <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                        padding: '16px',
                        backgroundColor: '#E8F0FE',
                        borderRadius: '8px',
                        boxShadow: 'inset 0 4px 8px 0 rgba(0,0,0,0.05)',
                        margin: '8px 0',
                        height: '50px'
                    }}
                >
                    <Box flexGrow={4} fontWeight={'bold'}>
                        <Trans>Patient Count by</Trans> <CriteriaTypeLabel criteriaType={CriteriaType.ObservationPeriod} />
                    </Box>
                    <Box height={'48px'} paddingLeft={'15px'} sx={{ display: disableFetching ? 'none' : 'flex' }}>
                        <ReportChartButtons view={view} setView={setViewProp} onDownload={handleDownloadCSV} isDrilledDown={false} />
                    </Box>
                </Box>
                <Box
                    width={'100%'}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    marginBottom={2}
                    sx={{ position: 'relative', backgroundColor: 'grey.100' }}
                >
                    <Box width='100%' height='100%'>
                        <Box height='100%'>
                            {view === 'barChart' && (
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    containerProps={CONTAINER_PROPS}
                                    options={CHART_OPTIONS}
                                    callback={(chart) => {
                                        chartRef.current = chart
                                    }}
                                />
                            )}
                            {view === 'tableChart' && <BasicTable chartData={chartData} valueLabel={undefined} />}
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }, [CHART_OPTIONS, actions, analytics, analyticsLoading, chartData, cohort.name, disableFetching, report, reportsLoading, view, chartRef])

    let loadingOverlay: ReactNode
    if (reportsLoading || analyticsLoading) {
        const overlayStyles: SxProps = { alignItems: 'flex-start', paddingTop: 12 }
        loadingOverlay = (
            <Box position='absolute' top={0} left={0} right={0} bottom={0} display='flex' justifyContent='center' sx={overlayStyles}>
                <Box position='absolute' zIndex={1} top={0} left={0} right={0} bottom={0} bgcolor='grey.100' sx={{ opacity: 0.9 }} />
                <Box
                    position='relative'
                    zIndex={2}
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    gap={2}
                    p={4}
                    color='grey.600'
                >
                    <CircularProgress color='inherit' />
                    <Typography>
                        <strong>
                            <Trans>We&apos;re gathering your data. Queries typically finish in less than 30 seconds.</Trans>
                        </strong>
                    </Typography>
                </Box>
            </Box>
        )
    } else {
        loadingOverlay = null
    }

    return (
        <Box position='relative' minHeight={disableFetching ? '' : window.innerHeight} overflow={'auto'} maxHeight={'100%'} marginBottom={0}>
            {renderReport}
            {loadingOverlay}
        </Box>
    )
}
