import { t } from '@lingui/macro'
import { ExplorerTasksService, OpenAPI, TaskDTO } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { notificationActions } from '@om1/platform-notifications'
import { EventChannel } from 'redux-saga'
import { call, put, take } from 'redux-saga/effects'
import { renderCohortDuplicateSuccessToast } from '../components/list/CohortDuplicateSuccessToast'
import { cohortListActions } from '../state'
import { taskSocket } from './task-socket'

export function createDuplicateCohortSaga() {
    return function* (action: ReturnType<typeof cohortListActions.cohortDuplicate>) {
        const { cohortId } = action.payload

        yield put(cohortListActions.loadingSet({ loading: true }))

        let taskId
        try {
            const taskDTO: TaskDTO = yield call(ExplorerTasksService.cohortDuplicateExplorerTasksCohortCohortIdDuplicateGet, {
                cohortId: cohortId
            })
            taskId = taskDTO.taskId
        } catch (e) {
            console.log(e)
            yield put(notificationActions.error(t`Could not get task id`))
            yield put(cohortListActions.loadingSet({ loading: false }))
        }

        if (taskId) {
            try {
                const eventChannel: EventChannel<any> = yield call(taskSocket, taskId, OpenAPI.TOKEN)
                const socketResponse = JSON.parse(yield take(eventChannel)) as {
                    taskId: string
                    status: string
                    result: { cohortId: string; cohortName: string } & { error?: string }
                }
                if (socketResponse.status === 'SUCCESS') {
                    yield put(cohortListActions.cohortsGetAllCohorts({ query: {}, path: {} }))
                    yield put(
                        notificationActions.success(
                            renderCohortDuplicateSuccessToast({ id: socketResponse.result.cohortId, name: socketResponse.result.cohortName })
                        )
                    )
                } else if (socketResponse.status === 'FAILURE' && (socketResponse.result.error || '').includes('TimeoutError')) {
                    yield handleApiError(socketResponse.result.error)
                } else {
                    yield put(notificationActions.error(t`Could not duplicate the cohort`))
                }
            } catch (error) {
                yield handleApiError(error)
            }
        }

        yield put(cohortListActions.loadingSet({ loading: false }))
    }
}
