import { t } from '@lingui/macro'

export enum AnalyticsDimension {
    Race = 'RACE',
    Sex = 'SEX',
    Age = 'AGE',
    Ethnicity = 'ETHNICITY',
    State = 'STATE',
    Diagnosis = 'DIAGNOSIS',
    DiagnosisConceptId = 'DIAGNOSIS_CONCEPT_ID',
    Drug = 'DRUG',
    RouteOfAdmin = 'ROUTE_OF_ADMIN',
    VaHierarchy = 'VA_HIERARCHY',
    BocCui = 'BOC_CUI',
    BocName = 'BOC_NAME',
    NdcCode = 'NDC_CODE',
    NdcName = 'NDC_NAME',
    Observation = 'OBSERVATION',
    Value = 'VALUE',
    ValueType = 'VALUE_TYPE',
    Procedure = 'PROCEDURE',
    ProcedureConceptId = 'PROCEDURE_CONCEPT_ID',
    HasEhrData = 'has_ehr_data',
    HasMedicalClaimsData = 'has_medical_claims_data',
    HasRxClaimsData = 'has_rx_claims_data',
    HasEhrLinkedClaimsData = 'has_ehr_linked_medical_or_rx_data',
    HasEhrLinkedMedicalAndRxClaimsData = 'has_ehr_linked_medical_and_rx_data',
    Phenotype = 'phenotype',
    ProductCustomCohort = 'product_custom_cohort'
}

export enum AnalyticsTaskStatus {
    Pending = 'PENDING',
    Complete = 'COMPLETE',
    Failed = 'FAILED'
}

export interface CohortAnalyticsState {
    data: Record<string, AnalyticsReportState>
}

export enum ReportAnalyticsType {
    DEMOGRAPHICS = 'demographics',
    DATA_TYPE = 'data_type',
    DIAGNOSES = 'diagnoses',
    MEDICATIONS = 'medications',
    OBSERVATIONS = 'observations',
    PROCEDURES = 'procedures',
    LAB_TESTS = 'labs',
    COHORT = 'cohort-tree'
}

export const getReportTabLabel = (type: ReportAnalyticsType): string => {
    switch (type) {
        case ReportAnalyticsType.DEMOGRAPHICS:
            return t`Demographics`
        case ReportAnalyticsType.DIAGNOSES:
            return t`Diagnoses`
        case ReportAnalyticsType.MEDICATIONS:
            return t`Medications`
        case ReportAnalyticsType.OBSERVATIONS:
            return t`Observations`
        case ReportAnalyticsType.PROCEDURES:
            return t`Procedures`
        case ReportAnalyticsType.COHORT:
            return t`Cohort`
        case ReportAnalyticsType.LAB_TESTS:
            return t`Lab Tests`
        case ReportAnalyticsType.DATA_TYPE:
            return t`Data Type`
        default:
            return type
    }
}

export type AnalyticsReportState = {
    [ReportAnalyticsType.DEMOGRAPHICS]?: AnalyticsDataState<DemographicsAnalyticsData>
    [ReportAnalyticsType.DATA_TYPE]?: AnalyticsDataState<DataTypeAnalyticsData>
    [ReportAnalyticsType.DIAGNOSES]?: AnalyticsDataState<AnalyticsChartData>
    [ReportAnalyticsType.MEDICATIONS]?: AnalyticsDataState<AnalyticsChartData>
    [ReportAnalyticsType.LAB_TESTS]?: AnalyticsDataState<AnalyticsChartData>
    [ReportAnalyticsType.PROCEDURES]?: AnalyticsDataState<AnalyticsChartData>
}

export interface AnalyticsDataState<T> {
    taskId: string
    status: AnalyticsTaskStatus
    data: T
}

export type DemographicsAnalyticsData = {
    [AnalyticsDimension.Race]: AnalyticsCategoryData
    [AnalyticsDimension.Sex]: AnalyticsCategoryData
    [AnalyticsDimension.Age]: AnalyticsCategoryData
    [AnalyticsDimension.Ethnicity]: AnalyticsCategoryData
    [AnalyticsDimension.State]: AnalyticsCategoryData
}

export type DataTypeAnalyticsData = {
    [AnalyticsDimension.HasEhrData]?: number | undefined
    [AnalyticsDimension.HasEhrLinkedClaimsData]?: number | undefined
    [AnalyticsDimension.HasMedicalClaimsData]?: number | undefined
    [AnalyticsDimension.HasRxClaimsData]?: number | undefined
}

export type AnalyticsData = DemographicsAnalyticsData | DataTypeAnalyticsData | AnalyticsChartData

export type AnalyticsCategoryData = Record<string, number>

export type AnalyticsChartData = {
    sections?: AnalyticsSection[]
}
export type AnalyticsSection = {
    id: string
    results: AnalyticsSectionResult[]
}
export type AnalyticsSectionResult = {
    data: AnalyticsSectionData[]
}
export type AnalyticsSectionData = Record<string, string | number>

export const initialCohortAnalyticsState: CohortAnalyticsState = {
    data: {}
}
