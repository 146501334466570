import { DiagnosisCuiRefFieldMapper } from './diagnosis-cui-ref-field-mapper'
import { DiagnosisGemMappedIcd10RefFieldMapper } from './diagnosis-gem-mapped-icd10-ref-field-mapper'
import { DiagnosisIcd10RefFieldMapper } from './diagnosis-icd10-ref-field-mapper'
import { EhrNotesRefFieldMapper } from './ehr-note-field-mapper'
import { AnyLabRefFieldMapper, LabRefFieldMapper } from './lab-ref-field-mapper'
import { MedicationCuiRefFieldMapper } from './medication-cui-ref-field-mapper'
import { MedicationNdcRefFieldMapper } from './medication-ndc-ref-field-mapper'
import { MedicationRefFieldMapper } from './medication-ref-field-mapper'
import { ObservationPeriodFieldMapper } from './observation-period-field-mapper'
import { ObservationRefFieldMapper } from './observation-ref-field-mapper'
import { PhenotypeRefFieldMapper } from './phenom-ref-field-mapper'
import { ProcedureRefFieldMapper } from './procedure-ref-field-mapper'
import { ProductCustomCohortRefFieldMapper } from './product-custom-cohort-ref-field-mapper'
import { RaceRefFieldMapper } from './race-ref-field-mapper'
import { SexRefFieldMapper } from './sex-ref-field-mapper'
import { SpecialtyRefFieldMapper } from './specialty-ref-field-mapper'
import { StateRefFieldMapper } from './state-ref-field-mapper'

export * from './diagnosis-cui-ref-field-mapper'
export * from './diagnosis-gem-mapped-icd10-ref-field-mapper'
export * from './diagnosis-icd10-ref-field-mapper'
export * from './ehr-note-field-mapper'
export * from './interfaces'
export * from './lab-ref-field-mapper'
export * from './medication-cui-ref-field-mapper'
export * from './medication-ndc-ref-field-mapper'
export * from './medication-ref-field-mapper'
export * from './observation-period-field-mapper'
export * from './observation-ref-field-mapper'
export * from './phenom-ref-field-mapper'
export * from './procedure-ref-field-mapper'
export * from './race-ref-field-mapper'
export * from './sex-ref-field-mapper'
export * from './specialty-ref-field-mapper'
export * from './state-ref-field-mapper'
export * from './utils'

// Should include all ref field mappers
export const FIELD_MAPPERS = [
    DiagnosisCuiRefFieldMapper,
    DiagnosisGemMappedIcd10RefFieldMapper,
    DiagnosisIcd10RefFieldMapper,
    LabRefFieldMapper,
    AnyLabRefFieldMapper,
    MedicationCuiRefFieldMapper,
    MedicationNdcRefFieldMapper,
    MedicationRefFieldMapper,
    ObservationRefFieldMapper,
    ProcedureRefFieldMapper,
    RaceRefFieldMapper,
    SexRefFieldMapper,
    StateRefFieldMapper,
    ObservationPeriodFieldMapper,
    EhrNotesRefFieldMapper,
    SpecialtyRefFieldMapper,
    PhenotypeRefFieldMapper,
    ProductCustomCohortRefFieldMapper
]

// Should include all ref field mappers except for Patient Attributes refs.
export const FIELD_MAPPERS_GENERIC = [
    DiagnosisCuiRefFieldMapper,
    DiagnosisGemMappedIcd10RefFieldMapper,
    DiagnosisIcd10RefFieldMapper,
    LabRefFieldMapper,
    AnyLabRefFieldMapper,
    MedicationCuiRefFieldMapper,
    MedicationNdcRefFieldMapper,
    MedicationRefFieldMapper,
    ObservationRefFieldMapper,
    ProcedureRefFieldMapper,
    ObservationPeriodFieldMapper,
    EhrNotesRefFieldMapper,
    SpecialtyRefFieldMapper,
    PhenotypeRefFieldMapper,
    ProductCustomCohortRefFieldMapper
]
