import { AnalyticsChartData } from '@om1/cohort-module/src/state'
import { SweetPath } from 'sweet-path'
import { FalconPaginationQuery, FalconPaginationRes } from './falcon-fetch'

export type Endpoints = {
    cohortCreate: {
        path: SweetPath
    }
    cohort: {
        path: SweetPath
    }
    cohortSql: {
        path: SweetPath
    }
    cohorts: {
        path: SweetPath
        queryParams: { limit: number; page: number; is_system?: boolean }
    }
    cohortDelete: {
        path: SweetPath
    }
    cohortUpdate: {
        path: SweetPath
    }
    cohortSizeUpdateTask: {
        path: SweetPath
    }
    analyticsRefs: {
        path: SweetPath<'tableName'>
        queryParams: string
    }
    dataDelivery: {
        path: SweetPath
    }
    dataDeliveries: {
        path: SweetPath<string>
        queryParams: Required<FalconPaginationQuery>
    }
    dataDeliveryCreate: {
        path: SweetPath
    }
    dataDeliveryUpdate: {
        path: SweetPath
    }
    dataDeliveryExecuteTask: {
        path: SweetPath<'deliveryId'>
    }
    dataDeliveryTask: {
        path: SweetPath<'taskId'>
    }
    deliveredDatabases: {
        path: SweetPath<string>
        queryParams: Required<FalconPaginationQuery>
    }
    cohortReports: {
        path: SweetPath
        queryParams: { cohort_id?: string; report_type?: string; limit?: number; page?: number }
    }
    cohortReport: {
        path: SweetPath<'reportId'>
    }
    cohortReportTask: {
        path: SweetPath<'reportId'>
    }
    version: {
        path: SweetPath
    }
    task: {
        path: SweetPath
    }
    whoami: {
        path: SweetPath
    }
    createTokenUser: {
        path: SweetPath
    }
    users: {
        path: SweetPath
    }
    user: {
        path: SweetPath
    }
    roles: {
        path: SweetPath
    }
    role: {
        path: SweetPath
    }
    tenants: {
        path: SweetPath
    }
    tenant: {
        path: SweetPath
    }
}

export const falconEndpoints: Endpoints = {
    cohortCreate: {
        path: new SweetPath('/explorer/cohorts')
    },
    cohort: {
        path: new SweetPath('/explorer/cohorts/:cohortId')
    },
    cohortSql: {
        path: new SweetPath('/explorer/cohorts/:cohortId/sql')
    },
    cohorts: {
        path: new SweetPath('/explorer/cohorts'),
        queryParams: { limit: 25, page: 1, is_system: false }
    },
    cohortDelete: {
        path: new SweetPath('/explorer/cohorts/:cohortId')
    },
    cohortUpdate: {
        path: new SweetPath('/explorer/cohorts/:cohortId')
    },
    cohortSizeUpdateTask: {
        path: new SweetPath('/explorer/tasks/cohort/:cohortId/update_size')
    },
    analyticsRefs: {
        path: new SweetPath<'tableName'>('/explorer/analytics/ref/:tableName'),
        queryParams: 'limit=100&page=1'
    },
    dataDeliveryCreate: {
        path: new SweetPath('/data_delivery/delivery')
    },
    dataDeliveryUpdate: {
        path: new SweetPath('/data_delivery/delivery/:deliveryId')
    },
    dataDeliveryExecuteTask: {
        path: new SweetPath('/data_delivery/tasks/execute/:deliveryId')
    },
    dataDeliveryTask: {
        path: new SweetPath('/data_delivery/ws/tasks/:taskId')
    },
    dataDelivery: {
        path: new SweetPath('/data_delivery/delivery/:deliveryId')
    },
    dataDeliveries: {
        path: new SweetPath('/data_delivery/delivery'),
        queryParams: { limit: 10, page: 1 }
    },
    deliveredDatabases: {
        path: new SweetPath('/data_delivery/delivered_databases'),
        queryParams: { limit: 10, page: 1 }
    },
    cohortReports: {
        path: new SweetPath('/explorer/reports'),
        queryParams: { limit: 100, page: 1 }
    },
    cohortReport: {
        path: new SweetPath<'reportId'>('/explorer/reports/:reportId')
    },
    cohortReportTask: {
        path: new SweetPath('/explorer/tasks/report/:reportId/execute')
    },
    version: {
        path: new SweetPath('/version')
    },
    task: {
        path: new SweetPath('/tasks/ws/:taskId')
    },
    whoami: {
        path: new SweetPath('/users/whoami')
    },
    createTokenUser: {
        path: new SweetPath('/users/token_user')
    },
    users: {
        path: new SweetPath('/users')
    },
    user: {
        path: new SweetPath('/users/:user_id')
    },
    roles: {
        path: new SweetPath('/roles')
    },
    role: {
        path: new SweetPath('/roles/:role_id')
    },
    tenants: {
        path: new SweetPath('/tenants')
    },
    tenant: {
        path: new SweetPath('/tenants/:tenant_id')
    }
}

export type AnalyticsDimensionParam =
    | 'race'
    | 'sex'
    | 'current_age'
    | 'ethnicity'
    | 'state'
    | 'diagnosis'
    | 'diagnosis_concept_id'
    | 'drug'
    | 'route_of_admin'
    | 'va_hierarchy'
    | 'boc_cui'
    | 'boc_name'
    | 'ndc_code'
    | 'ndc_name'
    | 'observation'
    | 'procedure'
    | 'procedure_concept_id'
    | 'phenotype'
    | 'product_custom_cohort'

export type AnalyticsGroupingSetsRes = { sections: { id: string; results: Partial<Record<AnalyticsDimensionParam, AnalyticsDefaultRes>>[] }[] }

export type AnalyticsDefaultRes = { data: (Partial<{ [key in AnalyticsDimensionParam]: string }> & { patient_count: number })[] }

export type AnalyticsGroupRes = AnalyticsGroupingSetsRes | AnalyticsDefaultRes | AnalyticsChartData

export type AnalyticsRefTableParam =
    | 'ref_diagnosis'
    | 'ref_race'
    | 'ref_sex'
    | 'ref_state'
    | 'ref_lab'
    | 'ref_medication'
    | 'ref_diagnosis_boc_cui'
    | 'ref_medication_boc_cui'
    | 'ref_medication_ndc'
    | 'ref_observation'
    | 'ref_procedure'
    | 'ref_specialty'
    | 'ref_phenotype'
    | 'ref_product_custom_cohort'

export type AnalyticsRaceRefsRes = FalconPaginationRes<{ race: string }[]>
export type AnalyticsSexRefsRes = FalconPaginationRes<{ sex: string }[]>
export type AnalyticsStateRefsRes = FalconPaginationRes<{ state_name: string; sub_region: string; region: string }[]>
export type AnalyticsMedicationRefRes = FalconPaginationRes<
    {
        id: string
        name: string
        drug_roa_cui: string
        route_of_admin: string
        in_min_cui: string
        level_0_id: string | null
        level_0_name: string | null
        level_1_id: string | null
        level_1_name: string | null
        level_2_id: string | null
        level_2_name: string | null
        level_3_id: string | null
        level_3_name: string | null
        level_4_id: string | null
        level_4_name: string | null
    }[]
>
export type AnalyticsProcedureRefRes = FalconPaginationRes<
    {
        procedure_concept_id: string | null
        procedure: string | null
        procedure_display_name: string | null
    }[]
>
export type AnaltyicsRefsRes = FalconPaginationRes<any[]>

export interface CohortDimensionTaskRes {
    taskId: string
}

export interface CohortSizeUpdateTaskRes {
    taskId: string
}
