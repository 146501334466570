import { cohortEditActions, CohortState } from '../../state'
import { connectRedux } from '@om1/platform-utils'
import { withRouter } from '@om1/platform-routing'
import { GenerateAttritionFunnelComponent } from './GenerateAttritionFunnelComponent'

export function createGenerateAttritionFunnelComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        withRouter(GenerateAttritionFunnelComponent),
        (state: TState) => ({
            cohortAttritionFunnelLoading: state.cohort.edit.base.ui.cohortAttritionFunnelLoading
        }),
        { ...cohortEditActions }
    )
}
