import { CohortDTO, ExplorerCohortsService, PaginatedDTO_CohortDTO_, falconEndpoints } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put, select } from 'redux-saga/effects'
import { CohortListActionTypes, CohortListItem, CohortOwner, CohortState, cohortListActions } from '../state'

export function createGetCohortsSaga() {
    return function* (
        action:
            | ReturnType<typeof cohortListActions.cohortsGetUserCohorts>
            | ReturnType<typeof cohortListActions.cohortsGetSystemCohorts>
            | ReturnType<typeof cohortListActions.cohortsGetAllCohorts>
    ) {
        yield put(cohortListActions.loadingSet({ loading: true }))
        try {
            if (action.type === CohortListActionTypes.COHORTS_ACCESS && 'cohortId' in action.payload && action.payload.cohortId) {
                const cohortDTO: CohortDTO = yield call(ExplorerCohortsService.getExplorerCohortsCohortIdGet, { cohortId: action.payload.cohortId })

                // Only add to state if it doesn't already exist there
                const cohorts = yield select((state: { cohort: CohortState }) => state.cohort.list.cohorts[CohortOwner.User])
                const isNewCohort = !cohorts.some((cohort) => cohort.id === cohortDTO.id)
                if (isNewCohort) {
                    const meta = yield select((state: { cohort: CohortState }) => state.cohort.list.paginationMeta)
                    yield put(
                        cohortListActions.cohortsSet({
                            cohorts: [...cohorts, cohortDTO as CohortListItem],
                            meta,
                            owner: CohortOwner.User
                        })
                    )
                }
            } else if (action.payload.owner !== undefined) {
                const paginatedCohortsDTO: PaginatedDTO_CohortDTO_ = yield call(ExplorerCohortsService.indexExplorerCohortsGet, {
                    page: action.payload.page ?? falconEndpoints.cohorts.queryParams.page,
                    limit: action.payload.pageSize ?? falconEndpoints.cohorts.queryParams.limit,
                    query: action.payload.searchValue,
                    isSystem: action.payload.owner === CohortOwner.System
                })
                // Filter out cohorts that already exist in the state
                const cohorts = yield select((state: { cohort: CohortState }) => state.cohort.list.cohorts[action.payload.owner!])
                const newCohorts = paginatedCohortsDTO.data.filter((cohortDTO) => {
                    return !cohorts.some((cohort) => cohort.id === cohortDTO.id)
                })
                yield put(
                    cohortListActions.cohortsSet({
                        cohorts: [...cohorts, ...newCohorts],
                        meta: paginatedCohortsDTO.meta,
                        owner: action.payload.owner === CohortOwner.System ? CohortOwner.System : CohortOwner.User
                    })
                )
            } else {
                const paginatedSystemCohortsDTO: PaginatedDTO_CohortDTO_ = yield call(ExplorerCohortsService.indexExplorerCohortsGet, {
                    page: action.payload.query.SYSTEM?.page ?? falconEndpoints.cohorts.queryParams.page,
                    limit: action.payload.query.SYSTEM?.pageSize ?? 1000,
                    isSystem: true
                })
                yield put(
                    cohortListActions.cohortsSet({
                        cohorts: paginatedSystemCohortsDTO.data.map((cohortDTO) => {
                            return cohortDTO as CohortListItem
                        }),
                        meta: paginatedSystemCohortsDTO.meta,
                        owner: CohortOwner.System
                    })
                )
                const paginatedUserCohortsDTO: PaginatedDTO_CohortDTO_ = yield call(ExplorerCohortsService.indexExplorerCohortsGet, {
                    page: action.payload.query.USER?.page ?? falconEndpoints.cohorts.queryParams.page,
                    limit: action.payload.query.USER?.pageSize ?? falconEndpoints.cohorts.queryParams.limit,
                    query: action.payload.query.USER?.searchValue,
                    isSystem: false
                })
                // Filter out cohorts that already exist in the state
                const cohorts = yield select((state: { cohort: CohortState }) => state.cohort.list.cohorts[CohortOwner.User])
                const newCohorts = paginatedUserCohortsDTO.data.filter((cohortDTO) => {
                    return !cohorts.some((cohort) => cohort.id === cohortDTO.id)
                })
                yield put(
                    cohortListActions.cohortsSet({
                        cohorts: [...cohorts, ...newCohorts],
                        meta: paginatedSystemCohortsDTO.meta,
                        owner: CohortOwner.User
                    })
                )
            }
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(cohortListActions.loadingSet({ loading: false }))
        }
    }
}

export type GetCohortsSaga = ReturnType<typeof createGetCohortsSaga>
