/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Box, Button, CircularProgress, Paper, styled, Table, TableBody, TableContainer, TableRow, TableHead, TableCell } from '@mui/material'
import { CohortAggregationResult, ReportResultDTO, RowListDTO } from '@om1/falcon-api'
import { ContentPadding, InvertContentPadding } from '@om1/platform-ui-kit/src/components/Layout'
import { RoutedFrameworkComponentProps, Routing } from '@om1/platform-utils'
import { createReport } from 'docx-templates'
import Highcharts from 'highcharts'
import html2canvas from 'html2canvas'
import { FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import LogoHorizontal from '../../../../../apps/platform/src/shared/assets/OM1_logo_standard_color.jpg'
import { CohortDashboardCohortCard } from '../../pages/CohortDashboardPage/CohortDashboardCohortCard'
import {
    AnalyticsChartData,
    AnalyticsDataState,
    AnalyticsDimension,
    AnalyticsSection,
    AnalyticsTaskStatus,
    cohortAnalyticsActions,
    cohortBlocksEditActions,
    cohortEditActions,
    CohortListItem,
    DataTypeAnalyticsData,
    DemographicsAnalyticsData,
    OperationNode,
    Report,
    ReportAnalyticsType,
    reportsActions,
    ReportsState
} from '../../state'
import { DataTypeReportChartComponent } from '../shared/DataTypeReportChartComponent'
import {
    DIAGNOSES_REPORT_CONFIG,
    LAB_TESTS_REPORT_CONFIG,
    MEDICATIONS_REPORT_CONFIG,
    OBSERVATIONS_REPORT_CONFIG,
    PROCEDURES_REPORT_CONFIG
} from './CohortReportsComponent'
import { DemographicsReportChartComponent, DemographicsReportRefs } from './DemographicsReportChartComponent'
import { ReadOnlyCohortComponent } from './ReadOnlyCohortComponent'
import { ReportAnalyticsTabType, ReportChartComponent } from './ReportChartComponent'

export type SummaryReportComponentPageProps = RoutedFrameworkComponentProps<
    {},
    { cohortId: string },
    {
        tree: OperationNode
        cohort: CohortListItem
        sizeLoading: boolean
        permissions: string[] | undefined
        summaryReportResult: Array<ReportResultDTO> | undefined
        reports: ReportsState | null
    },
    typeof cohortEditActions & typeof cohortBlocksEditActions & typeof reportsActions,
    {}
>

export const SummaryReportPageComponent: FunctionComponent<SummaryReportComponentPageProps> = ({ state, actions, routing }) => {
    const [loading, setLoading] = useState(true)
    const { cohort, reports }: { cohort: CohortListItem; reports: ReportsState | null } = state
    const reportArray: Report[] = reports?.reports || []

    const [templateBuffer, setTemplateBuffer] = useState<Uint8Array>()

    const queryTitles = useMemo(() => {
        return getCohortTitles(cohort.query?.filters || [])
    }, [cohort.query?.filters])

    useEffect(() => {
        // Fetch the .docx file from the public folder
        const loadTemplate = async () => {
            try {
                const response = await fetch('/summary_report_template.docx')
                if (!response.ok) {
                    throw new Error('Failed to fetch template')
                }
                const arrayBuffer = await response.arrayBuffer() // Convert to ArrayBuffer
                const templateFileBufferUint8 = new Uint8Array(arrayBuffer)
                setTemplateBuffer(templateFileBufferUint8)
            } catch (error) {
                console.error('Error loading template:', error)
            }
        }

        loadTemplate()
    }, [])

    const downloadURL = (data, fileName) => {
        const a = document.createElement('a')
        a.href = data
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        a.remove()
    }

    const saveDataToFile = (data, fileName, mimeType) => {
        const blob = new Blob([data], { type: mimeType })
        const url = window.URL.createObjectURL(blob)
        downloadURL(url, fileName)
        setTimeout(() => {
            window.URL.revokeObjectURL(url)
        }, 1000)
    }

    async function convertSvgToImageBuffer(svgContent: string): Promise<ArrayBuffer> {
        return new Promise((resolve, reject) => {
            // Create an offscreen canvas
            const canvas = document.createElement('canvas')
            const context = canvas.getContext('2d')

            // Create a Blob from the SVG string
            const blob = new Blob([svgContent], { type: 'image/svg+xml;charset=utf-8' })

            // Create an Image element
            const img = new Image()
            const url = URL.createObjectURL(blob)

            img.onload = () => {
                // Set canvas dimensions to match the image
                canvas.width = img.width
                canvas.height = img.height

                // Draw the SVG onto the canvas
                context?.drawImage(img, 0, 0)

                // Convert canvas to data URL (PNG format)
                canvas.toBlob((blob) => {
                    if (blob) {
                        // Convert the blob to an ArrayBuffer
                        const reader = new FileReader()
                        reader.onloadend = () => {
                            resolve(reader.result as ArrayBuffer)
                        }
                        reader.onerror = reject
                        reader.readAsArrayBuffer(blob)
                    } else {
                        reject(new Error('Canvas conversion to Blob failed.'))
                    }
                }, 'image/png')

                // Revoke the object URL after usage
                URL.revokeObjectURL(url)
            }

            img.onerror = reject

            // Start loading the SVG
            img.src = url
        })
    }

    function getSvgDimensions(svgContent: string): { width: number; height: number } {
        // Parse the SVG content into a DOM element
        const parser = new DOMParser()
        const svgDocument = parser.parseFromString(svgContent, 'image/svg+xml')

        // Extract the SVG element (assuming it's the root element)
        const svgElement = svgDocument.documentElement

        // Get the width and height attributes as strings
        const widthStr = svgElement.getAttribute('width')
        const heightStr = svgElement.getAttribute('height')

        // Convert the width and height to numbers, apply the scaling, default to 17 if null or NaN
        const width = widthStr ? (parseFloat(widthStr) / 10) * 2 : 17
        const height = heightStr ? (parseFloat(heightStr) / 10) * 2 : 17

        return { width: isNaN(width) ? 17 : width, height: isNaN(height) ? 17 : height }
    }

    const handleGenerateReport = async () => {
        if (!templateBuffer) {
            console.warn('Template not loaded yet')
            return
        }

        try {
            const data = {
                name: cohort.name,
                size: cohort.cohortSize ? cohort.cohortSize : '',
                date: getFormattedDate()
            }

            const createSvgContext = async (key: string, dimensions?: { widthModifier?: number; heightModifier?: number }) => {
                if (svgContents[key] !== '') {
                    const svgBuffer = await convertSvgToImageBuffer(svgContents[key])
                    const svgDimensions = getSvgDimensions(svgContents[key])
                    return () => ({
                        width: dimensions?.widthModifier ? svgDimensions.width / dimensions.widthModifier : svgDimensions.width / 9.5,
                        height: dimensions?.heightModifier ? svgDimensions.height / dimensions.heightModifier : svgDimensions.height / 9.5,
                        data: svgBuffer,
                        extension: '.png'
                    })
                } else {
                    return () => ({ width: 0, height: 0, data: '', extension: '.png' })
                }
            }

            // Capture the cohort-capture element as a canvas
            const cohortCriteria = document.getElementById('cohort-criteria')
            const elementWidth = cohortCriteria ? cohortCriteria.offsetWidth : 0
            const elementHeight = cohortCriteria ? cohortCriteria.offsetHeight : 0
            let canvas
            let arrayBuffer
            if (cohortCriteria) {
                canvas = await html2canvas(cohortCriteria)
                const dataURL = canvas.toDataURL('image/png')
                const base64String = dataURL.split(',')[1]
                const binaryString = atob(base64String)
                const len = binaryString.length
                arrayBuffer = new ArrayBuffer(len)
                const uintArray = new Uint8Array(arrayBuffer)

                for (let i = 0; i < len; i++) {
                    uintArray[i] = binaryString.charCodeAt(i)
                }
            } else {
                canvas = { width: 0, height: 0 }
                arrayBuffer = ''
            }

            const attritionTable = document.getElementById('attrition-table')
            let attritionBuffer
            let attritionCanvas
            const attritionElementWidth = attritionTable ? attritionTable.offsetWidth : 0
            const attritionElementHeight = attritionTable ? attritionTable.offsetHeight : 0
            if (attritionTable) {
                attritionCanvas = await html2canvas(attritionTable)
                const dataURL = attritionCanvas.toDataURL('image/png')
                const base64String = dataURL.split(',')[1]
                const binaryString = atob(base64String)
                const len = binaryString.length
                attritionBuffer = new ArrayBuffer(len)
                const uintArrayAttrition = new Uint8Array(attritionBuffer)

                for (let i = 0; i < len; i++) {
                    uintArrayAttrition[i] = binaryString.charCodeAt(i)
                }
            }

            const additionalJsContext = {
                data_type_svg: await createSvgContext(ReportAnalyticsType.DATA_TYPE),
                observations_svg: await createSvgContext(ReportAnalyticsType.OBSERVATIONS),
                diagnoses_svg: await createSvgContext(ReportAnalyticsType.DIAGNOSES),
                medications_svg: await createSvgContext(ReportAnalyticsType.MEDICATIONS),
                procedures_svg: await createSvgContext(ReportAnalyticsType.PROCEDURES),
                lab_tests_svg: await createSvgContext(ReportAnalyticsType.LAB_TESTS),
                age_svg: await createSvgContext('age', { widthModifier: 19, heightModifier: 19 }),
                sex_svg: await createSvgContext('sex', { widthModifier: 19, heightModifier: 19 }),
                race_svg: await createSvgContext('race', { widthModifier: 19, heightModifier: 19 }),
                ethnicity_svg: await createSvgContext('ethnicity', { widthModifier: 19, heightModifier: 19 }),
                state_svg: await createSvgContext('state', { widthModifier: 8, heightModifier: 8.5 }),
                state_map_svg: await createSvgContext('stateMap', { widthModifier: 8, heightModifier: 8.5 }),
                cohort_definition_svg: () => ({
                    width: elementHeight / 40 > 16 ? 16 : elementWidth / 40,
                    height: elementHeight / 40 > 15 ? 15 : elementHeight / 40,
                    data: arrayBuffer,
                    extension: '.png'
                }),
                cohort_attrition_svg: () => ({
                    width: attritionElementHeight / 40 > 15 ? 7.5 : attritionElementWidth / 40,
                    height: attritionElementHeight / 40 > 15 ? 15 : attritionElementHeight / 40,
                    data: attritionBuffer,
                    extension: '.png'
                })
            }

            const report = await createReport({
                template: templateBuffer,
                data,
                additionalJsContext
            })

            const toSafeFilename = (input: string): string => {
                let safeString = input.replace(/[^a-zA-Z0-9-_]/g, '_')
                const now = new Date()
                const formattedDate = now.toISOString().split('T')[0].replace(/-/g, '_')
                return `${safeString}_${formattedDate}`
            }

            // Handle the generated report (e.g., download it or display it)
            saveDataToFile(report, toSafeFilename(cohort.name), 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
        } catch (error) {
            console.error('Error generating report:', error)
        }
    }

    // Get report definitions

    useEffect(() => {
        if (routing.pathParams.cohortId && state.cohort.summaryReportTaskId !== undefined) {
            actions.summaryReportGet({ cohortId: routing.pathParams.cohortId })
        }
    }, [actions, routing.pathParams.cohortId, state.cohort.summaryReportTaskId])

    // Reports

    function processDemographicsChart(summaryReportResult: ReportResultDTO[]): AnalyticsDataState<DemographicsAnalyticsData> {
        const result: AnalyticsDataState<DemographicsAnalyticsData> = {
            taskId: state.cohort.summaryReportTaskId || '',
            status: state.cohort.summaryReportTaskId !== undefined ? AnalyticsTaskStatus.Complete : AnalyticsTaskStatus.Pending,
            data: {
                [AnalyticsDimension.Age]: {},
                [AnalyticsDimension.Ethnicity]: {},
                [AnalyticsDimension.Race]: {},
                [AnalyticsDimension.Sex]: {},
                [AnalyticsDimension.State]: {}
            }
        }
        const demographicsReportResultDTO: ReportResultDTO | undefined = summaryReportResult.find((report) => report.reportType === 'demographics')
        const demographicsSectionResults: (RowListDTO | CohortAggregationResult)[] | undefined = demographicsReportResultDTO?.sections.find(
            (section) => section.id === 'demographics_chart'
        )?.results
        const demographicsSection: RowListDTO | CohortAggregationResult | undefined =
            demographicsSectionResults && demographicsSectionResults.length > 0 ? demographicsSectionResults[0] : undefined
        demographicsSection &&
            Object.keys(demographicsSection as CohortAggregationResult).forEach((field) => {
                const normalizedField = field === 'current_age' ? 'age' : field
                const dimension = normalizedField.toUpperCase() as keyof typeof AnalyticsDimension
                const data: Record<string, any>[] = (demographicsSection as CohortAggregationResult)[field].data
                const resultData: { [key: string]: number } = {}
                data.forEach((item) => {
                    resultData[item[field]] = item.patient_count
                })
                result.data[dimension] = resultData
            })
        return result
    }

    function processDataTypesChart(summaryReportResult: ReportResultDTO[]): AnalyticsDataState<DataTypeAnalyticsData> {
        const result: AnalyticsDataState<DataTypeAnalyticsData> = {
            taskId: state.cohort.summaryReportTaskId || '',
            status: state.cohort.summaryReportTaskId !== undefined ? AnalyticsTaskStatus.Complete : AnalyticsTaskStatus.Pending,
            data: {}
        }
        const dataTypeReportResultDTO: ReportResultDTO | undefined = summaryReportResult.find((report) => report.reportType === 'data_type')
        const dataTypeSectionResults: (RowListDTO | CohortAggregationResult)[] | undefined = dataTypeReportResultDTO?.sections.find(
            (section) => section.id === 'data_type_chart'
        )?.results
        const dataTypeSection: RowListDTO | CohortAggregationResult | undefined =
            dataTypeSectionResults && dataTypeSectionResults.length > 0 ? dataTypeSectionResults[0] : undefined
        let resultData: { [key: string]: number } = {}
        if (dataTypeSection) {
            Object.keys(dataTypeSection).forEach((key) => {
                const trueData = dataTypeSection[key].data.find((item: { [k: string]: string | number }) => item[key] === 'true')
                if (trueData) {
                    resultData[key] = trueData.patient_count
                }
            })
        }
        result.data = resultData
        return result
    }

    function processGenericChartAnalytics(
        summaryReportResult: ReportResultDTO[],
        reportType: ReportAnalyticsType
    ): AnalyticsDataState<AnalyticsChartData> {
        const mappedReportType = (reportType: ReportAnalyticsType) => {
            switch (reportType) {
                case ReportAnalyticsType.OBSERVATIONS:
                    return 'observation'
                case ReportAnalyticsType.DIAGNOSES:
                    return 'diagnosis'
                case ReportAnalyticsType.MEDICATIONS:
                    return 'medication'
                case ReportAnalyticsType.PROCEDURES:
                    return 'procedure'
                case ReportAnalyticsType.LAB_TESTS:
                    return 'lab'
                default:
                    return ''
            }
        }

        const reportResultDTO: ReportResultDTO | undefined = summaryReportResult.find((report) => report.reportType === mappedReportType(reportType))
        const returnVal: AnalyticsDataState<AnalyticsChartData> = {
            taskId: cohort.summaryReportTaskId || '',
            status: cohort.summaryReportTaskId !== undefined ? AnalyticsTaskStatus.Complete : AnalyticsTaskStatus.Pending,
            data: {
                sections: (reportResultDTO?.sections as AnalyticsSection[]) || []
            }
        }
        return returnVal
    }

    // Mapping reports to images

    type ChartDataType = Exclude<ReportAnalyticsType, ReportAnalyticsType.DEMOGRAPHICS | ReportAnalyticsType.COHORT>

    interface ChartDataMap {
        [ReportAnalyticsType.OBSERVATIONS]: AnalyticsDataState<AnalyticsChartData>
        [ReportAnalyticsType.DIAGNOSES]: AnalyticsDataState<AnalyticsChartData>
        [ReportAnalyticsType.MEDICATIONS]: AnalyticsDataState<AnalyticsChartData>
        [ReportAnalyticsType.PROCEDURES]: AnalyticsDataState<AnalyticsChartData>
        [ReportAnalyticsType.LAB_TESTS]: AnalyticsDataState<AnalyticsChartData>
    }

    const processChartData = (dataType: ChartDataType): AnalyticsDataState<AnalyticsChartData> =>
        processGenericChartAnalytics(state.summaryReportResult || [], dataType)

    const chartData: ChartDataMap = {
        [ReportAnalyticsType.OBSERVATIONS]: processChartData(ReportAnalyticsType.OBSERVATIONS),
        [ReportAnalyticsType.DIAGNOSES]: processChartData(ReportAnalyticsType.DIAGNOSES),
        [ReportAnalyticsType.MEDICATIONS]: processChartData(ReportAnalyticsType.MEDICATIONS),
        [ReportAnalyticsType.PROCEDURES]: processChartData(ReportAnalyticsType.PROCEDURES),
        [ReportAnalyticsType.LAB_TESTS]: processChartData(ReportAnalyticsType.LAB_TESTS)
    }
    const demographicsChartData: AnalyticsDataState<DemographicsAnalyticsData> = processDemographicsChart(state.summaryReportResult || [])
    const dataTypesChartData: AnalyticsDataState<DataTypeAnalyticsData> = processDataTypesChart(state.summaryReportResult || [])

    const ageChartRef = useRef<Highcharts.Chart | null>(null)
    const sexChartRef = useRef<Highcharts.Chart | null>(null)
    const raceChartRef = useRef<Highcharts.Chart | null>(null)
    const ethnicityChartRef = useRef<Highcharts.Chart | null>(null)
    const stateChartRef = useRef<Highcharts.Chart | null>(null)
    const stateMapChartRef = useRef<Highcharts.Chart | null>(null)

    const demographicsReportRefs = useMemo<DemographicsReportRefs>(() => {
        return {
            age: ageChartRef,
            sex: sexChartRef,
            race: raceChartRef,
            ethnicity: ethnicityChartRef,
            state: stateChartRef,
            stateMap: stateMapChartRef
        }
    }, [])

    const dataTypesRef = useRef<Highcharts.Chart | null>(null)
    const observationChartRef = useRef<Highcharts.Chart | null>(null)
    const diagnosesChartRef = useRef<Highcharts.Chart | null>(null)
    const medicationsChartRef = useRef<Highcharts.Chart | null>(null)
    const proceduresChartRef = useRef<Highcharts.Chart | null>(null)
    const labTestsChartRef = useRef<Highcharts.Chart | null>(null)

    const otherChartRefs = useMemo(() => {
        return {
            [ReportAnalyticsType.DATA_TYPE]: dataTypesRef,
            [ReportAnalyticsType.OBSERVATIONS]: observationChartRef,
            [ReportAnalyticsType.DIAGNOSES]: diagnosesChartRef,
            [ReportAnalyticsType.MEDICATIONS]: medicationsChartRef,
            [ReportAnalyticsType.PROCEDURES]: proceduresChartRef,
            [ReportAnalyticsType.LAB_TESTS]: labTestsChartRef
        }
    }, [])

    const [svgContents, setSvgContents] = useState<Record<string, string>>({
        age: '',
        sex: '',
        race: '',
        ethnicity: '',
        state: '',
        stateMap: '',
        [ReportAnalyticsType.DATA_TYPE]: '',
        [ReportAnalyticsType.OBSERVATIONS]: '',
        [ReportAnalyticsType.DIAGNOSES]: '',
        [ReportAnalyticsType.MEDICATIONS]: '',
        [ReportAnalyticsType.PROCEDURES]: '',
        [ReportAnalyticsType.LAB_TESTS]: ''
    })

    const DemographicsReportChart: FunctionComponent = () => (
        <Box sx={{ display: 'none' }}>
            <DemographicsReportChartComponent
                props={{
                    cohort,
                    demographicsReportRefs,
                    renderSexAsBarChart: true
                }}
                state={{
                    analytics: demographicsChartData,
                    reports,
                    reportsLoading: false
                }}
                actions={{ ...reportsActions, ...cohortAnalyticsActions }}
            />
        </Box>
    )

    const DataTypeReportChart: FunctionComponent = () => (
        <Box sx={{ display: 'none' }} id='data-type-report-chart'>
            <DataTypeReportChartComponent
                props={{ cohort, disableFetching: true, chartRefProp: otherChartRefs[ReportAnalyticsType.DATA_TYPE] }}
                state={{
                    analytics: dataTypesChartData,
                    reports,
                    reportsLoading: false
                }}
                actions={{ ...reportsActions, ...cohortAnalyticsActions }}
            />
        </Box>
    )

    interface ReportChartComponentWrapperProps {
        type: ReportAnalyticsTabType
        config: any // Replace 'any' with the specific type if available
    }

    const fakeRouting: Routing<{ reportAnalyticsType?: ReportAnalyticsType }, { cohortId: string }> = {
        pathParams: { cohortId: cohort.id },
        queryParams: { reportAnalyticsType: ReportAnalyticsType.OBSERVATIONS },
        matchUrl: null,
        updateQuery: function (update: Partial<{ reportAnalyticsType?: ReportAnalyticsType }>): void {
            throw new Error('Function not implemented.')
        }
    }

    const ReportChartComponentWrapper: FunctionComponent<ReportChartComponentWrapperProps> = ({ type, config }) => {
        return (
            <Box sx={{ display: 'none' }}>
                <ReportChartComponent
                    props={{
                        config,
                        cohort,
                        reportAnalyticsType: type,
                        disableFetching: true,
                        renderInDashboard: true,
                        chartRefProp: otherChartRefs[type]
                    }}
                    state={{
                        analytics: chartData[type],
                        reports: reportArray,
                        reportsLoading: false
                    }}
                    actions={{ ...reportsActions, ...cohortAnalyticsActions }}
                    routing={fakeRouting}
                />
            </Box>
        )
    }

    const Charts = {
        Observation: () => <ReportChartComponentWrapper type={ReportAnalyticsType.OBSERVATIONS} config={OBSERVATIONS_REPORT_CONFIG} />,
        Diagnosis: () => <ReportChartComponentWrapper type={ReportAnalyticsType.DIAGNOSES} config={DIAGNOSES_REPORT_CONFIG} />,
        Medication: () => <ReportChartComponentWrapper type={ReportAnalyticsType.MEDICATIONS} config={MEDICATIONS_REPORT_CONFIG} />,
        Procedure: () => <ReportChartComponentWrapper type={ReportAnalyticsType.PROCEDURES} config={PROCEDURES_REPORT_CONFIG} />,
        Lab: () => <ReportChartComponentWrapper type={ReportAnalyticsType.LAB_TESTS} config={LAB_TESTS_REPORT_CONFIG} />
    }

    // Printing config
    const gatherSvgContents = useCallback(async () => {
        const chartOptions: Highcharts.Options = {
            exporting: {
                sourceWidth: 756,
                scale: 5
            }
        }

        const allChartRefs = { ...demographicsReportRefs, ...otherChartRefs }

        ;(Object.keys(allChartRefs) as (keyof typeof allChartRefs)[]).forEach((key) => {
            const chart = allChartRefs[key].current
            if (chart) {
                const svg = chart.getSVG(chartOptions)
                setSvgContents((prev) => ({ ...prev, [key]: svg }))
            }
        })
    }, [demographicsReportRefs, otherChartRefs])

    const Logo = styled('img')({
        lineHeight: 0,
        cursor: 'pointer',
        width: '200px'
    })

    // Manage Loading

    useEffect(() => {
        if (cohort.id) {
            actions.reportsSet({ reports: [] })
            actions.reportsGet({ cohortId: cohort.id })
            const disableLoading = () => {
                setTimeout(() => {
                    setTimeout(() => {
                        gatherSvgContents()
                    }, 500)
                    setLoading(false)
                }, 3500)
            }

            disableLoading()
        }
    }, [actions, cohort.id, gatherSvgContents])

    // File Name conversion

    function getFormattedDate(): string {
        const date = new Date()
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }
        return date.toLocaleDateString('en-US', options)
    }

    // Render

    interface ChartSectionProps {
        title: string
        svgContent: string
    }

    const ChartSection: FunctionComponent<ChartSectionProps> = ({ title, svgContent }) => {
        if (!svgContent) return null

        return (
            <Box display='flex' flexDirection='column' alignItems='center' width='776px' marginTop={'20px'}>
                <Box>
                    <h2>{title}</h2>
                </Box>
                <Box dangerouslySetInnerHTML={{ __html: svgContent }} />
            </Box>
        )
    }

    interface ChartWrapperProps {
        children: ReactNode
    }

    const ChartWrapper: FunctionComponent<ChartWrapperProps> = ({ children }) => (
        <Box display='flex' justifyContent='center' width='100%' flexDirection='column' alignItems='center'>
            {children}
        </Box>
    )

    return (
        <Box>
            <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                sx={{ position: 'relative', backgroundColor: 'grey.100', marginTop: '-32px' }}
            >
                <Button onClick={() => handleGenerateReport()} sx={{ width: '100%' }} disabled={!templateBuffer || loading}>
                    Download Report
                </Button>
            </Box>
            <InvertContentPadding padX padY sx={{ backgroundColor: 'white', marginTop: '0px' }}>
                {loading ? (
                    <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <ContentPadding
                            padX
                            padY
                            sx={{
                                pointerEvents: 'none',
                                minHeight: 'none !important',
                                height: 'inherit !important',
                                backgroundColor: 'inherit',
                                maxWidth: '100%',
                                paddingBottom: '10px'
                            }}
                        >
                            <Box display='flex' justifyContent='center' width='100%'>
                                <Box height={'75px'}></Box>
                                <Box sx={{ padding: '20px', width: '776px' }}>
                                    <Box display='flex' justifyContent='center' width='100%'>
                                        <Logo src={LogoHorizontal} />
                                    </Box>
                                    <Box height={'75px'}></Box>
                                    <Box display='flex' flexDirection='column' alignItems='center' width='746px' marginTop={'20px'} color='#082D74'>
                                        <Box sx={{ fontWeight: 'bold', fontSize: '30px' }}>
                                            <h1>OM1 Insights</h1>
                                        </Box>
                                        <Box sx={{ fontWeight: 'bold', fontSize: '24px' }}>
                                            <h3>{cohort.name}</h3>
                                        </Box>
                                        <Box sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                                            <h5>{getFormattedDate()}</h5>
                                        </Box>
                                    </Box>
                                    <div className='pagebreak'></div>
                                    {state.cohort && <CohortDashboardCohortCard cohort={cohort} actions={[]} sizeLoading={state.sizeLoading} />}
                                    {!state.cohort.isSystem && (
                                        <>
                                            <ReadOnlyCohortComponent state={state} actions={actions} props={{ cohort: state.cohort }} />
                                            <TableContainer id='attrition-table' component={Paper} sx={{ my: '50px' }}>
                                                <h4 style={{ fontWeight: 'bold', fontSize: '24px', margin: '10px 20px' }}>Attrition</h4>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                {cohort.analyticsDataset.name === 'OM1 Real World Data Cloud'
                                                                    ? 'Patients in OM1 RWDC'
                                                                    : 'Patients in ' + cohort.analyticsDataset.name + ' PremiOM dataset'}
                                                            </TableCell>
                                                            <TableCell>{cohort.analyticsDataset.size}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {cohort.attritionFunnel?.map((attrition, idx) => (
                                                            <TableRow key={idx}>
                                                                <TableCell>{queryTitles[idx] || 'Criteria ' + (idx + 1)}</TableCell>
                                                                <TableCell>{attrition}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>
                                    )}
                                </Box>
                            </Box>
                            <div className='pagebreak'></div>
                            <ChartWrapper>
                                <DemographicsReportChart />
                                <ChartSection title='Patients by Age' svgContent={svgContents.age} />
                                <div className='pagebreak'></div>
                                <ChartSection title='Patients by Sex' svgContent={svgContents.sex} />
                                <div className='pagebreak'></div>
                                <ChartSection title='Patients by Race' svgContent={svgContents.race} />
                                <div className='pagebreak'></div>
                                <ChartSection title='Patients by Ethnicity' svgContent={svgContents.ethnicity} />
                                <div className='pagebreak'></div>
                                <ChartSection title='Patients by State' svgContent={svgContents.state} />
                                <div className='pagebreak'></div>
                                <ChartSection title='Patients by State Map' svgContent={svgContents.stateMap} />
                            </ChartWrapper>
                            <div className='pagebreak'></div>
                            <ChartWrapper>
                                <DataTypeReportChart />
                                <ChartSection title='Data Types' svgContent={svgContents[ReportAnalyticsType.DATA_TYPE]} />
                            </ChartWrapper>
                            <div className='pagebreak'></div>
                            <ChartWrapper>
                                <Charts.Observation />
                                <ChartSection title='Observations' svgContent={svgContents[ReportAnalyticsType.OBSERVATIONS]} />
                            </ChartWrapper>
                            <div className='pagebreak'></div>
                            <ChartWrapper>
                                <Charts.Diagnosis />
                                <ChartSection title='Diagnosis' svgContent={svgContents[ReportAnalyticsType.DIAGNOSES]} />
                            </ChartWrapper>
                            <div className='pagebreak'></div>
                            <ChartWrapper>
                                <Charts.Medication />
                                <ChartSection title='Medications' svgContent={svgContents[ReportAnalyticsType.MEDICATIONS]} />
                            </ChartWrapper>
                            <div className='pagebreak'></div>
                            <ChartWrapper>
                                <Charts.Procedure />
                                <ChartSection title='Procedures' svgContent={svgContents[ReportAnalyticsType.PROCEDURES]} />
                            </ChartWrapper>
                            <div className='pagebreak'></div>
                            <ChartWrapper>
                                <Charts.Lab />
                                <ChartSection title='Labs' svgContent={svgContents[ReportAnalyticsType.LAB_TESTS]} />
                            </ChartWrapper>
                        </ContentPadding>
                    </>
                )}
            </InvertContentPadding>
        </Box>
    )
}

export const getCohortTitles = (filters: any[]) => {
    const titles = filters.map((filter) => {
        const name = filter.name
        // If we find a name, that means it's the top level of a criteria
        // Assumes no nested criteria can be named
        if (name) return name

        // No name, so check the children
        if (filter.type === 'ExceptDTO') {
            return getCohortTitles(filter.except)
        } else if (filter.type === 'AndDTO') {
            return getCohortTitles(filter.and)
        } else if (filter.type === 'OrDTO') {
            return getCohortTitles(filter.or)
        } else {
            // If no name is found in the deepest level,
            // name will be null or defined
            return name
        }
    })
    return titles.flat()
}
