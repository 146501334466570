import { withRouter } from '@om1/platform-routing/WithRouter'
import { connectRedux } from '@om1/platform-utils'
import { ExplorerAssistantConversationState } from '../state'
import { explorerAssistantConversationActions } from '../state/explorer-assistant-conversation-actions'
import { EditCohortConversationComponent } from './EditCohortConversationComponent'

export function createEditCohortConversationComponent<TState extends { explorerAssistantConversation: ExplorerAssistantConversationState }>() {
    return connectRedux(
        withRouter(EditCohortConversationComponent, { namespace: 'cohort-assistant' }),
        (state: TState) => state.explorerAssistantConversation,
        { ...explorerAssistantConversationActions }
    )
}

export type EditCohortConversation = ReturnType<typeof createEditCohortConversationComponent>
