import { AssistantConversationDTO, ExplorerAssistantConversationService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { explorerAssistantConversationActions } from '../state/explorer-assistant-conversation-actions'

export function createUpdateExplorerAssistantConversationSaga() {
    return function* (action: ReturnType<typeof explorerAssistantConversationActions.updateConversation>) {
        try {
            const assistantConversationDTO: AssistantConversationDTO = yield call(
                ExplorerAssistantConversationService.patchExplorerAssistantConversationIdPatch,
                { id: action.payload.id, requestBody: action.payload.requestBody }
            )
            yield put(explorerAssistantConversationActions.updateConversationSuccess(assistantConversationDTO))
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(explorerAssistantConversationActions.setConversationsLoading(false))
        }
    }
}

export type UpdateExplorerAssistantConversationSaga = ReturnType<typeof createUpdateExplorerAssistantConversationSaga>
