import { OperationNode } from '../../state'

export function splitAttritionFunnel(
    attritionFunnel: number[],
    datasetSize: number,
    inclusionRoot?: OperationNode,
    exclusionRoot?: OperationNode
): { attritionInclusionLabels: string[]; attritionExclusionLabels: string[] } {
    const inclusionChildrenCount = inclusionRoot?.children?.length ?? 0
    const exclusionChildrenCount = exclusionRoot?.children?.length ?? 0

    const lengthsMatch = inclusionChildrenCount + exclusionChildrenCount === attritionFunnel.length
    if (!lengthsMatch) {
        return { attritionInclusionLabels: [], attritionExclusionLabels: [] }
    }

    const numberFormatter = new Intl.NumberFormat(undefined)
    const percentFormatter = new Intl.NumberFormat(undefined, { style: 'percent' })

    const formatLabel = (value: number): string => {
        const formattedValue = numberFormatter.format(value)
        const formattedPercentage = percentFormatter.format(value / datasetSize)
        const displayPercentage = formattedPercentage === '0%' ? '<1%' : formattedPercentage
        return `${formattedValue} (${displayPercentage})`
    }

    const attritionInclusionLabels: string[] = []
    const attritionExclusionLabels: string[] = []

    attritionInclusionLabels.push(...attritionFunnel.slice(0, inclusionChildrenCount).map(formatLabel))
    attritionExclusionLabels.push(...attritionFunnel.slice(inclusionChildrenCount).map(formatLabel))

    return { attritionInclusionLabels, attritionExclusionLabels }
}
