import { debounce, takeEvery, takeLatest } from 'redux-saga/effects'
import {
    AnalyticsRefDestination,
    CohortBlocksEditActionTypes,
    CohortCommonActionTypes,
    CohortCreateActionTypes,
    CohortEditActionTypes,
    CohortListActionTypes,
    DatasetsActionTypes,
    ReportsActionTypes
} from '../state'
import { CohortDeleteActionTypes } from '../state/delete'
import { createCreateCohortSaga } from './create-cohort-saga'
import { createCreateReportNoFilterSaga } from './create-no-filter-report-saga'
import { createCreateReportSaga } from './create-report-saga'
import { createDeleteCohortSaga } from './delete-cohort-saga'
import { createDeleteReportSaga } from './delete-report-saga'
import { createDuplicateCohortSaga } from './duplicate-cohort-saga'
import { createGenerateSummaryReportSaga } from './generate-summary-report-saga'
import { createGetCohortReportDataSaga } from './get-cohort-report-data-saga'
import { createGetCohortSaga } from './get-cohort-saga'
import { createGetCohortAttritionFunnelSaga } from './get-cohort-attrition-funnel-saga'
import { createGetCohortSizeSaga } from './get-cohort-size-saga'
import { createGetCohortSqlSaga } from './get-cohort-sql-saga'
import { createGetCohortsSaga } from './get-cohorts-saga'
import { createGetDatasetsSaga } from './get-datasets-saga'
import { createGetDemographicRefsSaga } from './get-demographic-refs-saga'
import { createGetRefLabelsSaga } from './get-ref-label-values-saga'
import { createGetReportsSaga } from './get-reports-saga'
import { createGetSummaryReportSaga } from './get-summary-report-saga'
import { createParseCohortTreeSaga } from './parse-cohort-tree-saga'
import { createQueryRefsSaga } from './query-refs-saga'
import { createUpdateCohortObservationPeriodSaga } from './update-cohort-observation-period-saga'
import { createUpdateCohortQuerySaga } from './update-cohort-query-saga'
import { createUpdateCohortSaga } from './update-cohort-saga'
import { createUpdateReportSaga } from './update-report-saga'

export function createCohortsSaga() {
    const createCohortSaga = createCreateCohortSaga()
    const deleteCohortSaga = createDeleteCohortSaga()
    const getCohortSaga = createGetCohortSaga()
    const parseCohortTreeSaga = createParseCohortTreeSaga()
    const getCohortsSaga = createGetCohortsSaga()
    const duplicateCohortSaga = createDuplicateCohortSaga()
    const getCohortReportDataSaga = createGetCohortReportDataSaga()
    const getDatasetsSaga = createGetDatasetsSaga()
    const getDemographicRefsSaga = createGetDemographicRefsSaga()
    const queryRefsSaga = createQueryRefsSaga()
    const updateCohortSaga = createUpdateCohortSaga()
    const updateCohortQuerySaga = createUpdateCohortQuerySaga()
    const getCohortAttritionFunnelSaga = createGetCohortAttritionFunnelSaga()
    const getCohortSizeSaga = createGetCohortSizeSaga()
    const getCohortSqlSaga = createGetCohortSqlSaga()
    const getRefLabelsSaga = createGetRefLabelsSaga()
    const getReportsSaga = createGetReportsSaga()
    const createReportSaga = createCreateReportSaga()
    const updateReportSaga = createUpdateReportSaga()
    const updateCohortObservationPeriodSaga = createUpdateCohortObservationPeriodSaga()
    const createReportNoFilterSaga = createCreateReportNoFilterSaga()
    const deleteReportSaga = createDeleteReportSaga()
    const generateSummaryReportSaga = createGenerateSummaryReportSaga()
    const summaryReportGet = createGetSummaryReportSaga()

    return function* () {
        yield debounce(0, CohortCreateActionTypes.COHORT_CREATE, createCohortSaga)
        yield debounce(0, CohortListActionTypes.COHORT_DUPLICATE, duplicateCohortSaga)
        yield debounce(0, CohortDeleteActionTypes.COHORT_DELETE, deleteCohortSaga)
        yield debounce(0, CohortEditActionTypes.COHORT_PARSE_TREE, parseCohortTreeSaga)
        yield debounce(0, CohortEditActionTypes.COHORT_GET, getCohortSaga)
        yield debounce(0, CohortListActionTypes.COHORTS_ACCESS, getCohortsSaga)
        yield debounce(0, DatasetsActionTypes.GET, getDatasetsSaga)
        yield debounce(0, CohortEditActionTypes.DEMOGRAPHICS_REFS_GET, getDemographicRefsSaga)
        yield debounce(
            0,
            (action) => {
                return action.type === CohortBlocksEditActionTypes.REFS_QUERY && action.payload.destination === AnalyticsRefDestination.SEARCH
            },
            queryRefsSaga
        )
        yield debounce(
            0,
            (action) => {
                return action.type === CohortBlocksEditActionTypes.REFS_QUERY && action.payload.destination === AnalyticsRefDestination.RESTORE
            },
            queryRefsSaga
        )
        yield debounce(0, CohortEditActionTypes.COHORT_UPDATE, updateCohortSaga)
        yield debounce(0, CohortEditActionTypes.COHORT_UPDATE_QUERY, updateCohortQuerySaga)
        yield takeLatest(CohortCommonActionTypes.COHORT_AUTOSAVE_SIZE_GET, getCohortSizeSaga)
        yield takeEvery(CohortCommonActionTypes.COHORT_SIZE_GET, getCohortSizeSaga)
        yield takeLatest(CohortEditActionTypes.COHORT_ATTRITION_FUNNEL_GET, getCohortAttritionFunnelSaga)
        yield debounce(0, CohortEditActionTypes.COHORT_SQL_GET, getCohortSqlSaga)
        yield debounce(0, CohortBlocksEditActionTypes.REF_LABELS_GET, getRefLabelsSaga)
        yield debounce(0, ReportsActionTypes.REPORTS_GET, getReportsSaga)
        yield debounce(0, ReportsActionTypes.REPORT_CREATE, createReportSaga)
        yield debounce(0, ReportsActionTypes.REPORT_NO_FILTER_CREATE, createReportNoFilterSaga)
        yield debounce(0, ReportsActionTypes.REPORT_UPDATE, updateReportSaga)
        yield debounce(0, ReportsActionTypes.REPORT_TASK_GET, getCohortReportDataSaga)
        yield debounce(0, CohortBlocksEditActionTypes.OBSERVATION_PERIOD_UPDATE, updateCohortObservationPeriodSaga)
        yield debounce(0, ReportsActionTypes.REPORT_DELETE, deleteReportSaga)
        yield debounce(0, CohortCommonActionTypes.COHORT_GENERATE_SUMMARY_REPORT, generateSummaryReportSaga)
        yield debounce(0, ReportsActionTypes.SUMMARY_REPORT_GET, summaryReportGet)
    }
}
