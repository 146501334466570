import { connectRedux } from '@om1/platform-utils'
import { ExplorerAssistantConversationState } from '../state'
import { explorerAssistantConversationActions } from '../state/explorer-assistant-conversation-actions'
import { ActiveConversationComponent } from './ActiveConversationComponent'

export function createActiveConversationComponent<TState extends { explorerAssistantConversation: ExplorerAssistantConversationState }>() {
    return connectRedux(
        ActiveConversationComponent,
        (state: TState) => ({
            activeConversation: state.explorerAssistantConversation.activeConversation,
            assistantIsResponding: state.explorerAssistantConversation.assistantIsResponding
        }),
        { ...explorerAssistantConversationActions }
    )
}

export type ActiveConversation = ReturnType<typeof createActiveConversationComponent>
