import { t, Trans } from '@lingui/macro'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { Breadcrumbs, CircularProgress, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid, GridColDef, GridRowSpacingParams } from '@mui/x-data-grid'
import { cohortListActions } from '@om1/cohort-module/src/state'
import { CohortInsightsReportDTO } from '@om1/falcon-api'
import { insightsLibraryActions, InsightsLibraryState } from '@om1/insights-library-module'
import { FrameworkButton } from '@om1/platform-components'
import { Routes, toPath } from '@om1/platform-routing'
import { BreadcrumbLink, ContentPadding } from '@om1/platform-ui-kit/src/components/Layout'
import { getStyledGrid } from '@om1/platform-ui-kit/src/styles'
import { PlatformPermissions, RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { DeleteInsightsLibraryReportDialog } from '../components/DeleteInsightsLibraryReportDialog'
import { createRegisterInsightsLibraryReportModal } from '../components/RegisterInsightsLibraryReportModal'
import { createManageInsightsLibraryPage } from './ManageInsightsLibraryPage'

const RegisterInsightsLibraryReportModal = createRegisterInsightsLibraryReportModal()

export type ManageInsightsLibraryPageComponentProps = RoutedFrameworkComponentProps<
    {},
    {},
    {
        insightsLibrary: InsightsLibraryState
        permissions?: string[] | undefined
    },
    typeof insightsLibraryActions & typeof cohortListActions,
    {}
>

export const ManageInsightsLibraryPageComponent: FunctionComponent<ManageInsightsLibraryPageComponentProps> = ({ state, actions }) => {
    const { permissions, insightsLibrary } = state
    const [registerInsightsLibraryReportModalVisible, setRegisterInsightsLibraryReportModalVisible] = useState(false)

    const handleRegisterInsightsLibraryReportModalOpen = () => {
        setRegisterInsightsLibraryReportModalVisible(true)
    }

    const handleRegisterInsightsLibraryReportModalClose = () => {
        setRegisterInsightsLibraryReportModalVisible(false)
    }

    useEffect(() => {
        actions.cohortsGetSystemCohorts({ query: {}, path: {} })
    }, [actions])

    const [deleteModalReport, setDeleteModalReport] = useState<CohortInsightsReportDTO>()
    const handleDeleteHide = useCallback(() => setDeleteModalReport(undefined), [])

    const StyledDataGrid = getStyledGrid(DataGrid) as typeof DataGrid

    const columns = useMemo<GridColDef<CohortInsightsReportDTO>[]>(() => {
        const columns = [
            {
                field: 'cohortName',
                headerName: t`Cohort Name`,
                renderCell: (params) => {
                    const cohortName = insightsLibrary.cohortInsightsReports.find((c) => c.id === params.row.id)?.cohortName
                    return (
                        <Box display='flex' flexDirection='column' gap={1} minWidth={0} maxWidth='100%'>
                            <Typography color='primary' fontWeight='bold' className='grid-content' noWrap>
                                {cohortName ? cohortName : t`Unknown Cohort`}
                            </Typography>
                        </Box>
                    )
                },
                flex: 3,
                sortable: false,
                filterable: false
            },
            {
                field: 'insightsReportName',
                headerName: t`Insights Report Name`,
                renderCell: (params) => {
                    const insightsReportName = insightsLibrary.cohortInsightsReports.find((c) => c.id === params.row.id)?.insightsReportName
                    return (
                        <Box display='flex' flexDirection='column' gap={1} minWidth={0} maxWidth='100%'>
                            <Typography color='primary' fontWeight='bold' className='grid-content' noWrap>
                                {insightsReportName ? insightsReportName : t`Unknown Report`}
                            </Typography>
                        </Box>
                    )
                },
                flex: 3,
                sortable: false,
                filterable: false
            }
        ]
        if (permissions?.includes(PlatformPermissions.DELETE_RWA_INSIGHTS_CONDITIONS)) {
            columns.push({
                field: 'delete',
                headerName: t`Delete`,
                renderCell: (params) => (
                    <Box
                        display='flex'
                        flexDirection='column'
                        gap={1}
                        minWidth={0}
                        maxWidth='100%'
                        paddingTop={0}
                        paddingBottom={0}
                        sx={{ border: 'none' }}
                    >
                        <IconButton
                            key='delete-condition'
                            color='primary'
                            aria-label={t`Delete`}
                            sx={{ backgroundColor: '#D6E4F2', color: '#012D72', borderRadius: '2px' }}
                            onClick={() => setDeleteModalReport(params.row)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                ),
                flex: 3,
                sortable: false,
                filterable: false
            })
        }
        return columns
    }, [permissions, insightsLibrary.cohortInsightsReports])

    const breadcrumbs = (
        <Box sx={{ marginBottom: '10px' }}>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                <BreadcrumbLink to={toPath(Routes.INSIGHTS_LIBRARY)} sx={{ fontWeight: '600', fontSize: '16px', color: '#012D72' }}>
                    <Trans>Insights Library</Trans>
                </BreadcrumbLink>
                <Box sx={{ fontWeight: '600', fontSize: '16px', color: '#707171' }}>
                    <Trans>Manage</Trans>
                </Box>
            </Breadcrumbs>
        </Box>
    )

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', padding: 2, position: 'relative' }}>
            {breadcrumbs}
            {state.insightsLibrary.loading && (
                <ContentPadding padX padY>
                    <Box display='flex' justifyContent='center' alignItems='center' flex='1'>
                        <CircularProgress />
                    </Box>
                </ContentPadding>
            )}
            {!state.insightsLibrary.loading && (
                <ContentPadding padX padY>
                    <RegisterInsightsLibraryReportModal
                        showRegisterInsightsLibraryReportModal={registerInsightsLibraryReportModalVisible}
                        closeModal={handleRegisterInsightsLibraryReportModalClose}
                    />
                    <Box display='flex' justifyContent='space-between' alignItems='center' flex='1'>
                        <h1>
                            <Trans>Insights Library Management</Trans>
                        </h1>
                        {permissions?.includes(PlatformPermissions.CREATE_RWA_INSIGHTS_CONDITIONS) && (
                            <FrameworkButton
                                variant='contained'
                                color='primary'
                                onClick={handleRegisterInsightsLibraryReportModalOpen}
                                sx={{ width: '400px' }}
                                aria-label={t`Register New Insights Library Report`}
                            >
                                <AddIcon sx={{ height: '18.5px', width: '18.5px', margin: '0.1rem', marginRight: '10px' }} />
                                <Trans>Register New Insights Library Report</Trans>
                            </FrameworkButton>
                        )}
                    </Box>
                    {state.insightsLibrary.cohortInsightsReports.length === 0 ? (
                        <Trans>No Insight Library Reports</Trans>
                    ) : (
                        <Box>
                            <StyledDataGrid
                                autoHeight
                                disableColumnMenu
                                disableRowSelectionOnClick
                                rowHeight={80}
                                getRowSpacing={(params: GridRowSpacingParams) => {
                                    return {
                                        top: params.isFirstVisible ? 0 : 4,
                                        bottom: params.isLastVisible ? 0 : 4
                                    }
                                }}
                                columns={columns}
                                hideFooter={true}
                                rows={state.insightsLibrary.cohortInsightsReports}
                            />
                        </Box>
                    )}
                    {deleteModalReport && (
                        <DeleteInsightsLibraryReportDialog id={deleteModalReport.id} actions={actions} onCancel={handleDeleteHide} />
                    )}
                </ContentPadding>
            )}
        </Box>
    )
}

export type ManageInsightsLibraryPage = ReturnType<typeof createManageInsightsLibraryPage>
