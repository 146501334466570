/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { MessageInput, MessageList } from '@chatscope/chat-ui-kit-react'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import CloseIcon from '@mui/icons-material/Close'
import MinimizeIcon from '@mui/icons-material/Minimize'
import { AssistantConversationDetailDTO } from '@om1/falcon-api'
import { FrameworkComponentProps } from '@om1/platform-utils'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Resizable } from 'react-resizable'
import 'react-resizable/css/styles.css'
import { explorerAssistantConversationActions } from '../state'
import { renderMessage } from './MessageComponents'

export type ActiveConversationWidgetComponentProps = FrameworkComponentProps<
    { activeConversation: AssistantConversationDetailDTO | undefined; assistantIsResponding: boolean },
    typeof explorerAssistantConversationActions,
    { activeCohortId: string; activeCohortName?: string }
>

export const ActiveConversationWidgetComponent: React.FunctionComponent<ActiveConversationWidgetComponentProps> = ({ state, actions, props }) => {
    const activeConversation = state.activeConversation
    const hasActiveConversation = !!activeConversation
    const [isOpen, setIsOpen] = useState(false)
    const [dimensions, setDimensions] = useState({ width: window.innerWidth * 0.25, height: 500 })
    const messageInputRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (props.activeCohortId !== state.activeConversation?.cohortId) {
            actions.clearActiveConversation()
        }
    }, [actions, props.activeCohortId, state.activeConversation?.cohortId])

    useEffect(() => {
        if (!state.assistantIsResponding && messageInputRef.current) {
            messageInputRef.current.focus()
        }
    }, [state.assistantIsResponding])

    const toggleOpen = () => {
        if (!isOpen && !hasActiveConversation) {
            actions.createConversation({
                requestBody: {
                    cohortId: props.activeCohortId
                }
            })
        }
        setIsOpen(!isOpen)
    }
    const minimize = () => {
        setIsOpen(false)
    }
    const close = () => {
        setIsOpen(false)
        actions.clearActiveConversation()
    }
    const messages = useMemo(() => activeConversation?.messages || [], [activeConversation])

    const handleSend = (message: string) => {
        if (!activeConversation?.id) return

        // Remove all HTML tags from the message
        const cleanedMessage = message.replace(/<[^>]*>/g, '')

        actions.invokeConversation({
            id: activeConversation?.id,
            requestBody: { content: cleanedMessage }
        })
    }

    return (
        <div
            style={{
                position: 'fixed',
                bottom: '16px',
                right: '16px',
                zIndex: 50
            }}
        >
            {!isOpen && (
                <button
                    onClick={() => toggleOpen()}
                    style={{
                        backgroundColor: '#012D72',
                        color: 'white',
                        padding: '16px',
                        borderRadius: '9999px',
                        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        border: 'none',
                        cursor: 'pointer',
                        transition: 'background-color 0.2s'
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#001f4f')}
                    onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#012D72')}
                >
                    <AutoAwesomeIcon sx={{ fontSize: 24 }} />
                    <span>
                        {hasActiveConversation
                            ? `Continue${' ' + activeConversation && activeConversation?.title ? ' ' + activeConversation?.title : ''} Chat`
                            : 'Start Chat'}
                    </span>
                </button>
            )}

            {isOpen && (
                <Resizable
                    width={dimensions.width}
                    height={dimensions.height}
                    onResize={(e, { size }) => {
                        setDimensions({
                            width: size.width,
                            height: size.height
                        })
                    }}
                    minConstraints={[300, 400]}
                    maxConstraints={[window.innerWidth * 0.8, window.innerHeight * 0.8]}
                    handle={
                        <div
                            className='react-resizable-handle react-resizable-handle-nw'
                            style={{
                                left: 0,
                                top: 0,
                                cursor: 'nw-resize',
                                width: '20px',
                                height: '20px',
                                position: 'absolute',
                                background: 'linear-gradient(to top left, #8AB8E8 10%, #012D72 60%)',
                                borderRadius: '0 0 4px 0',
                                boxShadow: '2px 2px 4px rgba(0,0,0,0.1)'
                            }}
                        />
                    }
                    handleSize={[20, 20]}
                    resizeHandles={['nw']}
                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
                            width: `${dimensions.width}px`,
                            height: `${dimensions.height}px`,
                            display: 'flex',
                            flexDirection: 'column',
                            zIndex: '101'
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: '#012D72',
                                color: 'white',
                                padding: '16px',
                                borderTopLeftRadius: '8px',
                                borderTopRightRadius: '8px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <h3
                                style={{
                                    margin: 0,
                                    fontWeight: 600
                                }}
                            >
                                {activeConversation?.title ?? 'AI Chat'}
                            </h3>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '8px'
                                }}
                            >
                                <button
                                    onClick={() => minimize()}
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        color: 'white',
                                        cursor: 'pointer',
                                        padding: '4px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                    onMouseOver={(e) => (e.currentTarget.style.color = '#C7D2FE')}
                                    onMouseOut={(e) => (e.currentTarget.style.color = 'white')}
                                >
                                    <MinimizeIcon sx={{ fontSize: 20 }} />
                                </button>
                                <button
                                    onClick={() => close()}
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        color: 'white',
                                        cursor: 'pointer',
                                        padding: '4px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                    onMouseOver={(e) => (e.currentTarget.style.color = '#C7D2FE')}
                                    onMouseOut={(e) => (e.currentTarget.style.color = 'white')}
                                >
                                    <CloseIcon sx={{ fontSize: 20 }} />
                                </button>
                            </div>
                        </div>

                        <div
                            style={{
                                flex: 1,
                                overflow: 'hidden'
                            }}
                        >
                            <MessageList>
                                {messages.map((message: Record<string, any>, index: number) => {
                                    const messageNode = renderMessage(message, index) as React.ReactElement
                                    return <React.Fragment key={message.id || index}>{messageNode}</React.Fragment>
                                })}
                                {state.assistantIsResponding && (
                                    <div
                                        style={{
                                            padding: '12px',
                                            color: '#666',
                                            fontStyle: 'italic',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '8px'
                                        }}
                                    >
                                        Assistant is typing...
                                    </div>
                                )}
                            </MessageList>
                        </div>

                        <div
                            style={{
                                padding: '16px',
                                borderTop: '1px solid #E5E7EB'
                            }}
                        >
                            <MessageInput
                                ref={messageInputRef}
                                placeholder='Type message here'
                                onSend={handleSend}
                                attachButton={false}
                                disabled={state.assistantIsResponding}
                                autoFocus={!state.assistantIsResponding}
                            />
                        </div>
                    </div>
                </Resizable>
            )}
        </div>
    )
}
