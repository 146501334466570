import { CohortState } from '@om1/cohort-module'
import { cohortListActions } from '@om1/cohort-module/src/state'
import { withRouter } from '@om1/platform-routing'
import { PlatformSettingsState } from '@om1/platform-settings-module'
import { rwaConditionsActions } from '@om1/platform-settings-module/src/state/rwa-conditions'
import { connectRedux } from '@om1/platform-utils'
import { insightsLibraryActions, InsightsLibraryState } from '../state'
import { RegisterInsightsLibraryReportModalComponent } from './RegisterInsightsLibraryReportModalComponent'

export function createRegisterInsightsLibraryReportModal<
    TState extends { platformSettings: PlatformSettingsState; cohort: CohortState; insightsLibrary: InsightsLibraryState }
>() {
    return connectRedux(
        withRouter(RegisterInsightsLibraryReportModalComponent, { namespace: 'insights-library', dataFetchAction: 'getRwaConditions' }),
        (state: TState) => {
            return { ...state.platformSettings.rwaConditions, ...state.cohort, ...state.insightsLibrary }
        },
        { ...rwaConditionsActions, ...cohortListActions, ...insightsLibraryActions }
    )
}

export type RegisterInsightsLibraryReportModal = ReturnType<typeof createRegisterInsightsLibraryReportModal>
