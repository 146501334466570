import { Trans, t } from '@lingui/macro'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, styled } from '@mui/material'
import { Box } from '@mui/system'
import PhenotypeIcon from '@om1/cohort-module/src/assets/phenotype-icon.png'
import { BasicCard, BasicCardHeader, BasicCardStat } from '@om1/platform-components/BasicCard'
import { LightTooltip } from '@om1/platform-components/Tooltip'
import { Routes, toPath } from '@om1/platform-routing'
import { TrackingActionTypes } from '@om1/platform-tracking'
import { ActionWithPayload, PlatformPermissions } from '@om1/platform-utils'
import { get } from 'lodash'
import React, { ReactNode, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { hasPhenotypeBlock } from '../../components/edit/utils/filter-import'
import { CohortSizeWithLoader } from '../../components/shared/CohortSizeWithLoader'
import { DatasetSize } from '../../components/shared/DatasetSize'
import { CohortRoutes } from '../../routes'
import { CohortListItem, ExceptFilterOperator, ReportAnalyticsType } from '../../state'
import { DashboardCohortOwner } from './CohortDashboardPageComponent'

interface PngIconProps {
    height: string
    selected: boolean
}

const PngIcon = styled('img')<PngIconProps>`
    height: ${(props) => props.height};
    filter: ${(props) => (props.selected ? 'none' : 'invert(1)')};
    border-radius: ${(props) => props.height};
`

export interface DashboardCohortCardProps {
    cohort: CohortListItem
    isSelected: boolean
    sizeLoading: boolean
    onDeleteView: (id: string) => void
    onDuplicate: (id: string) => void
    reportType: ReportAnalyticsType
    onRefreshSize: (id: string, name: string) => void
    permissions: string[] | undefined
    setCohortId: React.Dispatch<React.SetStateAction<string | undefined>>
    trackPage: (route: string) => ActionWithPayload<
        TrackingActionTypes.TRACK_PAGE,
        {
            location: {
                pathname: string
                search: string
                hash: string
                key: string
            }
            action: string
            isFirstRendering: boolean
        }
    >
}

export const DashboardCohortCard = ({
    cohort,
    isSelected,
    sizeLoading,
    onDeleteView,
    onDuplicate,
    onRefreshSize,
    reportType,
    permissions,
    setCohortId,
    trackPage
}: DashboardCohortCardProps) => {
    const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null)
    const menuOpen = Boolean(menuAnchor)
    const location = useLocation()

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.preventDefault()
        setMenuAnchor(event.currentTarget)
    }
    const handleMenuClose = (event) => setMenuAnchor(null)

    const menu = useMemo(() => {
        let actions: ReactNode[] = []
        if (!cohort.isSystem && permissions?.includes(PlatformPermissions.UPDATE_COHORT)) {
            actions.push(
                <MenuItem
                    data-testid={'edit-cohort'}
                    key='edit'
                    color='primary'
                    component={Link}
                    to={toPath(Routes.COHORTBUILD) + `/${DashboardCohortOwner.CUSTOM}/${cohort.id}/edit`}
                    aria-label={t`Edit`}
                >
                    <ListItemIcon>
                        <EditOutlinedIcon fontSize='small' color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                        <Trans>Edit Cohort</Trans>
                    </ListItemText>
                </MenuItem>
            )
        }
        actions.push(
            <MenuItem
                key='refreshCount'
                color='primary'
                aria-label={t`Refresh Count`}
                disabled={sizeLoading}
                onClick={() => onRefreshSize(cohort.id, cohort.name)}
            >
                <ListItemIcon>
                    <RefreshOutlinedIcon fontSize='small' color='primary' />
                </ListItemIcon>
                <ListItemText>
                    <Trans>Refresh Count</Trans>
                </ListItemText>
            </MenuItem>
        )
        if (permissions?.includes(PlatformPermissions.CREATE_COHORT)) {
            actions.push(
                <MenuItem key='duplicate' color='primary' aria-label={t`Duplicate`} onClick={() => onDuplicate(cohort.id)}>
                    <ListItemIcon>
                        <ContentCopyIcon fontSize='small' color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                        <Trans>Duplicate</Trans>
                    </ListItemText>
                </MenuItem>
            )
        }
        if (permissions?.includes(PlatformPermissions.DELETE_COHORT)) {
            actions.push(
                <MenuItem key='delete' color='primary' aria-label={t`Delete`} onClick={() => onDeleteView(cohort.id)}>
                    <ListItemIcon>
                        <DeleteOutlinedIcon fontSize='small' color='primary' />
                    </ListItemIcon>
                    <ListItemText>
                        <Trans>Delete</Trans>
                    </ListItemText>
                </MenuItem>
            )
        }

        if (actions.length && !cohort.isSystem) {
            return (
                <>
                    <IconButton
                        id={`dashboard-actions-toggle-${cohort.id}`}
                        onClick={handleMenuOpen}
                        aria-label={t`Cohort Actions`}
                        aria-controls={menuOpen ? `dashboard-actions-${cohort.id}` : undefined}
                        aria-haspopup='true'
                        aria-expanded={menuOpen ? 'true' : undefined}
                        style={isSelected ? { color: 'white' } : { color: '#002D72' }}
                        sx={{ position: 'absolute', bottom: 0, right: 0, padding: 1 }}
                    >
                        <MoreHorizIcon />
                    </IconButton>
                    <Menu
                        id={`dashboard-actions-${cohort.id}`}
                        open={menuOpen}
                        anchorEl={menuAnchor}
                        onClose={handleMenuClose}
                        onClick={handleMenuClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        MenuListProps={{ 'aria-labelledby': `dashboard-actions-toggle-${cohort.id}` }}
                    >
                        {actions}
                    </Menu>
                </>
            )
        } else {
            return null
        }
    }, [cohort, isSelected, sizeLoading, onDeleteView, onDuplicate, onRefreshSize, menuAnchor, menuOpen, permissions])

    const labelProps = useMemo(() => ({ color: isSelected ? 'secondary.main' : undefined }), [isSelected])

    return (
        <BasicCard isSelected={isSelected}>
            <BasicCardHeader label={<CohortListItemToTitle cohort={cohort} isSelected={isSelected} />} />
            <Box display='flex' flexDirection='column' gap={1}>
                {!cohort.isSystem && (
                    <BasicCardStat
                        id='cohort-card-dataset'
                        label={<Trans>Dataset</Trans>}
                        labelProps={labelProps}
                        value={<DatasetSize name={cohort.analyticsDataset.name} size={cohort.analyticsDataset.size} />}
                    />
                )}
                <BasicCardStat
                    id='cohort-card-size'
                    label={<Trans>Cohort Size</Trans>}
                    labelProps={labelProps}
                    value={
                        <CohortSizeWithLoader
                            cohortSize={cohort.cohortSize}
                            datasetSize={cohort.analyticsDataset.size}
                            isStale={cohort.isStale}
                            sizeLoading={sizeLoading}
                            isSystem={cohort.isSystem}
                        />
                    }
                />
                {!cohort.isSystem && (
                    <BasicCardStat id='cohort-card-owner' label={<Trans>Owner</Trans>} value={cohort.createdByEmail} labelProps={labelProps} />
                )}
            </Box>
            <Box
                style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
                onClick={() => {
                    setCohortId(cohort.id)
                    const route = `/${CohortRoutes.COHORTBUILD}/${CohortRoutes.DASHBOARD}/${
                        cohort.isSystem ? DashboardCohortOwner.DATASET : DashboardCohortOwner.CUSTOM
                    }/${cohort.id}/${reportType}${location.search}`
                    trackPage(route)
                    window.history.pushState({}, '', route)
                }}
                sx={{ cursor: 'pointer' }}
            />
            {menu}
        </BasicCard>
    )
}

export const CohortListItemToTitle: React.FunctionComponent<{ cohort: CohortListItem; isSelected?: boolean }> = ({
    cohort,
    isSelected
}: {
    cohort: CohortListItem
    isSelected?: boolean
}) => {
    const hasPhenotype = useMemo(() => {
        const queryFilters: boolean[] = get(cohort, ['query', 'filters'], []).map(
            (filter: ExceptFilterOperator) => hasPhenotypeBlock(filter) ?? false
        )

        return queryFilters.some((f) => f)
    }, [cohort])

    const cardHeaderLabel = useMemo(() => {
        if (!hasPhenotype) {
            return <>{cohort.name}</>
        }

        return (
            <Box display='flex' flexDirection='row' flexWrap='nowrap' justifyContent='space-between' alignItems='center'>
                <span>{cohort.name}</span>
                <LightTooltip key={'editTooltip'} title={<Trans>Powered by PhenOM</Trans>} placement='top' sx={{ zIndex: 10000 }}>
                    <PngIcon src={PhenotypeIcon} height='28px' selected={isSelected ? isSelected : false} sx={{ marginLeft: '18px' }} />
                </LightTooltip>
            </Box>
        )
    }, [cohort.name, hasPhenotype, isSelected])

    return cardHeaderLabel
}
