import { AssistantConversationDetailDTO } from '@om1/falcon-api/codegen/client/models/AssistantConversationDetailDTO'
import { AssistantConversationDTO } from '@om1/falcon-api/codegen/client/models/AssistantConversationDTO'
import { PaginateMeta } from '@om1/falcon-api/codegen/client/models/PaginateMeta'

export interface ExplorerAssistantConversationState {
    activeConversation: AssistantConversationDetailDTO | undefined
    conversations: AssistantConversationDTO[]
    isLoading: boolean
    error: string | undefined
    paginateMeta: PaginateMeta | undefined
    assistantIsResponding: boolean
}

export const initialExplorerAssistantConversationState: ExplorerAssistantConversationState = {
    activeConversation: undefined,
    conversations: [],
    isLoading: false,
    error: undefined,
    paginateMeta: undefined,
    assistantIsResponding: false
}
