import { t } from '@lingui/macro'
import { AnalyticsRefDimension, CriteriaType, ObservationRef } from '../../../../state'
import { DepthRefFieldMapper, DepthRefGetterCallback, RefFieldMapperType } from './interfaces'

const getItemLabel: DepthRefGetterCallback<ObservationRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.observation
        case 1:
            return item.resultType
    }
}

const getItemValue: DepthRefGetterCallback<ObservationRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return item.observation
        case 1:
            return item.observation
    }
}

const getItemTooltip: DepthRefGetterCallback<ObservationRef> = (item, depth) => {
    if (!item) {
        return undefined
    }
    switch (depth) {
        case 0:
            return getItemLabel(item, depth)
    }
}

export const ObservationRefFieldMapper: DepthRefFieldMapper<ObservationRef> = {
    type: RefFieldMapperType.Depth,
    dimension: AnalyticsRefDimension.OBSERVATION,
    criteriaType: CriteriaType.Observation,
    table: 'patient_observation',
    dateField: {
        first: 'first_observation_date',
        last: 'last_observation_date',
        any: 'any_observation_date'
    },
    dateFieldInverse: {
        first_observation_date: 'first',
        last_observation_date: 'last',
        any_observation_date: 'any'
    },
    searchPlaceholder: (() => t`Search Observations`)(),
    noResultsMessage: (() => t`No results`)(),
    searchHintMessage: (() => t`Search to browse observations`)(),
    filterFieldToRefField: { observation: 'observation', result_type: 'resultType' },
    filterFieldOrder: ['observation', 'result_type'],
    filterLabelOrder: ['observation', 'observation'],
    disableRequireSearch: true,
    ignoreChildren: true,
    getItemLabel,
    getItemValue,
    getItemTooltip
}
