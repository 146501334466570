/* eslint-disable string-to-lingui/missing-lingui-transformation */
import styled from '@emotion/styled'
import { t, Trans } from '@lingui/macro'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import {
    Box,
    Breadcrumbs,
    Button,
    CircularProgress,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material'
import { cohortListActions } from '@om1/cohort-module/src/state'
import { CohortConditionDTO, CohortInsightsReportDTO, PowerBIEmbedTokenResponse, PowerBiService } from '@om1/falcon-api'
import { LightTooltip } from '@om1/platform-components/Tooltip'
import { Routes, toPath } from '@om1/platform-routing'
import { BreadcrumbLink } from '@om1/platform-ui-kit/src/components/Layout'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { PowerBIEmbed } from 'powerbi-client-react'
import { BackgroundType, FilterType, TokenType } from 'powerbi-models'
import { FunctionComponent, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { insightsLibraryActions, InsightsLibraryState } from '../state'

const PowerBIPageContainer = styled(Box)({
    padding: '16px 36px',
    height: '92%',
    iframe: {
        border: 'none'
    }
})

export type PowerBIPageComponentProps = RoutedFrameworkComponentProps<
    {},
    {},
    {
        insightsLibrary: InsightsLibraryState
        permissions?: string[] | undefined
        conditions: CohortConditionDTO[]
    },
    typeof insightsLibraryActions & typeof cohortListActions,
    {}
>

export const PowerBIPageComponent: FunctionComponent<PowerBIPageComponentProps> = ({ state, actions }) => {
    const [embedToken, setEmbedToken] = useState<string>()
    const [embedUrl, setEmbedUrl] = useState<string>()
    const [error, setError] = useState<string>()
    const [reportId, setReportId] = useState<string>('')
    const [currentReportCondition, setCurrentReportCondition] = useState<CohortConditionDTO>()
    const [loading, setLoading] = useState<boolean>(false)
    const [view, setView] = useState<'tiles' | 'report'>('tiles')
    const [filterText] = useState<string>('')

    const [currentCohortName, setCurrentCohortName] = useState<string>('')
    const [currentReportName, setCurrentReportName] = useState<string>('')

    const groupedReports = useMemo(() => {
        const groups: { [key: string]: CohortInsightsReportDTO[] } = {}
        state.insightsLibrary.cohortInsightsReports.forEach((report: CohortInsightsReportDTO) => {
            const cohort = state.insightsLibrary.cohortInsightsReports.find((c: CohortInsightsReportDTO) => c.cohortName === report.cohortName)
            const groupName = cohort?.cohortName || 'Unknown Cohort'
            if (!groups[groupName]) {
                groups[groupName] = []
            }
            groups[groupName].push(report)
        })
        return groups
    }, [state.insightsLibrary.cohortInsightsReports])

    const filteredGroups = useMemo(() => {
        const filtered: { [key: string]: CohortInsightsReportDTO[] } = {}
        Object.entries(groupedReports).forEach(([groupName, reports]) => {
            const filteredReports = reports.filter((report) => {
                const insightsReport = state.insightsLibrary.cohortInsightsReports.find((r) => r.id === report.id)
                return insightsReport?.insightsReportName?.toLowerCase().includes(filterText.toLowerCase())
            })
            if (filteredReports.length > 0) {
                filtered[groupName] = filteredReports
            }
        })
        return filtered
    }, [groupedReports, state.insightsLibrary.cohortInsightsReports, filterText])

    const fetchEmbedToken = async (id: string) => {
        try {
            console.log('fetching embed token for report:', id)
            setLoading(true)
            setError('')
            try {
                const res: PowerBIEmbedTokenResponse = await PowerBiService.getPowerBiReportPowerBiEmbedCohortInsightsReportIdGet({
                    cohortInsightsReportId: id
                })
                setEmbedToken(res.token)
                setEmbedUrl(res.embedUrl)
                setReportId(res.report_id)

                // Find the current report and set the cohort and report names
                const cohortInsightsReport = state.insightsLibrary.cohortInsightsReports.find((r) => r.id === id)
                if (cohortInsightsReport) {
                    let condition = state.conditions.find(
                        (c) => c.cohortName === cohortInsightsReport.cohortName && c.insightsReportName === cohortInsightsReport.insightsReportName
                    )
                    console.log('condition', condition)
                    setCurrentReportCondition(condition)
                    setCurrentCohortName(cohortInsightsReport.cohortName || 'Unknown Cohort')
                    setCurrentReportName(cohortInsightsReport.insightsReportName || 'Unknown Report')
                }

                setView('report')
            } catch (e) {
                console.error('Error fetching PowerBI report:', e)
                setError(JSON.stringify(e?.['body']?.['detail']))
            } finally {
                setLoading(false)
            }
        } catch (error) {
            console.error('Error fetching PowerBI report:', error)
            setError(JSON.stringify(error?.['body']?.['detail']))
            setLoading(false)
        }
    }

    const handleBackClick = () => {
        setView('tiles')
        setEmbedToken(undefined)
        setEmbedUrl(undefined)
        setReportId('')
        setCurrentReportCondition(undefined)
    }

    const breadcrumbs = (
        <Box sx={{ marginBottom: '10px' }}>
            <Breadcrumbs separator='›' aria-label='breadcrumb'>
                {view === 'report' && (
                    <BreadcrumbLink
                        onClick={handleBackClick}
                        to={toPath(Routes.INSIGHTS_LIBRARY)}
                        sx={{ fontWeight: '600', fontSize: '16px', color: '#012D72' }}
                    >
                        <Trans>Insights Library</Trans>
                    </BreadcrumbLink>
                )}
                {view !== 'report' && <Box sx={{ fontWeight: '600', fontSize: '16px', color: '#707171' }}>Insights Library</Box>}
                {view === 'report' && <Box sx={{ fontWeight: '600', fontSize: '16px', color: '#707171' }}>{currentCohortName}</Box>}
                {view === 'report' && <Box sx={{ fontWeight: '600', fontSize: '16px', color: '#707171' }}>{currentReportName}</Box>}
            </Breadcrumbs>
        </Box>
    )

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', padding: 2, position: 'relative' }}>
            {breadcrumbs}
            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress />
                </Box>
            )}
            {!loading && view === 'tiles' && (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                        <Typography variant='h4' color='primary'>
                            OM1 Insights Library
                        </Typography>
                        <LightTooltip title={t`Manage Insights Library`} placement='top'>
                            <IconButton
                                key='distribution'
                                color='primary'
                                component={Link}
                                to={toPath(Routes.INSIGHTS_LIBRARY + '/manage')}
                                sx={{
                                    backgroundColor: '#D6E4F2',
                                    color: '#012D72',
                                    borderRadius: '2px'
                                }}
                            >
                                <SettingsRoundedIcon />
                            </IconButton>
                        </LightTooltip>
                    </Box>
                    {error && <Box sx={{ color: 'error.main', mb: 2 }}>{error}</Box>}
                    <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                        {Object.entries(filteredGroups).map(([cohortName, reports]) => (
                            <Box key={cohortName} sx={{ mb: 4 }}>
                                <Typography variant='h6' gutterBottom>
                                    {cohortName}
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table aria-label={`insights library table for ${cohortName}`}>
                                        <TableBody>
                                            {reports.map((report: CohortInsightsReportDTO, index) => {
                                                const cohortInsightsReport = state.insightsLibrary.cohortInsightsReports.find(
                                                    (r) => r.id === report.id
                                                )
                                                return (
                                                    <TableRow
                                                        key={`${cohortName}-Row${index}`}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component='th' scope='row' sx={{ width: '70%' }}>
                                                            {cohortInsightsReport ? cohortInsightsReport.insightsReportName : 'Unknown Report'}
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            <Button variant='text' onClick={() => fetchEmbedToken(report.id)}>
                                                                View Report
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ))}
                    </Box>
                </>
            )}
            {!loading && view !== 'tiles' && (
                <PowerBIPageContainer>
                    <PowerBIEmbed
                        embedConfig={{
                            type: 'report',
                            id: reportId,
                            accessToken: embedToken,
                            embedUrl: embedUrl,
                            tokenType: TokenType.Embed,
                            filters: [
                                {
                                    $schema: 'http://powerbi.com/product/schema#basic',
                                    target: {
                                        table: currentReportCondition ? currentReportCondition.powerbiFilterTable : '',
                                        column: currentReportCondition ? currentReportCondition.powerbiFilterColumn : ''
                                    },
                                    operator: 'In',
                                    values: [currentReportCondition?.powerbiFilterValue || ''],
                                    filterType: FilterType.Basic
                                }
                            ],
                            settings: {
                                panes: {
                                    filters: {
                                        visible: true
                                    },
                                    pageNavigation: {
                                        visible: true,
                                        position: 1
                                    }
                                },
                                background: BackgroundType.Transparent
                            }
                        }}
                        cssClassName={'reportClass'}
                    />
                </PowerBIPageContainer>
            )}
        </Box>
    )
}
