import { t } from '@lingui/macro'
import { AnalyticsRefDimension, CriteriaType, DiagnosisIcd10Ref } from '../../../../state'
import { NestedRefFieldMapper, NestedRefGetterCallback, RefFieldMapperType } from './interfaces'

const getItemId: NestedRefGetterCallback<DiagnosisIcd10Ref, string> = (item) => item.diagnosisConceptId
const getItemParentId: NestedRefGetterCallback<DiagnosisIcd10Ref> = (item) => item.parentDiagnosisConceptId
const getItemPathToRoot: NestedRefGetterCallback<DiagnosisIcd10Ref, string[]> = (item) => (item.pathToRoot ? item.pathToRoot.split('|') : [])
const getItemLabel: NestedRefGetterCallback<DiagnosisIcd10Ref> = (item) => item.diagnosis
const getItemValue: NestedRefGetterCallback<DiagnosisIcd10Ref> = (item) => item.diagnosisConceptId
const getItemTooltip: NestedRefGetterCallback<DiagnosisIcd10Ref> = (item) => `${item.diagnosisConceptId}: ${item.diagnosis}`
const getItemChildCount: NestedRefGetterCallback<DiagnosisIcd10Ref> = (item) => item.childCount

export const DiagnosisIcd10RefFieldMapper: NestedRefFieldMapper<DiagnosisIcd10Ref> = {
    type: RefFieldMapperType.Nested,
    dimension: AnalyticsRefDimension.DIAGNOSIS_ICD10,
    criteriaType: CriteriaType.Diagnosis,
    table: 'patient_diagnosis',
    dateField: {
        first: 'first_record_date',
        last: 'last_record_date',
        any: 'any_record_date'
    },
    dateFieldInverse: {
        first_record_date: 'first',
        last_record_date: 'last',
        any_record_date: 'any'
    },
    searchPlaceholder: (() => t`Search Diagnoses`)(),
    noResultsMessage: (() => t`No results`)(),
    searchHintMessage: (() => t`Search to browse diagnoses`)(),
    filterFieldToRefField: {
        associated_diagnosis_concept_id_icd10: 'diagnosisConceptId',
        anchor_diagnosis_concept_id_icd10: 'diagnosisConceptId',
        diagnosis: 'diagnosis',
        anchor_diagnosis_icd10: 'anchorDiagnosis',
        child_count: 'childCount'
    },
    filterFieldOrder: ['anchor_diagnosis_concept_id_icd10'],
    filterLabelOrder: ['anchor_diagnosis_icd10'],
    getItemId,
    getItemParentId,
    getItemPathToRoot,
    getItemLabel,
    getItemValue,
    getItemTooltip,
    getItemChildCount
}
