import { AssistantConversationDTO, ExplorerAssistantConversationService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { explorerAssistantConversationActions } from '../state/explorer-assistant-conversation-actions'

export function createCreateExplorerAssistantConversationSaga() {
    return function* (action: ReturnType<typeof explorerAssistantConversationActions.createConversation>) {
        try {
            const assistantConversationDTO: AssistantConversationDTO = yield call(
                ExplorerAssistantConversationService.createExplorerAssistantConversationPost,
                { requestBody: action.payload.requestBody }
            )
            yield put(explorerAssistantConversationActions.createConversationSuccess(assistantConversationDTO))
            yield put(explorerAssistantConversationActions.setActiveConversation({ ...assistantConversationDTO, messages: [] }))
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(explorerAssistantConversationActions.setConversationsLoading(false))
        }
    }
}

export type CreateExplorerAssistantConversationSaga = ReturnType<typeof createCreateExplorerAssistantConversationSaga>
