import { connectRedux } from '@om1/platform-utils'
import { ExplorerAssistantConversationState } from '../state'
import { explorerAssistantConversationActions } from '../state/explorer-assistant-conversation-actions'
import { ActiveConversationWidgetComponent } from './ActiveConversationWidgetComponent'

export function createActiveConversationWidgetComponent<TState extends { explorerAssistantConversation: ExplorerAssistantConversationState }>() {
    return connectRedux(
        ActiveConversationWidgetComponent,
        (state: TState) => ({
            activeConversation: state.explorerAssistantConversation.activeConversation,
            assistantIsResponding: state.explorerAssistantConversation.assistantIsResponding
        }),
        { ...explorerAssistantConversationActions }
    )
}

export type ActiveConversationWidget = ReturnType<typeof createActiveConversationWidgetComponent>
