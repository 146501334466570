import { falconApiConfig } from '@om1/falcon-api'
import { put } from 'redux-saga/effects'
import { explorerAssistantConversationActions } from '../state/explorer-assistant-conversation-actions'
import { webSocketActions } from './websocket-sagas'

export function createSetActiveConversationSaga() {
    return function* (action: ReturnType<typeof explorerAssistantConversationActions.setActiveConversation>) {
        const FALCON_WSS_URL = falconApiConfig.falconApiUrl?.replace(new RegExp(/^http/), 'ws')
        if (action.payload.id) {
            const url = `${FALCON_WSS_URL}/explorer/assistant-conversation/${action.payload.id}`
            yield put(webSocketActions.setUrl(url))
        }
    }
}

export type SetActiveConversationSaga = ReturnType<typeof createSetActiveConversationSaga>
