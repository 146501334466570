import { all, debounce, fork } from 'redux-saga/effects'
import { ExplorerAssistantConversationActionTypes } from '../state/explorer-assistant-conversation-actions'
import { createCreateExplorerAssistantConversationSaga } from './create-explorer-assistant-conversations-saga'
import { createDeleteExplorerAssistantConversationSaga } from './delete-explorer-assistant-conversation-saga'
import { createGetExplorerAssistantConversationSaga } from './get-explorer-assistant-conversation-saga'
import { createGetExplorerAssistantConversationsSaga } from './get-explorer-assistant-conversations-saga'
import { createInvokeExplorerAssistantConversationSaga } from './invoke-explorer-assistant-conversations-saga'
import { createSetActiveConversationSaga } from './set-active-conversation-saga'
import { createUpdateExplorerAssistantConversationSaga } from './update-explorer-assistant-conversation-saga'
import { createWebSocketMessageReceivedSaga } from './websocket-message-recieved-saga'
import { createWebSocketSaga, WebSocketActionTypes } from './websocket-sagas'

export function createExplorerAssistantConversationsSaga() {
    const getExplorerAssistantConversationsSaga = createGetExplorerAssistantConversationsSaga()
    const createExplorerAssistantConversationSaga = createCreateExplorerAssistantConversationSaga()
    const getExplorerAssistantConversationSaga = createGetExplorerAssistantConversationSaga()
    const invokeExplorerAssistantConversationSaga = createInvokeExplorerAssistantConversationSaga()
    const updateExplorerAssistantConversationSaga = createUpdateExplorerAssistantConversationSaga()
    const deleteExplorerAssistantConversationSaga = createDeleteExplorerAssistantConversationSaga()
    const setActiveConversationSaga = createSetActiveConversationSaga()
    const webSocketMessageReceivedSaga = createWebSocketMessageReceivedSaga()

    return function* () {
        yield all([fork(createWebSocketSaga)])
        yield debounce(0, ExplorerAssistantConversationActionTypes.GET_CONVERSATIONS, getExplorerAssistantConversationsSaga)
        yield debounce(0, ExplorerAssistantConversationActionTypes.CREATE_CONVERSATION, createExplorerAssistantConversationSaga)
        yield debounce(0, ExplorerAssistantConversationActionTypes.GET_CONVERSATION, getExplorerAssistantConversationSaga)
        yield debounce(0, ExplorerAssistantConversationActionTypes.INVOKE_CONVERSATION, invokeExplorerAssistantConversationSaga)
        yield debounce(0, ExplorerAssistantConversationActionTypes.UPDATE_CONVERSATION, updateExplorerAssistantConversationSaga)
        yield debounce(0, ExplorerAssistantConversationActionTypes.DELETE_CONVERSATION, deleteExplorerAssistantConversationSaga)
        yield debounce(0, ExplorerAssistantConversationActionTypes.SET_ACTIVE_CONVERSATION, setActiveConversationSaga)
        yield debounce(0, WebSocketActionTypes.MESSAGE_RECEIVED, webSocketMessageReceivedSaga)
    }
}
