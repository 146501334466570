import { DepthRefGetterCallback } from '../edit/utils/ref-field-mappers'
import { Checked, TreeItem, TreeSelection } from './CheckTree'

export interface DepthCheckTreeManagerOptions<T> {
    getItemValue: DepthRefGetterCallback<T>
    getItemLabel: DepthRefGetterCallback<T>
    getItemTooltip: DepthRefGetterCallback<T>
    getItemChildCount?: DepthRefGetterCallback<T>
    collapseAtDepth: number
    /** When clicking on an item in the tree, skip the staged status and directly commit the selection */
    skipStaged?: boolean
    ignoreChildren?: boolean
}

/**
 * Manages the state of a tree for use in a CheckTree component. Handles, selection, deselection, addition of new items,
 * and the calculation of status for each node in the tree.
 *
 * The tree has a concept of "staged" and "commited" to support the left / right panes that typically appear with the
 * tree in the UI. A staged check tree item is an item that has been checked, but has not ultimately been added to the
 * committed result set. Staged items in the tree can be unchecked, where as committed items display as disabled and
 * need to be uncommitted to remove their selected status. Confirming the currently checked (staged) items in the tree
 * will move the currently staged items into a committed state.
 *
 * Every item added to the tree is expected to be a leaf node, and each item should contain all of the data needed to
 * display its hierarchy in the tree. For example, `category` and `category_label` may be the highest "depth 0" level
 * properties on an object, `sub_category` and `sub_category_label` may be the "depth 1" level properties, etc.
 */
export class DepthCheckTreeManager<T> {
    tree: TreeItem<T> = {
        id: '',
        order: [],
        children: new Map(),
        expanded: true,
        // Source is always undefined for the root node, just ignore the type error
        // @ts-ignore
        source: undefined
    }
    staged: TreeSelection<T>[] = []
    committed: TreeSelection<T>[] = []

    getItemValue: DepthRefGetterCallback<T>
    getItemLabel: DepthRefGetterCallback<T>
    getItemTooltip: DepthRefGetterCallback<T>
    getItemChildCount?: DepthRefGetterCallback<T>
    collapseAtDepth: number
    skipStaged: boolean
    listener?: () => void
    ignoreChildren: boolean

    constructor(options: DepthCheckTreeManagerOptions<T>) {
        this.getItemValue = options.getItemValue
        this.getItemLabel = options.getItemLabel
        this.getItemTooltip = options.getItemTooltip
        this.getItemChildCount = options.getItemChildCount
        this.collapseAtDepth = options.collapseAtDepth
        this.ignoreChildren = options.ignoreChildren || false
        this.skipStaged = options.skipStaged || false
    }

    /**
     * Registers a change listener with the tree manager. Listeners will be called every time the tree status is
     * updated. For example, when an item is added, or when checkbox is checked.
     */
    registerChangeListener(listener: () => void) {
        this.listener = listener
    }

    /**
     * Removes the previously registered change listener.
     */
    clearChangeListener() {
        this.listener = undefined
    }

    /**
     * Adds a new item to the tree. Determines the proper placement of the item in the tree using the depth callbacks,
     * and auto-generates an id for each item inserted.
     */
    addNode(item: T, searchParams: string) {
        this.addSingleNode(item, searchParams)
        this.notify()
    }

    /**
     * Add multiple items to the tree, and only broadcast a notification update once all items are added.
     */
    addNodes(items: T[], searchParams: string) {
        items.forEach((i) => {
            this.addSingleNode(i, searchParams)
        })
        this.notify()
    }

    /**
     * Calculates the status of a single checkbox in the tree.
     *
     * A checkbox is checked if:
     * - It is staged
     * - One of its parents is staged
     * - It is committed
     * - One of its parents is committed
     *
     * A checkbox is indeterminate if:
     * - One of its children is staged and none of its parents are staged or committed
     * - One of its children is committed and none of its parents are staged or committed
     *
     * A checkbox is disabled if:
     * - It is committed
     * - One of its parents is committed
     */
    displayStatus(item: T, depth: number): Checked {
        let committed = false
        for (let i = 0; !committed && i < this.committed.length; i++) {
            const existingItem = this.committed[i]
            if (depth >= existingItem.depth) {
                let matchesEveryValue = true
                for (let j = 0; matchesEveryValue && j <= existingItem.depth; j++) {
                    if (this.getItemValue(existingItem.source, j) !== this.getItemValue(item, j)) {
                        matchesEveryValue = false
                    }
                }
                if (matchesEveryValue) {
                    committed = true
                }
            }
        }

        if (committed) {
            return Checked.D
        }

        let staged = false
        for (let i = 0; !staged && i < this.staged.length; i++) {
            const existingItem = this.staged[i]
            if (depth >= existingItem.depth) {
                let matchesEveryValue = true
                for (let j = 0; matchesEveryValue && j <= existingItem.depth; j++) {
                    if (this.getItemValue(existingItem.source, j) !== this.getItemValue(item, j)) {
                        matchesEveryValue = false
                    }
                }
                if (matchesEveryValue) {
                    staged = true
                }
            }
        }

        if (staged) {
            return Checked.Y
        }

        let stagedOrCommittedBelow = false
        const stagedOrCommitted = [...this.staged, ...this.committed]
        for (let i = 0; !stagedOrCommittedBelow && i < stagedOrCommitted.length; i++) {
            const existingItem = stagedOrCommitted[i]
            if (depth < existingItem.depth) {
                let matchesEveryValue = true
                for (let j = 0; matchesEveryValue && j <= depth; j++) {
                    if (this.getItemValue(existingItem.source, j) !== this.getItemValue(item, j)) {
                        matchesEveryValue = false
                    }
                }
                if (matchesEveryValue) {
                    stagedOrCommittedBelow = true
                }
            }
        }

        if (stagedOrCommittedBelow) {
            return Checked.I
        }

        return Checked.N
    }

    /**
     * Removes all of the current items in the tree, and all previously selected staged items. Committed items are not
     * removed. Typically utilized when a new tree needs to be displayed due to a search term change.
     */
    clearTree() {
        this.tree = { ...this.tree, order: [], children: new Map() }
        this.staged = []
        this.notify()
    }

    /**
     * Adds a checked item to the list of staged items. If a parent of previously staged items is selected, the child
     * staged items are removed, and the newly selected item is added in their place.
     */
    stage(item: TreeSelection<T>) {
        if (this.skipStaged) {
            this.commit(item)
            return
        }

        const superceded: TreeSelection<T>[] = []
        for (let i = 0; i < this.staged.length; i++) {
            const existingItem = this.staged[i]
            if (item.depth < existingItem.depth) {
                let matchesEveryValue = true
                for (let j = 0; matchesEveryValue && j <= item.depth; j++) {
                    if (this.getItemValue(existingItem.source, j) !== this.getItemValue(item.source, j)) {
                        matchesEveryValue = false
                    }
                }
                if (matchesEveryValue) {
                    superceded.push(existingItem)
                }
            }
        }

        this.staged = [...this.staged.filter((x) => !superceded.includes(x)), item]
        this.notify()
    }

    /**
     * Removes a checked item from the list of staged items. Also handles the use case where a parent with many children
     * is staged and one of its children is unstaged. This unstage action requires a recursive clean up where the single
     * parent is removed from the staged list and is replaced by the minimal subset of nodes that excludes the unstaged
     * item.
     */
    unstage(item: TreeSelection<T>) {
        let parentOfItemToRemove: TreeSelection<T> | undefined = undefined
        let itemToRemove: TreeSelection<T> | undefined = undefined
        for (let i = 0; !parentOfItemToRemove && !itemToRemove && i < this.staged.length; i++) {
            const existingItem = this.staged[i]
            if (item.depth === existingItem.depth) {
                let matchesEveryValue = true
                for (let j = 0; matchesEveryValue && j <= item.depth; j++) {
                    if (this.getItemValue(item.source, j) !== this.getItemValue(existingItem.source, j)) {
                        matchesEveryValue = false
                    }
                }
                if (matchesEveryValue) {
                    itemToRemove = existingItem
                }
            } else if (item.depth > existingItem.depth) {
                let matchesEveryValue = true
                for (let j = 0; matchesEveryValue && j <= existingItem.depth; j++) {
                    if (this.getItemValue(item.source, j) !== this.getItemValue(existingItem.source, j)) {
                        matchesEveryValue = false
                    }
                }
                if (matchesEveryValue) {
                    parentOfItemToRemove = existingItem
                }
            }
        }

        this.staged = this.staged.filter((x) => x !== itemToRemove && x !== parentOfItemToRemove)

        if (parentOfItemToRemove) {
            const itemsToAdd: TreeSelection<T>[] = []
            // Locate the node in the tree that is closest selected parent of the item we want to unstage
            let node: TreeItem<T> = this.tree
            for (let i = 0; i <= parentOfItemToRemove.depth; i++) {
                const value = this.getItemValue(parentOfItemToRemove.source, i)
                if (value && node.children.has(value)) {
                    node = node.children.get(value)!
                }
            }
            // Select everything under that node that isn't in the path of the item we want to unstage
            for (let i = parentOfItemToRemove.depth + 1; i <= item.depth; i++) {
                const removeItemvalue = this.getItemValue(item.source, i)!
                for (let j = 0; j < node.order.length; j++) {
                    const value = node.order[j]
                    if (removeItemvalue !== value) {
                        itemsToAdd.push({ source: node.children.get(value)!.source, depth: i })
                    }
                }
                node = node.children.get(removeItemvalue)!
            }
            this.staged = [...this.staged, ...itemsToAdd]
        }

        this.notify()
    }

    /**
     * Adds a single item directly to the committed list.
     */
    commit(item: TreeSelection<T>) {
        this.commitSingle(item)
        this.notify()
    }

    /**
     * Removes a single item from the committed list. If any children of the uncommitted item also have been committed,
     * those children are removed as well.
     */
    uncommit(item: TreeSelection<T>) {
        this.committed = this.committed.filter((x) => {
            if (item.depth === x.depth) {
                let pathMatches = true
                for (let i = 0; pathMatches && i <= item.depth; i++) {
                    if (this.getItemValue(item.source, i) !== this.getItemValue(x.source, i)) {
                        pathMatches = false
                    }
                }
                return !pathMatches
            }
            return true
        })
        this.notify()
    }

    /**
     * Clears all committed items from the committed array.
     */
    uncommitAll() {
        this.committed = this.committed.filter(() => false)
        this.notify()
    }

    /**
     * Convert all currently staged items to committed items.
     */
    commitStaged() {
        for (let i = 0; i < this.staged.length; i++) {
            this.commitSingle(this.staged[i])
        }
        this.staged = []
        this.notify()
    }

    /**
     * Expand a node in the tree to see its children.
     */
    expand(item: TreeSelection<T>) {
        this.toggleExpand(item, true)
        this.notify()
    }

    /**
     * Collapse a node in the tree to hide its children.
     */
    collapse(item: TreeSelection<T>) {
        this.toggleExpand(item, false)
        this.notify()
    }

    private toggleExpand(item: TreeSelection<T>, expand: boolean) {
        const toggle: (node: TreeItem<T>, item: T, depth: number, maxDepth: number) => TreeItem<T> = (node, item, depth, maxDepth) => {
            if (depth === maxDepth) {
                return { ...node, expanded: expand }
            }
            const nextValue = this.getItemValue(item, depth + 1)
            if (nextValue !== null && nextValue !== undefined && node.children.has(nextValue)) {
                const child = node.children.get(nextValue)!
                node = { ...node }
                node.children.set(nextValue, toggle(child, item, depth + 1, maxDepth))
            }
            return node
        }

        this.tree = toggle(this.tree, item.source, -1, item.depth)
    }

    private commitSingle(item: TreeSelection<T>) {
        const superceded: TreeSelection<T>[] = []
        for (let i = 0; i < this.committed.length; i++) {
            const existingItem = this.committed[i]
            if (item.depth < existingItem.depth) {
                let matchesEveryValue = true
                for (let j = 0; matchesEveryValue && j <= item.depth; j++) {
                    if (this.getItemValue(existingItem.source, j) !== this.getItemValue(item.source, j)) {
                        matchesEveryValue = false
                    }
                }
                if (matchesEveryValue) {
                    superceded.push(existingItem)
                }
            }
        }
        this.committed = [...this.committed.filter((x) => !superceded.includes(x)), item]
    }

    private addSingleNode(item: T, searchParams: string) {
        const searchParamsLower = searchParams ? searchParams.toLowerCase() : ''
        const add: (node: TreeItem<T>, item: T, depth: number) => TreeItem<T> = (node, item, depth) => {
            const value = this.getItemValue(item, depth)
            const label = this.getItemLabel(item, depth)
            if (value !== null && value !== undefined) {
                if (node.children.has(value)) {
                    node = { ...node }
                    const child = node.children.get(value)!
                    node.children.set(value, add(child, item, depth + 1))
                } else {
                    node = {
                        ...node,
                        order: [...node.order, value]
                    }
                    const id = depth + '::' + value.replace(/[^A-Za-z0-9-_:.]/g, '')
                    const expanded =
                        (!(label || '').toLowerCase().includes(searchParamsLower) && !(value || '').toLowerCase().includes(searchParamsLower)) ||
                        (this.collapseAtDepth ? depth < this.collapseAtDepth - 1 : true)
                    const child: TreeItem<T> = { id, source: item, expanded, order: [], children: new Map() }
                    node.children.set(value, add(child, item, depth + 1))
                }
            }
            return node
        }

        this.tree = add(this.tree, item, 0)
    }

    private notify() {
        if (this.listener) {
            this.listener()
        }
    }
}
