import { FrameworkComponentProps } from '@om1/platform-utils'
import { cohortEditActions, CohortListItem } from '../../state'
import React, { FunctionComponent } from 'react'
import { Box, CircularProgress, IconButton } from '@mui/material'
import { LightTooltip } from '@om1/platform-components/Tooltip'
import { t } from '@lingui/macro'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'

export type GenerateAttritionFunnelComponentProps = FrameworkComponentProps<
    { cohortAttritionFunnelLoading: boolean },
    typeof cohortEditActions,
    { cohort?: CohortListItem }
>

export const GenerateAttritionFunnelComponent: FunctionComponent<GenerateAttritionFunnelComponentProps> = ({ state, actions, props }) => {
    const cohortId = props.cohort?.id
    return (
        <Box key={`cohort-generate-attrition-funnel-${cohortId}`}>
            {state.cohortAttritionFunnelLoading && (
                <Box color='grey.500' sx={{ backgroundColor: '#D6E4F2', color: '#012D72', borderRadius: '2px', margin: '5px' }}>
                    <LightTooltip title={t`Loading Attrition Funnel...`} placement='top'>
                        <span>
                            <IconButton disabled={true} aria-label={t`Attrition Funnel loading`}>
                                <CircularProgress color='inherit' size='1em' sx={{ backgroundColor: 'transparent', color: 'grey.500' }} />
                            </IconButton>
                        </span>
                    </LightTooltip>
                </Box>
            )}
            {!state.cohortAttritionFunnelLoading && (
                <LightTooltip title={t`Generate Attrition Funnel`} placement='top'>
                    <span>
                        <IconButton
                            onClick={() => {
                                if (cohortId) {
                                    actions.cohortAttritionFunnelGet({ cohortId: cohortId })
                                }
                            }}
                            aria-label={t`Attrition Funnel`}
                            sx={{ backgroundColor: '#D6E4F2', color: '#012D72', borderRadius: '2px', margin: '5px' }}
                        >
                            <FilterAltOutlinedIcon />
                        </IconButton>
                    </span>
                </LightTooltip>
            )}
        </Box>
    )
}
