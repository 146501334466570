import {
    AndFilterOperator,
    CohortNode,
    CriteriaBase,
    CriteriaOperation,
    CriteriaType,
    CriterionNode,
    FilterQualifier,
    FilterType,
    OperationNode,
    OrFilterOperator,
    QueryFilterNode,
    QueryFilterOperator,
    QueryFilters,
    isApiAndOperation,
    isApiExceptOperation,
    isApiOrOperation,
    isCountDistinctQualifier,
    isCriterionNode,
    isDateQualifier,
    isOperationNode,
    isPatientAgeQualifier
} from '../../../state'
import {
    generateApiDateAwareFilter,
    generateApiFilter,
    generateApiPatientAttributes,
    generateApiRelativeDateFilter,
    generateApiRelativeFollowUpFilter
} from './filter-utils'

/*
 * Export UI criteria blocks to API query filters
 */

/**
 * Converts UI formatted criteria to a format that is consumable by the API.
 *
 * @param blocks the root node of the UI block tree
 * @returns API-formatted query filters
 */
export function blocksToFilters(blocks: OperationNode): QueryFilters | undefined {
    const queryFilters: QueryFilters = {
        filters: [
            {
                except: [
                    { and: [], type: FilterType.AndFilterDTO },
                    { and: [], type: FilterType.AndFilterDTO }
                ],
                type: FilterType.ExceptFilterDTO
            }
        ]
    }

    // Use an arbitrary "blockId" to group patient attribute criteria, beginning at 0.
    // Pass it as a context so it can be mutated.
    const context = { blockId: 0 }
    blocks.children.forEach((child, index) => {
        if (isOperationNode(child) && child.operation === CriteriaOperation.AND) {
            child.children.forEach((o) => {
                if (isOperationNode(o) && o.operation === CriteriaOperation.OR) {
                    const orFilter = visitNode(o, undefined, context)
                    if (o.name && o.name.length > 0 && orFilter !== undefined) {
                        orFilter.name = o.name
                    }
                    if (queryFilters.filters) {
                        queryFilters.filters[0].except[index].and.push(orFilter as OrFilterOperator)
                    }
                }
            })
        }
    })

    return queryFilters
}

/**
 * Visits a UI node recursively and constructs the API representation of the data.
 *
 * @param node the UI node currently being visited
 * @param parent the parent API operator of the node currently being visited, or undefined if the root
 * @param context a context object containing state for the recursive calls
 * @returns the root of the API-formatted query filters
 */
function visitNode(node: CohortNode, parent: QueryFilterOperator | undefined, context: { blockId: number }): QueryFilterOperator | undefined {
    if (isOperationNode(node)) {
        let operator: AndFilterOperator | OrFilterOperator
        if (node.operation === CriteriaOperation.AND) {
            operator = { and: [], type: FilterType.AndFilterDTO }
        } else {
            operator = { or: [], type: FilterType.OrFilterDTO }
        }
        if (node.excluded) {
            operator.disabled = node.excluded
        }

        // If parent is undefined, that means this is the first call to this function, so we won't have a parent to append to.
        if (parent !== undefined) {
            if (isApiOrOperation(parent)) {
                parent.or.push(operator)
            } else if (isApiAndOperation(parent)) {
                parent.and.push(operator)
            }
        }

        for (let i = 0; i < node.children.length; i++) {
            const child = node.children[i]
            visitNode(child, operator, context)
        }

        return operator
    } else if (parent !== undefined && isCriterionNode(node)) {
        const children = isApiOrOperation(parent) ? parent.or : !isApiExceptOperation(parent) ? parent.and : []
        if (node.type === CriteriaType.PatientAttributes) {
            const filters = convertPatientAttributesCriterion(node, context.blockId++)
            children.push(filters)
        } else {
            const filters = convertGenericCriterion(node, context.blockId++)
            if (isApiOrOperation(parent)) {
                let orFilter = filters as OrFilterOperator
                for (let i = 0; i < orFilter.or.length; i++) {
                    const filter = orFilter.or[i]
                    children.push(filter)
                }
            } else {
                children.push(filters)
            }
        }
    } else {
        return parent
    }
}

/**
 * Converts a patient attributes UI criteria block to the appropriate API format.
 *
 * @param criterion a patient attributes criterion
 * @param blockId the block id to assign to the filters
 * @returns API-formatted patient attribute query filters
 */
function convertPatientAttributesCriterion(criterion: CriteriaBase, blockId: number) {
    const fields = criterion.filters.reduce((acc, f) => ({ ...acc, [f.field]: f.values }), {})
    return generateApiPatientAttributes({ blockId, fields })
}

/**
 * Converts any non-patient attributes UI criteria block to the appropriate API format. Detects and generates the
 * correct DTO format according to the criterion's properties.
 *
 * @param criterion a non-patient attributes criterion
 * @param blockId the block id to assign to the filters
 * @returns API-formatted query filters
 */
function convertGenericCriterion(criterion: CriterionNode, blockId: number): OrFilterOperator | QueryFilterNode {
    let orFilter: OrFilterOperator | QueryFilterNode = { or: [], type: FilterType.OrFilterDTO }
    const qualifiers = convertQualifiersToExport(criterion.qualifiers)

    if (criterion.reference?.followUpRelation) {
        const followUp: OrFilterOperator = generateApiRelativeFollowUpFilter(blockId, criterion) as OrFilterOperator
        orFilter = followUp
    } else if (criterion.reference) {
        const referenceQualifiers = convertQualifiersToExport(criterion.reference.criteria.qualifiers)

        const {
            reference: { criteria: referenceCriterion, dateRelation }
        } = criterion
        orFilter = generateApiRelativeDateFilter({
            blockId,
            subjectFields: criterion.filters.reduce((acc, f) => ({ ...acc, [f.field]: f.values }), {}),
            subjectType: criterion.type,
            subjectDateField: criterion.dateField,
            subjectQualifiers: qualifiers,
            referenceFields: referenceCriterion.filters.reduce((acc, f) => ({ ...acc, [f.field]: f.values }), {}),
            referenceType: referenceCriterion.type,
            referenceDateField: referenceCriterion.dateField,
            referenceQualifiers: referenceQualifiers,
            metadata: dateRelation
        })
    } else {
        const fields = criterion.filters.reduce((acc, f) => ({ ...acc, [f.field]: { value: f.values, table: f.table } }), {})
        if (
            ['first', 'last'].includes(criterion.dateField) ||
            criterion.qualifiers.findIndex((q) => isDateQualifier(q)) > -1 ||
            criterion.qualifiers.findIndex((q) => isPatientAgeQualifier(q)) > -1
        ) {
            // Generic criteria that have first/last dateField or have a DateQualifierDTO or PatientAgeQualifierDTO are date aware filters
            orFilter.or = generateApiDateAwareFilter({
                type: criterion.type,
                blockId,
                fields,
                qualifiers,
                dateField: criterion.dateField
            })
        } else {
            // All other generic criteria are FilterDTOs
            orFilter.or = generateApiFilter({
                type: criterion.type,
                blockId,
                fields,
                qualifiers
            })
        }
    }
    return orFilter
}

/**
 * Converts a list of flat qualifiers to a nested hierarchy, if needed. Primarily used for nesting qualifiers under a
 * CountDistinctQualifierDTO, if present.
 *
 * @example
 *
 * Pre-nesting:
 *
 * qualifiers:
 *  ├─CountDistinctQualifier
 *  ├─DateQualifierDTO
 *  └─PatientAgeQualifierDTO
 *
 * Post-nesting:
 *
 * qualifiers:
 *  └─CountDistinctQualifier
 *     ├─DateQualifierDTO
 *     └─PatientAgeQualifierDTO
 *
 * @param qualifiers a list of unnested qualifiers
 * @returns a list of nested qualifiers if a CountDistinctQualifierDTO is found, otherwise the same list as the input
 */
function convertQualifiersToExport(qualifiers: FilterQualifier[]) {
    const dateQualifierIndex = qualifiers.findIndex((q) => isDateQualifier(q))
    const countDistinctQualifierIndex = qualifiers.findIndex((q) => isCountDistinctQualifier(q))

    if (countDistinctQualifierIndex > -1 && dateQualifierIndex > -1) {
        return [
            { ...qualifiers[countDistinctQualifierIndex], qualifiers: [qualifiers[dateQualifierIndex]] },
            ...qualifiers.filter((q) => !isDateQualifier(q) && !isCountDistinctQualifier(q))
        ]
    } else {
        return qualifiers
    }
}
