import { yupResolver } from '@hookform/resolvers/yup'
import { t, Trans } from '@lingui/macro'
import CloseIcon from '@mui/icons-material/Close'
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material'
import { Box } from '@mui/system'
import { cohortListActions, CohortState } from '@om1/cohort-module/src/state'
import { rwaConditionsActions, RWAConditionsState } from '@om1/platform-settings-module/src/state/rwa-conditions'
import { RoutedFrameworkComponentProps } from '@om1/platform-utils'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { insightsLibraryActions, InsightsLibraryState } from '../state'

export type RegisterInsightsLibraryReportModalComponentProps = RoutedFrameworkComponentProps<
    {},
    {},
    RWAConditionsState & CohortState & InsightsLibraryState,
    typeof rwaConditionsActions & typeof insightsLibraryActions & typeof cohortListActions,
    { showRegisterInsightsLibraryReportModal: boolean; closeModal: () => void }
>

/**
 * A dialog.
 */
export const RegisterInsightsLibraryReportModalComponent: FunctionComponent<RegisterInsightsLibraryReportModalComponentProps> = ({
    state,
    actions,
    props
}) => {
    const { showRegisterInsightsLibraryReportModal, closeModal } = props
    const { conditions, cohortInsightsReports } = state
    const [disableSubmit, setDisableSubmit] = useState(false)
    const [selectedCohortName, setSelectedCohortName] = useState<string>('')
    const [selectedInsightsReportName, setSelectedInsightsReportName] = useState<string>('')

    const availableInsightsReports = useMemo(
        () =>
            conditions.filter(
                (report) =>
                    (!selectedCohortName ||
                        conditions.some((c) => c.cohortName === selectedCohortName && c.insightsReportName === report.insightsReportName)) &&
                    !cohortInsightsReports.some(
                        (cir) => cir.insightsReportName === report.insightsReportName && cir.cohortName === selectedCohortName
                    )
            ),
        [selectedCohortName, conditions, cohortInsightsReports]
    )

    const uniqueAvailableInsightsReports = useMemo(() => {
        const insightsReportSet = new Set(availableInsightsReports.map((report) => report.insightsReportName))
        return Array.from(insightsReportSet)
            .filter((reportName) =>
                conditions.some(
                    (c) =>
                        c.insightsReportName === reportName &&
                        !cohortInsightsReports.some((cir) => cir.insightsReportName === reportName && cir.cohortName === c.cohortName)
                )
            )
            .sort()
    }, [availableInsightsReports, conditions, cohortInsightsReports])

    const availableCohorts = useMemo(
        () =>
            conditions.filter(
                (condition) =>
                    (!selectedInsightsReportName ||
                        conditions.some((c) => c.insightsReportName === selectedInsightsReportName && c.cohortName === condition.cohortName)) &&
                    !cohortInsightsReports.some(
                        (cir) => cir.cohortName === condition.cohortName && cir.insightsReportName === selectedInsightsReportName
                    )
            ),
        [conditions, selectedInsightsReportName, cohortInsightsReports]
    )

    const uniqueAvailableCohorts = useMemo(() => {
        const cohortSet = new Set(availableCohorts.map((cohort) => cohort.cohortName))
        return Array.from(cohortSet).sort()
    }, [availableCohorts])

    const selectedCondition = conditions.find(
        (condition) => condition.cohortName === selectedCohortName && condition.insightsReportName === selectedInsightsReportName
    )

    const [loading, setLoading] = useState(state.list.loading || state.loading)

    useEffect(() => {
        setLoading(state.list.loading || state.loading)
    }, [state.list.loading, state.loading])

    const validationSchema = Yup.object().shape({
        cohortName: Yup.string().required(t`Cohort is required`),
        insightsReportName: Yup.string().required(t`Insights Report is required`)
    })

    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {
            cohortName: '',
            insightsReportName: ''
        },
        resolver: yupResolver(validationSchema)
    })

    const onSubmit = (formValues: { cohortName: string; insightsReportName: string }) => {
        if (selectedCondition) {
            actions.createInsightsLibraryReport({ cohortConditionId: selectedCondition.id })
            closeModal()
        }
    }

    const noValidCombinations = useMemo(() => {
        const allCohorts = new Set(conditions.map((c) => c.cohortName))
        const allReports = new Set(conditions.map((c) => c.insightsReportName))

        for (const cohort of allCohorts) {
            const reportsForCohort = conditions.filter((c) => c.cohortName === cohort).map((c) => c.insightsReportName)
            const availableReports = reportsForCohort.filter(
                (report) => !cohortInsightsReports.some((cir) => cir.insightsReportName === report && cir.cohortName === cohort)
            )
            if (availableReports.length > 0) {
                return false
            }
        }

        for (const report of allReports) {
            const cohortsForReport = conditions.filter((c) => c.insightsReportName === report).map((c) => c.cohortName)
            const availableCohorts = cohortsForReport.filter(
                (cohort) => !cohortInsightsReports.some((cir) => cir.cohortName === cohort && cir.insightsReportName === report)
            )
            if (availableCohorts.length > 0) {
                return false
            }
        }

        return true
    }, [conditions, cohortInsightsReports])

    useEffect(() => {
        if (noValidCombinations) {
            setDisableSubmit(true)
        } else {
            setDisableSubmit(false)
        }
    }, [noValidCombinations])

    return (
        <Dialog
            open={showRegisterInsightsLibraryReportModal}
            maxWidth='sm'
            fullWidth
            aria-labelledby='add-condition-dialog-title'
            onClose={closeModal}
        >
            <DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={closeModal}
                    sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                >
                    <CloseIcon />
                </IconButton>
                <Box id='add-condition-dialog-title' pr={4}>
                    <Trans>Register Insights Library Report</Trans>
                </Box>
            </DialogTitle>
            {disableSubmit && (
                <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box position='relative' display='flex' flexDirection='column' flex={1} sx={{ overflowY: 'auto' }}>
                        <Box component='pre' p={2} m={0} sx={{ overflow: 'auto' }}>
                            <Box display={'flex'} flexDirection={'column'}>
                                <Alert severity='info'>
                                    <Trans>No valid combinations of Cohort and Insights Report are available.</Trans>
                                </Alert>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
            )}
            {!disableSubmit && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box position='relative' display='flex' flexDirection='column' flex={1} sx={{ overflowY: 'auto' }}>
                            <Box component='pre' p={2} m={0} sx={{ overflow: 'auto' }}>
                                <Box display={'flex'} flexDirection={'column'}>
                                    <FormControl fullWidth sx={{ marginBottom: '20px' }}>
                                        <InputLabel required>
                                            <Trans>Cohort</Trans>
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name='cohortName'
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    label={<Trans>Select Cohort</Trans>}
                                                    disabled={loading || disableSubmit}
                                                    error={!!errors.cohortName}
                                                    defaultValue={uniqueAvailableCohorts.length === 1 ? uniqueAvailableCohorts[0] : ''}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setSelectedCohortName(e.target.value as string)
                                                    }}
                                                >
                                                    {uniqueAvailableCohorts.map((cohortName) => (
                                                        <MenuItem value={cohortName!} key={cohortName}>
                                                            {cohortName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel required>
                                            <Trans>Insights Report</Trans>
                                        </InputLabel>
                                        <Controller
                                            control={control}
                                            name='insightsReportName'
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    label={<Trans>Select Insights Report</Trans>}
                                                    disabled={loading || disableSubmit}
                                                    defaultValue={
                                                        uniqueAvailableInsightsReports.length === 1 ? uniqueAvailableInsightsReports[0] : ''
                                                    }
                                                    error={!!errors.insightsReportName}
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setSelectedInsightsReportName(e.target.value as string)
                                                    }}
                                                >
                                                    {uniqueAvailableInsightsReports.map((insightsReportName) => (
                                                        <MenuItem value={insightsReportName!} key={insightsReportName}>
                                                            {insightsReportName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='text' color='primary' onClick={closeModal}>
                            <Trans>Cancel</Trans>
                        </Button>
                        <Button variant='contained' type='submit' disabled={disableSubmit}>
                            <Trans>Save</Trans>
                        </Button>
                    </DialogActions>
                </form>
            )}
        </Dialog>
    )
}
