import { AssistantConversationDetailDTO, ExplorerAssistantConversationService } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { call, put } from 'redux-saga/effects'
import { explorerAssistantConversationActions } from '../state/explorer-assistant-conversation-actions'

export function createGetExplorerAssistantConversationSaga() {
    return function* (action: ReturnType<typeof explorerAssistantConversationActions.getConversation>) {
        try {
            if (!action.payload.path.id) {
                throw new Error('Conversation ID is required')
            }
            const assistantConversationDetailDTO: AssistantConversationDetailDTO = yield call(
                ExplorerAssistantConversationService.getExplorerAssistantConversationIdGet,
                { id: action.payload.path.id, debugMessages: action.payload.query.debugMessages }
            )
            yield put(explorerAssistantConversationActions.setActiveConversation(assistantConversationDetailDTO))
        } catch (error: any) {
            yield handleApiError(error)
        }
    }
}

export type GetExplorerAssistantConversationSaga = ReturnType<typeof createGetExplorerAssistantConversationSaga>
