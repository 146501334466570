/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CohortInsightsReportCreateDTO } from '../models/CohortInsightsReportCreateDTO'
import type { CohortInsightsReportDTO } from '../models/CohortInsightsReportDTO'
import type { CohortInsightsReportPatchDTO } from '../models/CohortInsightsReportPatchDTO'
import type { PaginatedDTO_CohortInsightsReportDTO_ } from '../models/PaginatedDTO_CohortInsightsReportDTO_'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class InsightsReportOrganizationService {
    /**
     * Index
     * @returns PaginatedDTO_CohortInsightsReportDTO_ Successful Response
     * @throws ApiError
     */
    public static indexRwaInsightsInsightsReportOrganizationGet({
        cohortId,
        insightsReportId,
        page = 1,
        limit = 10
    }: {
        cohortId?: string | null
        insightsReportId?: string | null
        /**
         * Page number for pagination, starting at 1
         */
        page?: number
        /**
         * Number of items per page, maximum 1000
         */
        limit?: number
    }): CancelablePromise<PaginatedDTO_CohortInsightsReportDTO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rwa_insights/insights_report_organization',
            query: {
                cohort_id: cohortId,
                insights_report_id: insightsReportId,
                page: page,
                limit: limit
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Create
     * @returns CohortInsightsReportDTO Successful Response
     * @throws ApiError
     */
    public static createRwaInsightsInsightsReportOrganizationPost({
        requestBody
    }: {
        requestBody: CohortInsightsReportCreateDTO
    }): CancelablePromise<CohortInsightsReportDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rwa_insights/insights_report_organization',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Get
     * @returns CohortInsightsReportDTO Successful Response
     * @throws ApiError
     */
    public static getRwaInsightsInsightsReportOrganizationCohortInsightsReportIdGet({
        cohortInsightsReportId
    }: {
        cohortInsightsReportId: string
    }): CancelablePromise<CohortInsightsReportDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rwa_insights/insights_report_organization/{cohort_insights_report_id}',
            path: {
                cohort_insights_report_id: cohortInsightsReportId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Patch
     * @returns CohortInsightsReportDTO Successful Response
     * @throws ApiError
     */
    public static patchRwaInsightsInsightsReportOrganizationOrganizationReportIdPatch({
        organizationReportId,
        requestBody
    }: {
        organizationReportId: string
        requestBody: CohortInsightsReportPatchDTO
    }): CancelablePromise<CohortInsightsReportDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/rwa_insights/insights_report_organization/{organization_report_id}',
            path: {
                organization_report_id: organizationReportId
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Delete
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteRwaInsightsInsightsReportOrganizationOrganizationReportIdDelete({
        organizationReportId
    }: {
        organizationReportId: string
    }): CancelablePromise<Record<string, string>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/rwa_insights/insights_report_organization/{organization_report_id}',
            path: {
                organization_report_id: organizationReportId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
}
