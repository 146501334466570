import { put } from 'redux-saga/effects'
import { explorerAssistantConversationActions } from '../state/explorer-assistant-conversation-actions'
import { webSocketActions } from './websocket-sagas'

export function createInvokeExplorerAssistantConversationSaga() {
    return function* (action: ReturnType<typeof explorerAssistantConversationActions.invokeConversation>) {
        try {
            yield put(explorerAssistantConversationActions.setAssistantIsResponding(true))
            if (!action.payload.id) {
                throw new Error('Conversation ID is required')
            }
            yield put(
                explorerAssistantConversationActions.appendResponse({
                    content: action.payload.requestBody.content,
                    direction: 'outgoing',
                    type: 'human'
                })
            )
            yield put(webSocketActions.sendMessage(action.payload.requestBody.content))
        } catch (error: any) {
            console.error(error)
        }
    }
}

export type InvokeExplorerAssistantConversationSaga = ReturnType<typeof createInvokeExplorerAssistantConversationSaga>
