import { OpenAPI } from '@om1/falcon-api/codegen/client/core/OpenAPI'
import { ActionsUnion, createAction } from '@om1/platform-utils/action-types'
import { END, eventChannel, EventChannel } from 'redux-saga'
import { call, cancel, cancelled, fork, put, take } from 'redux-saga/effects'

export enum WebSocketActionTypes {
    MESSAGE_RECEIVED = '@ws/message-received',
    CONNECTION_CLOSED = '@ws/connection-closed',
    SEND_MESSAGE = '@ws/send-message',
    SET_URL = '@ws/set-url',
    CONNECTION_OPENED = '@ws/connection-opened'
}

export const webSocketActions = {
    setUrl: (payload: string) => createAction(WebSocketActionTypes.SET_URL, payload),
    sendMessage: (payload: string) => createAction(WebSocketActionTypes.SEND_MESSAGE, payload),
    messageReceived: (payload: string) => createAction(WebSocketActionTypes.MESSAGE_RECEIVED, payload)
}

export type WebSocketActions = ActionsUnion<typeof webSocketActions>

function createWebSocketConnection(url: string): WebSocket {
    return new WebSocket(url)
}

function createWebSocketChannel(socket: WebSocket): EventChannel<any> {
    return eventChannel((emitter) => {
        socket.onopen = () => {
            socket.send(JSON.stringify({ token: String(OpenAPI.TOKEN) }))
            emitter({ type: WebSocketActionTypes.CONNECTION_OPENED })
        }

        socket.onmessage = (event) => {
            emitter({ type: WebSocketActionTypes.MESSAGE_RECEIVED, payload: event.data })
        }

        socket.onclose = (event) => {
            emitter({ type: WebSocketActionTypes.CONNECTION_CLOSED })
            emitter(END) // Closes the channel when the connection is closed
        }

        socket.onerror = (error) => {
            emitter({ type: WebSocketActionTypes.CONNECTION_CLOSED })
            emitter(END)
        }

        return () => {
            // console.log('Closing WebSocket connection');
            // socket.close();
        }
    })
}

function* watchWebSocketChannel(channel: EventChannel<any>) {
    try {
        while (true) {
            const action = yield take(channel)
            yield put(action)
        }
    } finally {
        if (yield cancelled()) {
            channel.close()
        }
    }
}

function* sendWebSocketMessage(socket: WebSocket) {
    try {
        while (true) {
            const action = yield take(WebSocketActionTypes.SEND_MESSAGE)
            if (socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify({ type: 'human', content: action.payload }))
            }
        }
    } catch (error) {
        console.error('Error in sendWebSocketMessage:', error)
    }
}

function* manageWebSocketConnection(url: string, token: string | undefined) {
    let socket: WebSocket
    let channel: EventChannel<any>

    try {
        socket = yield call(createWebSocketConnection, url)
        channel = yield call(createWebSocketChannel, socket)

        yield fork(watchWebSocketChannel, channel)
        yield fork(sendWebSocketMessage, socket)
    } catch (error) {
        console.error('Error managing WebSocket connection:', error)
    } finally {
        if (yield cancelled()) {
            console.log('WebSocket connection cancelled, closing socket')
        }
    }
}

function* manageConnection(url: string, token: string | undefined) {
    yield* manageWebSocketConnection(url, token)
}

export function* createWebSocketSaga() {
    let task
    while (true) {
        const action = yield take(WebSocketActionTypes.SET_URL)
        if (task) {
            yield cancel(task)
        }
        task = yield fork(function* () {
            yield* manageConnection(action.payload, String(OpenAPI.TOKEN))
        })
    }
}
