import { yupResolver } from '@hookform/resolvers/yup'
import { t, Trans } from '@lingui/macro'
import CloseIcon from '@mui/icons-material/Close'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField
} from '@mui/material'
import { Box } from '@mui/system'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import {
    DateRangeIntervalUnit,
    DateRangeOperator,
    DateRelationMetadata,
    DiagnosisCriterion,
    EhrNotesCriterion,
    ExternalCohortCriterion,
    LabTestCriterion,
    MedicationCriterion,
    ObservationCriterion,
    ObservationPeriodCriterion,
    ProcedureCriterion
} from '../../../state'

import { ReactNode } from 'react'
import { FollowUpRelationMetadata } from '../../../state'
import { CriteriaTypeLabel } from '../../shared/CriteriaTypeLabel'

export type FollowUpRelationDialogProps = {
    onCancel: () => void
    onSave: (followUpRelation: FollowUpRelationMetadata) => void
    initialValue: FollowUpRelationMetadata
    criteria:
        | DiagnosisCriterion
        | ProcedureCriterion
        | LabTestCriterion
        | MedicationCriterion
        | ObservationCriterion
        | ObservationPeriodCriterion
        | EhrNotesCriterion
        | ObservationPeriodCriterion
        | ExternalCohortCriterion
}

// note that the form is hardcoded for AT LEAST so only need interval and interval units for baseline and follow up
interface FollowUpRelationFormValues {
    baselineEnabled: boolean
    baselineInterval?: number // x in x days
    baselineIntervalUnit?: string // day month year
    followUpEnabled: boolean
    followUpInterval?: number // x in x days
    followUpIntervalUnit?: string // day month year
}

function formValuesToFollowUpRelationMetaData(formValues: FollowUpRelationFormValues): FollowUpRelationMetadata {
    const { baselineEnabled, baselineInterval, baselineIntervalUnit, followUpEnabled, followUpInterval, followUpIntervalUnit } = formValues
    let returnValue: FollowUpRelationMetadata = { baseline: undefined, followUp: undefined }
    if (baselineEnabled) {
        let baseline: DateRelationMetadata = {
            dateRangeOperator: DateRangeOperator.Before,
            intervalStartFromReferenceDate: baselineInterval ? Number.parseInt(baselineInterval.toString()) : undefined,
            intervalEndFromReferenceDate: undefined,
            intervalUnitFromReferenceDate: baselineIntervalUnit ? (baselineIntervalUnit as DateRangeIntervalUnit) : DateRangeIntervalUnit.Day,
            intervalIsInclusive: true
        }
        returnValue = { ...returnValue, baseline: baseline }
    }
    if (followUpEnabled) {
        let followUp: DateRelationMetadata = {
            dateRangeOperator: DateRangeOperator.After,
            intervalStartFromReferenceDate: followUpInterval ? Number.parseInt(followUpInterval.toString()) : undefined,
            intervalEndFromReferenceDate: undefined,
            intervalUnitFromReferenceDate: followUpIntervalUnit ? (followUpIntervalUnit as DateRangeIntervalUnit) : DateRangeIntervalUnit.Day,
            intervalIsInclusive: true
        }
        returnValue = { ...returnValue, followUp: followUp }
    }
    return returnValue
}

export const BaselineRelatioValidationSchema = Yup.object().shape({
    baselineEnabled: Yup.boolean(),
    baselineInterval: Yup.number().min(0).when('baselineEnabled', {
        is: true,
        then: Yup.number().required(),
        otherwise: Yup.number().notRequired()
    }),
    baselineIntervalUnit: Yup.string().when('baselineEnabled', {
        is: true,
        then: Yup.string().required(),
        otherwise: Yup.string().notRequired()
    })
})
export const FollowUpRelatioValidationSchema = Yup.object().shape({
    followUpEnabled: Yup.boolean(),
    followUpInterval: Yup.number().min(0).when('followUpEnabled', {
        is: true,
        then: Yup.number().required(),
        otherwise: Yup.number().notRequired()
    }),
    followUpIntervalUnit: Yup.string().when('followUpEnabled', {
        is: true,
        then: Yup.string().required(),
        otherwise: Yup.string().notRequired()
    })
})
export const FollowUpRelationDialog = ({ onCancel, onSave, criteria, initialValue }: FollowUpRelationDialogProps) => {
    let referenceType
    if (criteria.reference?.criteria) {
        referenceType = criteria.reference.criteria.type
    }
    const FollowUpRelationMetadataSchema = Yup.object()
        .shape({
            baseline: BaselineRelatioValidationSchema.nullable(),
            followUp: FollowUpRelatioValidationSchema.nullable()
        })
        .test('at-least-one', t`At least one of baseline or followUp must be non-null`, (values: FollowUpRelationMetadata) => {
            return values.baseline != null || values.followUp != null
        })

    const { handleSubmit, register, control } = useForm<FollowUpRelationFormValues>({
        defaultValues: {
            baselineEnabled: initialValue ? initialValue.baseline != null : true,
            baselineInterval: initialValue?.baseline?.intervalStartFromReferenceDate,
            baselineIntervalUnit: initialValue?.baseline?.intervalUnitFromReferenceDate,
            followUpEnabled: initialValue?.baseline ? initialValue.followUp != null : true,
            followUpInterval: initialValue?.followUp?.intervalStartFromReferenceDate,
            followUpIntervalUnit: initialValue?.followUp?.intervalUnitFromReferenceDate
        },
        resolver: yupResolver(FollowUpRelationMetadataSchema)
    })

    const onSubmit: SubmitHandler<FollowUpRelationFormValues> = (followUpRelationValues: FollowUpRelationFormValues) => {
        onSave(formValuesToFollowUpRelationMetaData(followUpRelationValues))
    }
    return (
        <Dialog open maxWidth='md' fullWidth>
            <DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={onCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Box paddingTop={2} display='flex' flexDirection='column' justifyContent='space-around' alignItems='center'>
                        <Box
                            paddingTop={2}
                            display='flex'
                            flexDirection='row'
                            justifyContent='space-around'
                            alignItems='center'
                            sx={{ width: '100%' }}
                        >
                            <Box sx={{ marginRight: 1, width: 150 }}>
                                <Controller
                                    control={control}
                                    name='baselineEnabled'
                                    render={({ field: { onChange, value } }) => (
                                        <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />
                                    )}
                                />
                                <Trans>Baseline</Trans>
                            </Box>
                            <Box sx={{ marginRight: 1 }}>
                                <Trans>At Least</Trans>
                            </Box>
                            <TextField
                                id='baselineInterval'
                                {...register('baselineInterval')}
                                required
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                sx={{ marginRight: 1, width: 75 }}
                            />
                            <Controller
                                control={control}
                                name='baselineIntervalUnit'
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        id='interval-unit'
                                        labelId='interval-unit-label'
                                        required
                                        sx={{ marginRight: 1, width: 150 }}
                                        value={value as DateRangeIntervalUnit}
                                        onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                    >
                                        <MenuItem value={DateRangeIntervalUnit.Day}>
                                            <Trans>Days</Trans>
                                        </MenuItem>
                                        <MenuItem value={DateRangeIntervalUnit.Month}>
                                            <Trans>Months</Trans>
                                        </MenuItem>
                                        <MenuItem value={DateRangeIntervalUnit.Year}>
                                            <Trans>Years</Trans>
                                        </MenuItem>
                                    </Select>
                                )}
                            />
                            <Box sx={{ marginRight: 1 }}>
                                <Trans>Before</Trans>
                            </Box>
                            <Box sx={{ marginRight: 1 }}>
                                <CriteriaTypeLabel criteriaType={referenceType} />
                            </Box>
                        </Box>
                        <Box sx={{ fontWeight: 'bold', marginTop: 2, marginLeft: 10, alignSelf: 'start' }}>
                            <Trans>And</Trans>
                        </Box>
                        <Box
                            paddingTop={2}
                            display='flex'
                            flexDirection='row'
                            justifyContent='space-around'
                            alignItems='center'
                            sx={{ width: '100%' }}
                        >
                            <Box sx={{ marginRight: 1, width: 139 }}>
                                <Controller
                                    control={control}
                                    name='followUpEnabled'
                                    render={({ field: { onChange, value } }) => (
                                        <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />
                                    )}
                                />
                                <Trans>Follow Up</Trans>
                            </Box>
                            <Box sx={{ marginRight: 1 }}>
                                <Trans>At Least</Trans>
                            </Box>
                            <TextField
                                id='followUpInterval'
                                {...register('followUpInterval')}
                                required
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                sx={{ marginRight: 1, width: 75 }}
                            />
                            <Controller
                                control={control}
                                name='followUpIntervalUnit'
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        id='interval-unit'
                                        labelId='interval-unit-label'
                                        required
                                        sx={{ marginRight: 1, width: 150 }}
                                        value={value as DateRangeIntervalUnit}
                                        onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                    >
                                        <MenuItem value={DateRangeIntervalUnit.Day}>
                                            <Trans>Days</Trans>
                                        </MenuItem>
                                        <MenuItem value={DateRangeIntervalUnit.Month}>
                                            <Trans>Months</Trans>
                                        </MenuItem>
                                        <MenuItem value={DateRangeIntervalUnit.Year}>
                                            <Trans>Years</Trans>
                                        </MenuItem>
                                    </Select>
                                )}
                            />
                            <Box sx={{ marginRight: 1 }}>
                                <Trans>After</Trans>
                            </Box>
                            <Box sx={{ marginRight: 1 }}>
                                <CriteriaTypeLabel criteriaType={referenceType} />
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='primary' onClick={onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button variant='contained' type='submit'>
                        <Trans>Save</Trans>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
