import { connectRedux } from '@om1/platform-utils'
import { cohortBlocksEditActions, CohortState, CriterionNode } from '../../../state'
import { findNode } from '../../../state/edit/blocks/cohort-blocks-edit-reducer'
import { ObservationNumericScoreEditDialogComponent } from './ObservationNumericScoreEditDialogComponent'
export function createObservationNumericScoreEditDialogComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        ObservationNumericScoreEditDialogComponent,
        (state: TState) => {
            const nodeId = state.cohort.edit.blocks.ui.observationScoreEditDialog.target.nodeId

            return {
                criteria: findNode(state.cohort.edit.blocks.tree, nodeId) as CriterionNode
            }
        },
        cohortBlocksEditActions
    )
}

export type ObservationNumericScoreeEditDialog = ReturnType<typeof createObservationNumericScoreEditDialogComponent>
