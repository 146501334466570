/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TaskChainDTO } from '../models/TaskChainDTO'
import type { TaskDTO } from '../models/TaskDTO'
import type { TaskPriorityEnum } from '../models/TaskPriorityEnum'
import type { TaskWithArgsDTO } from '../models/TaskWithArgsDTO'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class ExplorerTasksService {
    /**
     * Test
     * @returns TaskDTO Successful Response
     * @throws ApiError
     */
    public static testExplorerTasksTestGet({
        word,
        shouldCache = false,
        shouldFail = false,
        durationSeconds = 1,
        queue = 'main-queue'
    }: {
        word: string
        shouldCache?: boolean
        shouldFail?: boolean
        durationSeconds?: number
        queue?: string
    }): CancelablePromise<TaskDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/tasks/test',
            query: {
                word: word,
                should_cache: shouldCache,
                should_fail: shouldFail,
                duration_seconds: durationSeconds,
                queue: queue
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Cohort Update Size
     * @returns TaskDTO Successful Response
     * @throws ApiError
     */
    public static cohortUpdateSizeExplorerTasksCohortCohortIdUpdateSizeGet({
        cohortId,
        priority = 'HIGH'
    }: {
        cohortId: string
        priority?: TaskPriorityEnum
    }): CancelablePromise<TaskDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/tasks/cohort/{cohort_id}/update_size',
            path: {
                cohort_id: cohortId
            },
            query: {
                priority: priority
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Cohort Duplicate
     * @returns TaskDTO Successful Response
     * @throws ApiError
     */
    public static cohortDuplicateExplorerTasksCohortCohortIdDuplicateGet({
        cohortId,
        priority = 'HIGH'
    }: {
        cohortId: string
        priority?: TaskPriorityEnum
    }): CancelablePromise<TaskDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/tasks/cohort/{cohort_id}/duplicate',
            path: {
                cohort_id: cohortId
            },
            query: {
                priority: priority
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Execute Report
     * @returns TaskDTO Successful Response
     * @throws ApiError
     */
    public static executeReportExplorerTasksReportReportIdExecuteGet({
        reportId,
        priority = 'HIGH'
    }: {
        reportId: string
        priority?: TaskPriorityEnum
    }): CancelablePromise<TaskDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/tasks/report/{report_id}/execute',
            path: {
                report_id: reportId
            },
            query: {
                priority: priority
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Summary Report Generate
     * Endpoint to generate a summary report for a cohort.
     *
     * :param cohort_id: UUID of the cohort.
     * :param priority: Priority of the task.
     * :param pg: Postgres session.
     * :param current_user: Current active user.
     * :return: TaskDTO with the task ID.
     * @returns TaskDTO Successful Response
     * @throws ApiError
     */
    public static summaryReportGenerateExplorerTasksCohortCohortIdSummaryReportGenerateGet({
        cohortId,
        priority = 'HIGH'
    }: {
        cohortId: string
        priority?: TaskPriorityEnum
    }): CancelablePromise<TaskDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/tasks/cohort/{cohort_id}/summary_report_generate',
            path: {
                cohort_id: cohortId
            },
            query: {
                priority: priority
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Summarize Cohort
     * @returns TaskDTO Successful Response
     * @throws ApiError
     */
    public static summarizeCohortExplorerTasksCohortCohortIdSummarizeGet({ cohortId }: { cohortId: string }): CancelablePromise<TaskDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/tasks/cohort/{cohort_id}/summarize',
            path: {
                cohort_id: cohortId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Generate Cohort Attrition Funnel
     * @returns any Successful Response
     * @throws ApiError
     */
    public static generateCohortAttritionFunnelExplorerTasksCohortCohortIdAttritionFunnelGenerateGet({
        cohortId
    }: {
        cohortId: string
    }): CancelablePromise<TaskChainDTO | TaskWithArgsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/tasks/cohort/{cohort_id}/attrition_funnel_generate',
            path: {
                cohort_id: cohortId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
}
