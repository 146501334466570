import { uniq } from 'lodash'
import {
    AnalyticsRefDimension,
    AndFilterOperator,
    CohortNode,
    CriteriaBase,
    CriteriaType,
    CriterionNode,
    DateAwareFilterDTO,
    DateFieldOptions,
    DateRelationMetadata,
    DateWindowFilterDTO,
    DimensionGroupingType,
    FilterDTO,
    FilterQualifier,
    FilterType,
    FollowUpRelationMetadata,
    OperationNode,
    OrFilterOperator,
    QueryFilterBase,
    QueryFilterNode,
    RelativeDateFilterDTO,
    ReportQueryFilters,
    ReportSection,
    ReportType
} from '../../../state'
import { DEFAULT_DATE_FIELD, DEFAULT_DATE_RELATION_METADATA } from './constants'
import {
    AnyLabRefFieldMapper,
    DiagnosisCuiRefFieldMapper,
    DiagnosisGemMappedIcd10RefFieldMapper,
    DiagnosisIcd10RefFieldMapper,
    EhrNotesRefFieldMapper,
    extractFieldLabel,
    LabRefFieldMapper,
    MedicationCuiRefFieldMapper,
    MedicationNdcRefFieldMapper,
    MedicationRefFieldMapper,
    ObservationPeriodFieldMapper,
    ObservationRefFieldMapper,
    PhenotypeRefFieldMapper,
    ProcedureRefFieldMapper,
    RaceRefFieldMapper,
    RefFieldMapper,
    SexRefFieldMapper,
    SpecialtyRefFieldMapper,
    StateRefFieldMapper
} from './ref-field-mappers'
import { ProductCustomCohortRefFieldMapper } from './ref-field-mappers/product-custom-cohort-ref-field-mapper'

/*
 * API DTO Generators
 *
 * Used to generate API-formatted DTO objects for query filters based on a smaller set of input parameters than writing
 * the objects directly.
 *
 * For single DTO generators, expect to pass in the name of the field to filter on along with the list of possible
 * values for the field as a string array.
 *
 * ```json
 * {
 *   ...
 *   field: 'my_field',
 *   values: ['foo', 'bar']
 *   ...
 * }
 * ```
 *
 * For generators that create multiple DTOs for multiple fields, expect to pass an object where the keys are the field
 * names, and the values are string arrays containing the values for the associated field.
 *
 *
 * ```json
 * {
 *   ...
 *   fields: { 'my_field': ['foo', 'bar'] }
 *   ...
 * }
 * ```
 */

/**
 * Generates multiple API FilterDTO_Outputs for the special shape of filters needed to model patient attributes. Generates
 * filters in the format of `sex AND race AND (region OR sub_region OR state)`.
 */
export function generateApiPatientAttributes({ blockId, fields }: { blockId: number; fields: { [key: string]: string[] } }) {
    const baseAnd: AndFilterOperator = {
        and: [],
        type: FilterType.AndFilterDTO
    }
    const baseOr: OrFilterOperator = { or: [], type: FilterType.OrFilterDTO }
    Object.keys(fields).forEach((field) => {
        const filter = generateApiFilterDTO({
            blockId,
            type: CriteriaType.PatientAttributes,
            field,
            values: fields[field]
        })
        if (['region', 'sub_region', 'state'].includes(field)) {
            baseOr.or.push(filter)
        } else {
            baseAnd.and.push(filter)
        }
    })

    baseAnd.and.push(baseOr)

    return baseAnd
}

/**
 * Generates multiple API FilterDTO_Outputs across multiple fields for a single criteria type / ref table.
 */
export function generateApiFilter(payload: {
    blockId: number
    type: CriteriaType
    fields: { [key: string]: { value: string[]; table?: string } }
    qualifiers?: FilterQualifier[]
}): QueryFilterNode[] {
    const { blockId, type, fields, qualifiers } = payload

    const filters: QueryFilterNode[] = []
    Object.keys(fields).forEach((field) => {
        filters.push(generateApiFilterDTO({ blockId, type, field, values: fields[field].value, qualifiers, table: fields[field].table }))
    })

    return filters
}

/**
 * Generates a single API FilterDTO_Output for a single field for a single criteria type / ref table.
 */
export function generateApiFilterDTO(payload: {
    blockId: number
    type: CriteriaType
    field: string
    values: string[]
    qualifiers?: FilterQualifier[]
    table?: string
}): FilterDTO {
    const { blockId, type, field, values, qualifiers, table } = payload

    const fieldMapper = getFieldMapperFromType(type, field)

    let filter: FilterDTO = {
        blockId,
        type: FilterType.FilterDTO,
        table: table ? table : fieldMapper.table,
        field,
        operator: 'in',
        values
    }

    if (qualifiers) {
        filter = { ...filter, qualifiers: qualifiers }
    }

    return filter
}

export function generateApiDateWindowFilter(payload: {
    blockId: number
    type: CriteriaType
    field: string
    values: string[]
    qualifiers?: FilterQualifier[] | undefined
}): DateWindowFilterDTO {
    const { blockId, type, field, values, qualifiers } = payload

    return generateApiDateWindowFilterDTO({ blockId, type, field, values, qualifiers })
}

export function generateApiDateWindowFilterDTO(payload: {
    blockId: number
    type: CriteriaType
    field: string
    values: string[]
    qualifiers?: FilterQualifier[]
}): DateWindowFilterDTO {
    const { blockId, type, field, values, qualifiers } = payload

    const fieldMapper = getFieldMapperFromType(type, field)

    let filter: DateWindowFilterDTO = {
        blockId,
        type: FilterType.DateWindowFilterDTO,
        table: fieldMapper.table,
        field,
        operator: 'in',
        values,
        startDateField: fieldMapper.dateField.start!,
        endDateField: fieldMapper.dateField.end!,
        qualifiers: []
    }

    if (qualifiers) {
        filter = { ...filter, qualifiers: qualifiers }
    }

    return filter
}

/**
 * Generates multiple API DateAwareFilterDTO_Output_Outputs across multiple fields for a single criteria type / ref table.
 */
export function generateApiDateAwareFilter(payload: {
    blockId: number
    type: CriteriaType
    fields: { [key: string]: { value: string[]; table?: string } }
    dateField?: DateFieldOptions
    qualifiers?: FilterQualifier[]
}): DateAwareFilterDTO[] {
    const { blockId, type, fields, dateField = DEFAULT_DATE_FIELD, qualifiers = [] } = payload

    const filters: DateAwareFilterDTO[] = []

    Object.keys(fields).forEach((field) => {
        filters.push(generateApiDateAwareFilterDTO({ blockId, type, field, values: fields[field].value, qualifiers, dateField }))
    })

    return filters
}

/**
 * Generates a single API DateAwareFilterDTO_Output_Output for a single field for a single criteria type / ref table.
 */
export function generateApiDateAwareFilterDTO(payload: {
    blockId: number
    type: CriteriaType
    field: string
    values: string[]
    dateField?: DateFieldOptions
    qualifiers?: FilterQualifier[]
}): DateAwareFilterDTO {
    const { blockId, type, field, values, dateField = DEFAULT_DATE_FIELD, qualifiers = [] } = payload

    const fieldMapper = getFieldMapperFromType(type, field)

    const filter: DateAwareFilterDTO = {
        blockId,
        type: FilterType.DateAwareFilterDTO,
        table: fieldMapper.table,
        field,
        operator: 'in',
        values,
        dateField: fieldMapper.dateField[dateField]!,
        qualifiers: []
    }

    if (qualifiers) {
        filter.qualifiers = qualifiers
    }

    return filter
}

/**
 * Generates multiple API RelativeDateFilterDTO_Outputs across multiple fields for a single criteria type / ref table.
 * Generates M * N RelativeDateFilterDTO_Outputs, where M is the number of unique subject fields and N is the number of unique
 * reference fields.
 */
export function generateApiRelativeDateFilter(payload: {
    blockId: number
    subjectFields: { [key: string]: string[] }
    subjectType: CriteriaType
    subjectDateField: DateFieldOptions
    subjectQualifiers?: FilterQualifier[]
    referenceFields: { [key: string]: string[] }
    referenceType: CriteriaType
    referenceDateField: DateFieldOptions
    referenceQualifiers?: FilterQualifier[]
    metadata?: DateRelationMetadata
    omitOr?: boolean
}): OrFilterOperator | QueryFilterNode {
    const { blockId, metadata } = payload
    const { subjectFields, subjectType, subjectDateField, subjectQualifiers } = payload
    const { referenceFields, referenceType, referenceDateField, referenceQualifiers } = payload

    const baseOr: OrFilterOperator = { or: [], type: FilterType.OrFilterDTO }

    Object.keys(subjectFields).forEach((subjectField) => {
        Object.keys(referenceFields).forEach((referenceField) => {
            baseOr.or.push(
                generateApiRelativeDateFilterDTO({
                    blockId,
                    subjectField,
                    subjectType,
                    subjectValues: subjectFields[subjectField],
                    subjectDateField,
                    subjectQualifiers,
                    referenceField,
                    referenceType,
                    referenceValues: referenceFields[referenceField],
                    referenceDateField,
                    referenceQualifiers,
                    metadata
                })
            )
        })
    })

    return payload.omitOr ? baseOr.or[0] : baseOr
}

/**
 * Generates multiple API RelativeDateFilterDTO_Outputs across multiple fields for a single criteria type / ref table.
 */
export function generateApiRelativeDateFilterDTO(payload: {
    blockId: number
    subjectType: CriteriaType
    subjectField: string
    subjectValues: string[]
    subjectDateField: DateFieldOptions
    subjectQualifiers?: FilterQualifier[]
    referenceType: CriteriaType
    referenceField: string
    referenceValues: string[]
    referenceDateField: DateFieldOptions
    referenceQualifiers?: FilterQualifier[]
    metadata?: DateRelationMetadata
}): RelativeDateFilterDTO {
    const { blockId, metadata } = payload
    const { subjectType, subjectField, subjectValues, subjectDateField, subjectQualifiers } = payload
    const { referenceType, referenceField, referenceValues, referenceDateField, referenceQualifiers } = payload

    const dateRelationMetadata = metadata ? { ...DEFAULT_DATE_RELATION_METADATA, ...metadata } : DEFAULT_DATE_RELATION_METADATA

    const subjectFilter = generateApiDateAwareFilterDTO({
        blockId,
        type: subjectType,
        field: subjectField,
        values: subjectValues,
        dateField: subjectDateField,
        qualifiers: subjectQualifiers
    })

    const referenceFilter = generateApiDateAwareFilterDTO({
        blockId,
        type: referenceType,
        field: referenceField,
        values: referenceValues,
        dateField: referenceDateField,
        qualifiers: referenceQualifiers
    })

    return {
        blockId,
        type: FilterType.RelativeDateFilterDTO,
        subjectFilter,
        referenceFilter,
        ...dateRelationMetadata
    }
}

export function generateApiRelativeFollowUpFilter(blockId: number, criterionNode: CriterionNode) {
    return {
        or: [
            {
                type: FilterType.RelativeFollowUpFilterDTO,
                blockId: blockId,
                baseline: criterionNode.reference?.followUpRelation?.baseline
                    ? (generateApiRelativeDateFilter({
                          blockId: blockId,
                          subjectFields: criterionNode.filters?.reduce((acc, f) => ({ ...acc, [f.field]: f.values }), {}),
                          subjectType: criterionNode.type,
                          subjectDateField: 'start',
                          subjectQualifiers: criterionNode.qualifiers,
                          referenceFields: criterionNode.reference?.criteria?.filters?.reduce((acc, f) => ({ ...acc, [f.field]: f.values }), {})!,
                          referenceType: criterionNode.reference?.criteria?.type!,
                          referenceDateField: criterionNode.reference?.criteria?.dateField!,
                          referenceQualifiers: criterionNode.reference?.criteria.qualifiers,
                          metadata: criterionNode.reference?.followUpRelation?.baseline,
                          omitOr: true
                      }) as RelativeDateFilterDTO)
                    : undefined,
                followUp: criterionNode.reference?.followUpRelation?.followUp
                    ? (generateApiRelativeDateFilter({
                          blockId: blockId,
                          subjectFields: criterionNode.filters?.reduce((acc, f) => ({ ...acc, [f.field]: f.values }), {}),
                          subjectType: criterionNode.type,
                          subjectDateField: 'end',
                          subjectQualifiers: criterionNode.qualifiers,
                          referenceFields: criterionNode.reference?.criteria?.filters?.reduce((acc, f) => ({ ...acc, [f.field]: f.values }), {})!,
                          referenceType: criterionNode.reference?.criteria?.type!,
                          referenceDateField: criterionNode.reference?.criteria?.dateField!,
                          referenceQualifiers: criterionNode.reference?.criteria.qualifiers,
                          metadata: criterionNode.reference?.followUpRelation?.followUp,
                          omitOr: true
                      }) as RelativeDateFilterDTO)
                    : undefined
            }
        ]
    }
}

export function generateApiRelativeFollowUpFilterDTO(payload: {
    blockId: number
    // some missing params here
    metadata?: FollowUpRelationMetadata
}) {
    // TODO implement
}

/*
 * UI criteria block generators
 *
 * Used to generate store-backed UI objects for each criteria block in the UI based on a smaller set of input parameters
 * than writing the objects directly.
 *
 * For single DTO generators, expect to pass in the name of the field to filter on along with the list of possible
 * values for the field as a string array.
 *
 * ```json
 * {
 *   ...
 *   field: 'my_field',
 *   values: ['foo', 'bar']
 *   ...
 * }
 * ```
 *
 * For generators that create multiple DTOs for multiple fields, expect to pass an object where the keys are the field
 * names, and the values are string arrays containing the values for the associated field.
 *
 *
 * ```json
 * {
 *   ...
 *   fields: { 'my_field': ['foo', 'bar'] }
 *   ...
 * }
 * ```
 */

/**
 * Generates the most basic, standalone UI criteria block. Can represent multiple fields across a single criteria type.
 */
export function generateUICriteriaBlock(payload: {
    id: string
    type: CriteriaType
    fields: { [key: string]: string[] }
    dateField?: DateFieldOptions
    qualifiers?: FilterQualifier[]
}): CriterionNode {
    const { id, type, fields, dateField = DEFAULT_DATE_FIELD, qualifiers = [] } = payload
    const filters = generateUICriteriaFilters({ fields, type })

    return {
        id,
        type,
        filters,
        dateField,
        qualifiers
    }
}

/**
 * Generates formatted filters to be attached to a UI criteria block, representing the selected fields and associated
 * values chosen for those fields in a given table.
 */
export function generateUICriteriaFilters({ fields, type }: { fields: { [key: string]: string[] }; type: CriteriaType }): QueryFilterBase[] {
    const filters: QueryFilterBase[] = []
    Object.keys(fields).forEach((field) => {
        const fieldMapper: RefFieldMapper<any> | typeof ObservationPeriodFieldMapper | typeof EhrNotesRefFieldMapper = getFieldMapperFromType(
            type,
            field
        )
        let filter: QueryFilterBase = {
            field,
            operator: 'in',
            table: fieldMapper.table,
            values: fields[field]
        }
        filters.push(filter)
    })

    return filters
}

/**
 * Generates a complex UI criteria block that represents a relationship between a subject / reference pair of criteria
 * connected by a date relation.
 */
export function generateUIDateRelationCriteriaBlock(payload: {
    subjectType: CriteriaType
    subjectFields: { [key: string]: string[] }
    subjectId: string
    subjectDateField?: DateFieldOptions
    subjectQualifiers?: FilterQualifier[]
    referenceType: CriteriaType
    referenceFields: { [key: string]: string[] }
    referenceId: string
    referenceDateField?: DateFieldOptions
    referenceQualifiers?: FilterQualifier[]
    metadata?: DateRelationMetadata
}): CriteriaBase {
    const { metadata } = payload
    const { subjectType, subjectFields, subjectId, subjectDateField = DEFAULT_DATE_FIELD, subjectQualifiers = [] } = payload
    const { referenceType, referenceFields, referenceId, referenceDateField = 'first', referenceQualifiers = [] } = payload
    const dateRelationMetadata = metadata ? { ...DEFAULT_DATE_RELATION_METADATA, ...metadata } : DEFAULT_DATE_RELATION_METADATA

    const subject = generateUICriteriaBlock({
        id: subjectId,
        type: subjectType,
        fields: subjectFields,
        dateField: subjectDateField,
        qualifiers: subjectQualifiers
    })

    const reference = generateUICriteriaBlock({
        id: referenceId,
        type: referenceType,
        fields: referenceFields,
        dateField: referenceDateField,
        qualifiers: referenceQualifiers
    })

    return {
        ...subject,
        reference: {
            criteria: { ...reference },
            dateRelation: dateRelationMetadata
        }
    }
}

/**
 * Maps a criteria type (and field) to a ref field mapper. `field` may not be necessary in all cases, but is
 * specifically helpful for patient attributes.
 *
 * @param type The criteria type
 * @param field The field within the criteria type
 * @returns The correct field mapper for the passed in criteria type
 */
export function getFieldMapperFromType(
    type: CriteriaType,
    field: string
):
    | typeof DiagnosisCuiRefFieldMapper
    | typeof DiagnosisIcd10RefFieldMapper
    | typeof LabRefFieldMapper
    | typeof MedicationRefFieldMapper
    | typeof MedicationCuiRefFieldMapper
    | typeof MedicationNdcRefFieldMapper
    | typeof ObservationRefFieldMapper
    | typeof ProcedureRefFieldMapper
    | typeof SexRefFieldMapper
    | typeof RaceRefFieldMapper
    | typeof StateRefFieldMapper
    | typeof ObservationPeriodFieldMapper
    | typeof SpecialtyRefFieldMapper
    | typeof EhrNotesRefFieldMapper
    | typeof PhenotypeRefFieldMapper
    | typeof ProductCustomCohortRefFieldMapper {
    switch (type) {
        case CriteriaType.Diagnosis:
            switch (field) {
                case 'boc_cui':
                    return DiagnosisCuiRefFieldMapper
                case 'anchor_diagnosis_concept_id':
                    return DiagnosisGemMappedIcd10RefFieldMapper
                default:
                    return DiagnosisIcd10RefFieldMapper
            }
        case CriteriaType.LabTest:
            switch (field) {
                case 'has_labs':
                    return AnyLabRefFieldMapper
                default:
                    return LabRefFieldMapper
            }
        case CriteriaType.Medication:
            switch (field) {
                case 'ndc_code':
                    return MedicationNdcRefFieldMapper
                case 'boc_cui':
                    return MedicationCuiRefFieldMapper
                default:
                    return MedicationRefFieldMapper
            }
        case CriteriaType.PatientAttributes:
            switch (field) {
                case 'region':
                case 'sub_region':
                case 'state':
                    return StateRefFieldMapper
                case 'sex':
                    return SexRefFieldMapper
                case 'race':
                    return RaceRefFieldMapper
                default:
                    return StateRefFieldMapper
            }
        case CriteriaType.Observation:
            switch (field) {
                default:
                    return ObservationRefFieldMapper
            }
        case CriteriaType.Procedure:
            switch (field) {
                default:
                    return ProcedureRefFieldMapper
            }
        case CriteriaType.ObservationPeriod:
            switch (field) {
                default:
                    return ObservationPeriodFieldMapper
            }
        case CriteriaType.EhrNotes:
            switch (field) {
                case 'specialty_concept_id':
                    return SpecialtyRefFieldMapper
                default:
                    return EhrNotesRefFieldMapper
            }
        case CriteriaType.ExternalCohort:
            switch (field) {
                case 'phenotype':
                    return PhenotypeRefFieldMapper
                default:
                    return ProductCustomCohortRefFieldMapper
            }
        default:
            return DiagnosisIcd10RefFieldMapper
    }
}

export function deduceDimensionFromTableAndField(filter: QueryFilterBase) {
    if (filter.table === SexRefFieldMapper.table) {
        if (SexRefFieldMapper.filterFieldOrder.includes(filter.field)) {
            return SexRefFieldMapper.dimension
        } else if (StateRefFieldMapper.filterFieldOrder.includes(filter.field)) {
            return StateRefFieldMapper.dimension
        } else if (RaceRefFieldMapper.filterFieldOrder.includes(filter.field)) {
            return RaceRefFieldMapper.dimension
        }
    } else if (filter.table === DiagnosisIcd10RefFieldMapper.table) {
        if (DiagnosisIcd10RefFieldMapper.filterFieldOrder.includes(filter.field)) {
            return DiagnosisIcd10RefFieldMapper.dimension
        } else if (DiagnosisGemMappedIcd10RefFieldMapper.filterFieldOrder.includes(filter.field)) {
            return DiagnosisGemMappedIcd10RefFieldMapper.dimension
        } else if (DiagnosisCuiRefFieldMapper.filterFieldOrder.includes(filter.field)) {
            return DiagnosisCuiRefFieldMapper.dimension
        }
    } else if (filter.table === LabRefFieldMapper.table) {
        if (LabRefFieldMapper.filterFieldOrder.includes(filter.field)) {
            return LabRefFieldMapper.dimension
        }
    } else if (filter.table === MedicationRefFieldMapper.table) {
        if (MedicationRefFieldMapper.filterFieldOrder.includes(filter.field)) {
            return MedicationRefFieldMapper.dimension
        } else if (MedicationCuiRefFieldMapper.filterFieldOrder.includes(filter.field)) {
            return MedicationCuiRefFieldMapper.dimension
        } else if (MedicationNdcRefFieldMapper.filterFieldOrder.includes(filter.field)) {
            return MedicationNdcRefFieldMapper.dimension
        }
    } else if (filter.table === ObservationRefFieldMapper.table) {
        if (ObservationRefFieldMapper.filterFieldOrder.includes(filter.field)) {
            return ObservationRefFieldMapper.dimension
        }
    } else if (filter.table === ProcedureRefFieldMapper.table) {
        if (ProcedureRefFieldMapper.filterFieldOrder.includes(filter.field)) {
            return ProcedureRefFieldMapper.dimension
        }
    } else if (filter.table === SpecialtyRefFieldMapper.table) {
        if (SpecialtyRefFieldMapper.filterFieldOrder.includes(filter.field)) {
            return SpecialtyRefFieldMapper.dimension
        }
    } else if (filter.table === PhenotypeRefFieldMapper.table) {
        if (PhenotypeRefFieldMapper.filterFieldOrder.includes(filter.field)) {
            return PhenotypeRefFieldMapper.dimension
        } else if (ProductCustomCohortRefFieldMapper.filterFieldOrder.includes(filter.field)) {
            return ProductCustomCohortRefFieldMapper.dimension
        }
    } else {
        // TODO: What to do if we can't deduce dimension properly?
        return DiagnosisIcd10RefFieldMapper.dimension
    }
}

/**
 * Build a full query object for a report, deriving certain fields from the filters.
 *
 * @param filters A flat list of filters used to generate a report
 * @returns The passed in filters, decorated with the correct dimensions and groupType for a full query
 */
export const REPORT_SECTION_ID = 'bar_chart_section'
export const DRILLDOWN_SECTION_ID = 'drilldown_section'
export const DRILLDOWN_SUMMARY_SECTION_ID = 'drilldown_summary_section'
export const DATA_TYPE_CHART_ID = 'data_type_chart'
export const DEMOGRAPHICS_CHART_ID = 'demographics_chart'

export const getReportSectionsFromFilters = (filters: QueryFilterBase[]): ReportSection[] => {
    const getDimensionsFromFilter = (filter: QueryFilterBase): string[] => {
        const dimension = deduceDimensionFromTableAndField(filter)
        const { table, field } = filter

        let dimensions: string[] = []
        switch (dimension) {
            case AnalyticsRefDimension.DIAGNOSIS_CUI:
                dimensions = [field, extractFieldLabel(field, DiagnosisCuiRefFieldMapper)]
                break
            case AnalyticsRefDimension.DIAGNOSIS_ICD10:
                dimensions = [field, extractFieldLabel(field, DiagnosisIcd10RefFieldMapper)]
                break
            case AnalyticsRefDimension.DIAGNOSIS_ICD10_GEM_MAPPED:
                dimensions = [field, extractFieldLabel(field, DiagnosisGemMappedIcd10RefFieldMapper)]
                break
            case AnalyticsRefDimension.LAB:
                dimensions = [field, extractFieldLabel(field, LabRefFieldMapper)]
                break
            case AnalyticsRefDimension.MEDICATION:
                dimensions = [field, extractFieldLabel(field, MedicationRefFieldMapper)]
                break
            case AnalyticsRefDimension.MEDICATION_CUI:
                dimensions = [field, extractFieldLabel(field, MedicationCuiRefFieldMapper)]
                break
            case AnalyticsRefDimension.MEDICATION_NDC:
                dimensions = [field, extractFieldLabel(field, MedicationNdcRefFieldMapper)]
                break
            case AnalyticsRefDimension.OBSERVATION:
                dimensions = [field, extractFieldLabel(field, ObservationRefFieldMapper)]
                break
            case AnalyticsRefDimension.PROCEDURE:
                dimensions = [field, extractFieldLabel(field, ProcedureRefFieldMapper)]
                break
            case AnalyticsRefDimension.SPECIALTY:
                dimensions = [field, extractFieldLabel(field, SpecialtyRefFieldMapper)]
                break
            case AnalyticsRefDimension.PHENOTYPE:
                dimensions = [field, extractFieldLabel(field, PhenotypeRefFieldMapper)]
                break
            case AnalyticsRefDimension.PRODUCT_CUSTOM_COHORT:
                dimensions = [field, extractFieldLabel(field, ProductCustomCohortRefFieldMapper)]
                break
        }

        // Iterate set of dimensions found above and conver to `table.dimension`. Skip any
        // where the dimension is an empty string and remove duplicates.
        dimensions = dimensions.reduce((aggregate_dims: string[], dimension: string) => {
            const table_dim = `${table}.${dimension}`

            if (dimension.length > 0 && !aggregate_dims.includes(table_dim)) {
                aggregate_dims.push(table_dim)
            }

            return aggregate_dims
        }, [])

        return dimensions
    }

    const getMeasuresFromFilter = (filter: QueryFilterBase): string[] | undefined => {
        const dimension = deduceDimensionFromTableAndField(filter)
        const { table } = filter

        switch (dimension) {
            case AnalyticsRefDimension.OBSERVATION:
                return [
                    `patient_count`,
                    `total_record_count`,
                    `avg_record_count`,
                    `stddev_record_count`,
                    `median_record_count`,
                    `twenty_fifth_percentile_record_count`,
                    `seventy_fifth_percentile_record_count`,
                    `avg_months_between`,
                    `stddev_months_between`
                ].map((field) => `${table}.${field}`)
            default: {
                return undefined
            }
        }
    }

    const getQueryFromFilter = (filter: QueryFilterBase): ReportQueryFilters => {
        const dimensions = getDimensionsFromFilter(filter)

        return {
            dimensions,
            filters: [
                {
                    or: [
                        {
                            blockId: 0,
                            type: FilterType.FilterDTO,
                            ...filter
                        }
                    ],
                    type: FilterType.OrFilterDTO
                }
            ],
            groupType: DimensionGroupingType.Default
        }
    }

    const getDrilldownQueryFromFilter = (filter: QueryFilterBase): ReportQueryFilters => {
        let dimensions = getDimensionsFromFilter(filter)
        const { table } = filter

        dimensions.push(`${table}.value`)
        dimensions.push(`${table}.value_type`)

        return {
            dimensions,
            filters: [
                {
                    or: [
                        {
                            blockId: 0,
                            type: FilterType.FilterDTO,
                            ...filter
                        }
                    ],
                    type: FilterType.OrFilterDTO
                }
            ],
            groupType: DimensionGroupingType.Default
        }
    }

    let sections: ReportSection[] = [
        {
            id: REPORT_SECTION_ID,
            queries: filters.map(getQueryFromFilter)
        }
    ]

    if (getReportTypeFromFilters(filters) === ReportType.OBSERVATION) {
        const filtersByValues: Record<string, string[]> = filters.reduce((aggDims, filter) => {
            const filterKey = `${filter.table}|${filter.field}|${filter.operator}`
            aggDims[filterKey] = [...(aggDims[filterKey] || []), ...filter.values]

            return aggDims
        }, {})

        const drilldownSummaryQueries: ReportQueryFilters[] = []
        const drilldownQueries: ReportQueryFilters[] = []

        Object.keys(filtersByValues).forEach((filterKey) => {
            const filterKeyValues = filterKey.split('|')

            const dimensionFilter: QueryFilterBase = {
                table: filterKeyValues[0],
                field: filterKeyValues[1],
                operator: filterKeyValues[2],
                values: uniq(filtersByValues[filterKey])
            }
            const query = getDrilldownQueryFromFilter(dimensionFilter)
            drilldownSummaryQueries.push({
                ...query,
                dimensions: getDimensionsFromFilter(dimensionFilter),
                measures: getMeasuresFromFilter(dimensionFilter)
            })
            drilldownQueries.push(query)
        })

        sections.push(
            {
                id: DRILLDOWN_SUMMARY_SECTION_ID,
                queries: drilldownSummaryQueries
            },
            {
                id: DRILLDOWN_SECTION_ID,
                queries: drilldownQueries
            }
        )
    }

    return sections
}

/**
 * Derive the type of report that is being generated from the filters.
 *
 * @param filters A flat list of filters used to generate a report
 * @returns the type of report, derived from the filters
 */
export const getReportTypeFromFilters = (filters: QueryFilterBase[]): ReportType => {
    const firstFilter = filters[0]
    switch (firstFilter.table) {
        case 'patient_diagnosis':
            return ReportType.DIAGNOSIS
        case 'patient_medication':
            return ReportType.MEDICATION
        case 'patient_observation':
            return ReportType.OBSERVATION
        case 'patient_procedure':
            return ReportType.PROCEDURE
        case 'patient_lab':
            return ReportType.LAB_TEST
        default:
            return ReportType.DIAGNOSIS
    }
}

function isOperationNode(node: CohortNode): node is OperationNode {
    return (node as OperationNode).operation !== undefined
}

function isCriterionNode(node: CohortNode): node is CriterionNode {
    return (node as CriterionNode).type !== undefined
}

// Function to check if a node or its children has a specific type
export function hasCriteriaType(node: OperationNode, criteriaType: CriteriaType): boolean {
    for (const child of node.children) {
        if (isOperationNode(child)) {
            if (hasCriteriaType(child, criteriaType)) {
                return true
            }
        } else if (isCriterionNode(child)) {
            if (child.type === criteriaType) {
                return true
            }
        }
    }
    return false
}
