/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CohortCreateDTO } from '../models/CohortCreateDTO'
import type { CohortDTO } from '../models/CohortDTO'
import type { CohortPatchDTO } from '../models/CohortPatchDTO'
import type { PaginatedDTO_CohortDTO_ } from '../models/PaginatedDTO_CohortDTO_'
import type { ReportResultDTO } from '../models/ReportResultDTO'
import type { SqlDTO } from '../models/SqlDTO'
import type { TaskDTO } from '../models/TaskDTO'
import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'
export class ExplorerCohortsService {
    /**
     * Index
     * @returns PaginatedDTO_CohortDTO_ Successful Response
     * @throws ApiError
     */
    public static indexExplorerCohortsGet({
        isSystem = false,
        query,
        page = 1,
        limit = 10
    }: {
        /**
         * True = Get all system cohorts, False = Get all user-created cohorts
         */
        isSystem?: boolean
        /**
         * Search query
         */
        query?: string | null
        /**
         * Page number for pagination, starting at 1
         */
        page?: number
        /**
         * Number of items per page, maximum 1000
         */
        limit?: number
    }): CancelablePromise<PaginatedDTO_CohortDTO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/cohorts',
            query: {
                is_system: isSystem,
                query: query,
                page: page,
                limit: limit
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Create
     * @returns CohortDTO Successful Response
     * @throws ApiError
     */
    public static createExplorerCohortsPost({ requestBody }: { requestBody: CohortCreateDTO }): CancelablePromise<CohortDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/explorer/cohorts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Get
     * @returns CohortDTO Successful Response
     * @throws ApiError
     */
    public static getExplorerCohortsCohortIdGet({ cohortId }: { cohortId: string }): CancelablePromise<CohortDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/cohorts/{cohort_id}',
            path: {
                cohort_id: cohortId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Patch
     * @returns CohortDTO Successful Response
     * @throws ApiError
     */
    public static patchExplorerCohortsCohortIdPatch({
        cohortId,
        requestBody
    }: {
        cohortId: string
        requestBody: CohortPatchDTO
    }): CancelablePromise<CohortDTO> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/explorer/cohorts/{cohort_id}',
            path: {
                cohort_id: cohortId
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Delete
     * @returns string Successful Response
     * @throws ApiError
     */
    public static deleteExplorerCohortsCohortIdDelete({ cohortId }: { cohortId: string }): CancelablePromise<Record<string, string>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/explorer/cohorts/{cohort_id}',
            path: {
                cohort_id: cohortId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Distinct Patients Sql
     * @returns SqlDTO Successful Response
     * @throws ApiError
     */
    public static distinctPatientsSqlExplorerCohortsCohortIdSqlGet({ cohortId }: { cohortId: string }): CancelablePromise<SqlDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/cohorts/{cohort_id}/sql',
            path: {
                cohort_id: cohortId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Export Cohort To Table
     * @returns TaskDTO Successful Response
     * @throws ApiError
     */
    public static exportCohortToTableExplorerCohortsCohortIdExportToTableGet({
        cohortId,
        table,
        database,
        schema,
        sampleSize
    }: {
        cohortId: string
        table?: string | null
        database?: string | null
        schema?: string | null
        sampleSize?: number | null
    }): CancelablePromise<TaskDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/cohorts{cohort_id}/export_to_table',
            path: {
                cohort_id: cohortId
            },
            query: {
                table: table,
                database: database,
                schema: schema,
                sample_size: sampleSize
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
    /**
     * Get Summary Report Data
     * Endpoint to generate a summary report for a cohort.
     * :param cohort_id: UUID of the cohort.
     * :param priority: Priority of the task.
     * :param pg: Postgres session.
     * :param current_user: Current active user.
     * :return: TaskDTO with the task ID.
     * @returns ReportResultDTO Successful Response
     * @throws ApiError
     */
    public static getSummaryReportDataExplorerCohortsCohortIdSummaryReportDataGet({
        cohortId
    }: {
        cohortId: string
    }): CancelablePromise<Array<ReportResultDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/explorer/cohorts/{cohort_id}/summary_report_data',
            path: {
                cohort_id: cohortId
            },
            errors: {
                422: `Validation Error`
            }
        })
    }
}
