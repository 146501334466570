import { yupResolver } from '@hookform/resolvers/yup'
import { t, Trans } from '@lingui/macro'
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack
} from '@mui/material'
import { DeliveryCohortCreateDTO, DeliveryCohortDTO, DeliveryCohortPatchDTO, DeliveryCreateDTO, DeliveryDTO, DeliveryPatchDTO } from '@om1/falcon-api'
import { Auth0State } from '@om1/platform-authentication'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { jwtDecode } from 'jwt-decode'
import { ReactNode, useEffect, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { CohortOwner, Cohorts, dataDeliveryActions, DeliveredDatabase } from '../state/data-delivery'
import { createDataDeliveryCohortInputComponent } from './DataDeliveryCohortInput'

const DataDeliveryCohortInput = createDataDeliveryCohortInputComponent()

type DataDeliveryFormValues = {
    deliveredDatabaseId: string
    cohorts: DeliveryCohortDTO[]
}

export type DataDeliverySaveValues = DataDeliveryFormValues & { id?: string }

export type CreateEditDataDeliveryDialogProps = FrameworkComponentProps<
    {
        cohorts: Cohorts
        cohortsLoading: boolean
        deliveredDatabases: DeliveredDatabase[]
        deliveredDatabasesLoading: boolean
        isLoading: boolean
    } & { auth0: Auth0State },
    Pick<typeof dataDeliveryActions, 'getCohorts' | 'getDeliveredDatabases' | 'dataDeliveriesCreate' | 'dataDeliveriesUpdate'>,
    {
        dataDelivery?: DeliveryDTO
        onCancel: () => void
        onSave: () => void
    }
>

/**
 * A dialog to edit the primary fields for a data delivery such as cohorts, and delivered database.
 */
export const CreateEditDataDeliveryDialogComponent = ({
    state,
    props: { dataDelivery, onCancel, onSave },
    actions
}: CreateEditDataDeliveryDialogProps) => {
    const { cohorts, deliveredDatabases, deliveredDatabasesLoading, isLoading } = state

    const validationSchema = Yup.object().shape({
        cohorts: Yup.array()
            .of(
                Yup.object().shape({
                    cohort: Yup.object().shape({
                        id: Yup.string().required()
                    }),
                    registryType: Yup.string().required(),
                    sampleSize: Yup.number().optional()
                })
            )
            .min(1, t`Must have at least one cohort`)
            .unique(t`Cohorts must be unique per delivery`, (cohort: any) => cohort.cohort.id), // Unique on cohort.id only
        deliveredDatabaseId: Yup.string().required(t`Train is required`)
    })
    const {
        handleSubmit,
        control,
        getValues,
        formState: { errors }
    } = useForm<DataDeliveryFormValues>({
        defaultValues: {
            cohorts: dataDelivery ? dataDelivery.cohorts : [],
            deliveredDatabaseId: dataDelivery ? dataDelivery.deliveredDatabase.id : ''
        },
        resolver: yupResolver(validationSchema)
    })

    const handleCancel = () => onCancel()
    const onSubmit: SubmitHandler<DataDeliveryFormValues> = (formDataDelivery: DataDeliveryFormValues) => {
        if (dataDelivery) {
            const deliveryPatchDTO: DeliveryPatchDTO = {
                deliveredDatabaseId: formDataDelivery.deliveredDatabaseId,
                cohorts: getCohortUpdateValue(formDataDelivery.cohorts, dataDelivery?.cohorts || []),
                organizationId: dataDelivery.organizationId
            }
            actions.dataDeliveriesUpdate({ ...deliveryPatchDTO, deliveryId: dataDelivery.id })
        } else {
            let org_id = ''
            if (state.auth0.accessToken) {
                org_id = jwtDecode(state.auth0.accessToken)['org_id']
            }
            const deliveryCreateDTO: DeliveryCreateDTO = {
                organizationId: org_id,
                deliveredDatabaseId: formDataDelivery.deliveredDatabaseId,
                cohorts: getCohortCreateValue(formDataDelivery.cohorts, [])
            }
            actions.dataDeliveriesCreate(deliveryCreateDTO)
        }
        onSave()
    }

    useEffect(() => {
        actions.getDeliveredDatabases({ query: { limit: 1000, page: 1 }, path: {} })
        actions.getCohorts()
    }, [actions])

    const selectedCohorts = getValues('cohorts')

    const availableCohorts = useMemo(() => {
        return cohorts[CohortOwner.User].filter((c) => {
            return selectedCohorts.findIndex((s) => s.cohort.id === c.id) === -1
        })
    }, [cohorts, selectedCohorts])

    const datasets = useMemo(() => {
        return cohorts[CohortOwner.System]
    }, [cohorts])

    return (
        <Dialog open maxWidth='md' fullWidth onClose={handleCancel}>
            <DialogTitle>
                {dataDelivery ? (
                    <>
                        <Trans>Edit</Trans>
                    </>
                ) : (
                    <Trans>Create a New Data Delivery</Trans>
                )}
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)} data-testid='form'>
                <DialogContent>
                    <Box paddingTop={2}>
                        <Stack spacing={3}>
                            <Controller
                                control={control}
                                name='cohorts'
                                render={({ field: { value, onChange } }) => (
                                    <DataDeliveryCohortInput
                                        availableCohorts={availableCohorts}
                                        helperText={errors.cohorts?.message}
                                        value={value}
                                        onChange={onChange}
                                        disabled={deliveredDatabasesLoading || isLoading}
                                        datasets={datasets}
                                    />
                                )}
                            />
                            <FormControl fullWidth>
                                <InputLabel id='delivered-database-label' required>
                                    <Trans>Train</Trans>
                                </InputLabel>
                                <Controller
                                    control={control}
                                    name='deliveredDatabaseId'
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            id='delivered-database'
                                            labelId='delivered-database-label'
                                            label={<Trans>Select Cohort</Trans>}
                                            required
                                            disabled={deliveredDatabasesLoading || isLoading}
                                            value={value}
                                            error={!!errors['deliveredDatabaseId']}
                                            onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                        >
                                            {deliveredDatabases.map((d) => (
                                                <MenuItem value={d.id} key={d.id}>
                                                    {d.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='primary' onClick={handleCancel} disabled={isLoading}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button variant='contained' type='submit' disabled={isLoading}>
                        <Trans>Save</Trans>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

function getCohortUpdateValue(cohorts: DeliveryCohortDTO[], existingCohorts: DeliveryCohortDTO[]): DeliveryCohortPatchDTO[] | null {
    // Include the ID of any existing cohort ID and registry type pairing.
    const deliveryCohortPatchDTOs: DeliveryCohortPatchDTO[] = []
    cohorts.forEach((c) => {
        const existingCohortIndex = existingCohorts.findIndex((e) => e.cohort.id === c.cohort.id && e.registryType === c.registryType)
        if (existingCohortIndex === -1) {
            deliveryCohortPatchDTOs.push({ id: c.id, cohortId: c.cohort.id, registryType: c.registryType, sampleSize: c.sampleSize })
        } else {
            const {
                id,
                cohort: { id: cohortId },
                registryType
            } = existingCohorts[existingCohortIndex]
            deliveryCohortPatchDTOs.push({ id, cohortId, registryType, sampleSize: c.sampleSize })
        }
    })

    return deliveryCohortPatchDTOs
}

function getCohortCreateValue(cohorts: DeliveryCohortDTO[], existingCohorts: DeliveryCohortDTO[]): DeliveryCohortCreateDTO[] {
    // Include the ID of any existing cohort ID and registry type pairing.
    const deliveryCohortCreateDTOs: DeliveryCohortCreateDTO[] = []
    cohorts.forEach((c) => {
        const existingCohortIndex = existingCohorts.findIndex((e) => e.cohort.id === c.cohort.id && e.registryType === c.registryType)
        if (existingCohortIndex === -1) {
            deliveryCohortCreateDTOs.push({ cohortId: c.cohort.id, registryType: c.registryType, sampleSize: c.sampleSize })
        } else {
            const {
                cohort: { id: cohortId },
                registryType,
                sampleSize
            } = existingCohorts[existingCohortIndex]
            deliveryCohortCreateDTOs.push({ cohortId, registryType, sampleSize })
        }
    })

    return deliveryCohortCreateDTOs
}
