import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogTitle, IconButton } from '@mui/material'
import { FrameworkComponentProps } from '@om1/platform-utils'
import { useCallback, useMemo } from 'react'
import { cohortBlocksEditActions, CriterionNode } from '../../../state'
import { createObservationNumericScoreEditDialogComponent } from './ObservationNumericScoreEditDialog'

const ObservationNumericScoreEditDialogComponent = createObservationNumericScoreEditDialogComponent()

export enum ObservationScoreField {
    Numeric = 'numeric',
    Text = 'text',
    Date = 'date'
}

export type ObservationScoreEditDialogComponentProps = FrameworkComponentProps<
    {
        resultTypeMap: Record<string, string | null>
        criteria?: CriterionNode
    },
    typeof cohortBlocksEditActions
>

export const ObservationScoreEditDialogComponent = ({ state, actions }: ObservationScoreEditDialogComponentProps) => {
    const { resultTypeMap, criteria } = state

    const observationType = useMemo(() => {
        const filterValue = criteria?.filters[0].values[0]

        if (filterValue && resultTypeMap[filterValue]) {
            return resultTypeMap[filterValue]
        }

        return null
    }, [criteria, resultTypeMap])

    const renderQualifierForm = useCallback(() => {
        if (observationType === ObservationScoreField.Numeric) {
            return <ObservationNumericScoreEditDialogComponent />
        }

        if (observationType === ObservationScoreField.Text) {
            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
            alert('Text input not implemented')
            actions.observationScoreEditDialogTrigger({})
        }

        if (observationType === ObservationScoreField.Date) {
            // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
            alert('Date input not implemented')
            actions.observationScoreEditDialogTrigger({})
        }

        return <></>
    }, [observationType, actions])

    const onCancel = () => {
        actions.observationScoreEditDialogTrigger({})
    }

    return (
        <Dialog
            open
            fullWidth
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '700px'
                    }
                }
            }}
        >
            <DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={onCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {renderQualifierForm()}
        </Dialog>
    )
}
