import { cohortListActions, CohortState } from '@om1/cohort-module/src/state'
import { insightsLibraryActions } from '@om1/insights-library-module/src/state/insights-library/insights-library-actions'
import { InsightsLibraryState } from '@om1/insights-library-module/src/state/insights-library/insights-library-state'
import { Auth0State } from '@om1/platform-authentication'
import { withRouter } from '@om1/platform-routing'
import { PlatformSettingsState } from '@om1/platform-settings-module'
import { connectRedux } from '@om1/platform-utils'
import { ManageInsightsLibraryPageComponent } from './ManageInsightsLibraryComponent'

export function createManageInsightsLibraryPage<
    TState extends { insightsLibrary: InsightsLibraryState; auth0: Auth0State; cohort: CohortState; platformSettings: PlatformSettingsState }
>() {
    return connectRedux(
        withRouter(ManageInsightsLibraryPageComponent, { namespace: 'insights-library', dataFetchAction: 'getInsightsLibraryReports' }),
        (state: TState) => {
            return {
                insightsLibrary: state.insightsLibrary,
                permissions: state.auth0.permissions
            }
        },
        { ...insightsLibraryActions, ...cohortListActions }
    )
}

export type ManageInsightsLibraryPage = ReturnType<typeof createManageInsightsLibraryPage>
