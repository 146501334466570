import { AnalyticsRefTableParam, ExplorerAnalyticsRefService, PaginatedDictDTO_str_Any_ } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import camelcaseKeys from 'camelcase-keys'
import { all, call, put, select } from 'redux-saga/effects'
import {
    AnalyticsRefDestination,
    AnalyticsRefDimension,
    cohortEditActions,
    CohortState,
    DiagnosisIcd10Ref,
    LabRef,
    MedicationCuiRef,
    MedicationNdcRef,
    MedicationRef,
    ObservationRef,
    PhenotypeRef,
    ProductCustomCohortRef,
    RaceRef,
    SexRef,
    SpecialtyRef,
    StateRef
} from '../state'
import { analyticsRefActions } from '../state/refs/analytics-refs-actions'

export const AnalyticsDimensionToTableName: Record<AnalyticsRefDimension, AnalyticsRefTableParam> = {
    [AnalyticsRefDimension.RACE]: 'ref_race',
    [AnalyticsRefDimension.SEX]: 'ref_sex',
    [AnalyticsRefDimension.STATE]: 'ref_state',
    [AnalyticsRefDimension.DIAGNOSIS_ICD10]: 'ref_diagnosis',
    [AnalyticsRefDimension.DIAGNOSIS_ICD10_GEM_MAPPED]: 'ref_diagnosis',
    [AnalyticsRefDimension.DIAGNOSIS_CUI]: 'ref_diagnosis_boc_cui',
    [AnalyticsRefDimension.LAB]: 'ref_lab',
    [AnalyticsRefDimension.MEDICATION]: 'ref_medication',
    [AnalyticsRefDimension.MEDICATION_CUI]: 'ref_medication_boc_cui',
    [AnalyticsRefDimension.MEDICATION_NDC]: 'ref_medication_ndc',
    [AnalyticsRefDimension.OBSERVATION]: 'ref_observation',
    [AnalyticsRefDimension.PROCEDURE]: 'ref_procedure',
    [AnalyticsRefDimension.SPECIALTY]: 'ref_specialty',
    [AnalyticsRefDimension.PHENOTYPE]: 'ref_phenotype',
    [AnalyticsRefDimension.PRODUCT_CUSTOM_COHORT]: 'ref_product_custom_cohort'
}

export function createGetDemographicRefsSaga() {
    return function* () {
        yield put(cohortEditActions.setDemographicsRefsLoading({ loading: true }))
        try {
            const [state, race, sex] = yield select((store: { cohort: CohortState }) => [
                store.cohort.refs[AnalyticsRefDimension.STATE][AnalyticsRefDestination.SEARCH].data,
                store.cohort.refs[AnalyticsRefDimension.RACE][AnalyticsRefDestination.SEARCH].data,
                store.cohort.refs[AnalyticsRefDimension.SEX][AnalyticsRefDestination.SEARCH].data
            ])
            const dimensions: AnalyticsRefDimension[] = []
            if (state.length === 0) {
                dimensions.push(AnalyticsRefDimension.STATE)
            }
            if (race.length === 0) {
                dimensions.push(AnalyticsRefDimension.RACE)
            }
            if (sex.length === 0) {
                dimensions.push(AnalyticsRefDimension.SEX)
            }

            const calls = dimensions.map((dimension) =>
                call(ExplorerAnalyticsRefService.indexExplorerAnalyticsRefRefTableNameGet as any, {
                    refTableName: AnalyticsDimensionToTableName[dimension]
                })
            )

            const results: PaginatedDictDTO_str_Any_[] = yield all(calls)

            for (let i = 0; i < dimensions.length; i++) {
                const dimension = dimensions[i]
                const result = results[i]
                yield put(
                    analyticsRefActions.setRefs({
                        dimension,
                        destination: AnalyticsRefDestination.SEARCH,
                        data: camelcaseKeys(result.data) as
                            | StateRef[]
                            | RaceRef[]
                            | SexRef[]
                            | DiagnosisIcd10Ref[]
                            | MedicationRef[]
                            | MedicationCuiRef[]
                            | MedicationNdcRef[]
                            | LabRef[]
                            | SpecialtyRef[]
                            | PhenotypeRef[]
                            | ProductCustomCohortRef[]
                            | ObservationRef[],
                        paginationMeta: result.meta
                    })
                )
            }
        } catch (error: any) {
            yield handleApiError(error)
        } finally {
            yield put(cohortEditActions.setDemographicsRefsLoading({ loading: false }))
        }
    }
}

export type GetDemographicRefsSaga = ReturnType<typeof createGetDemographicRefsSaga>
