import { t } from '@lingui/macro'
import { ExplorerTasksService, OpenAPI, TaskDTO } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { notificationActions } from '@om1/platform-notifications'
import { EventChannel } from 'redux-saga'
import { call, put, take } from 'redux-saga/effects'
import { cohortEditActions } from '../state'
import { taskSocket } from './task-socket'

export function createGetCohortAttritionFunnelSaga() {
    return function* (action: ReturnType<typeof cohortEditActions.cohortAttritionFunnelGet>) {
        const { cohortId } = action.payload

        yield put(cohortEditActions.cohortAttritionFunnelLoadingSet({ cohortId: action.payload.cohortId, loading: true }))
        yield put(cohortEditActions.cohortAttritionFunnelSet({ cohortId: cohortId, attritionFunnel: undefined }))

        let taskId
        try {
            const taskDTO: TaskDTO = yield call(
                ExplorerTasksService.generateCohortAttritionFunnelExplorerTasksCohortCohortIdAttritionFunnelGenerateGet,
                {
                    cohortId: cohortId
                }
            )
            taskId = taskDTO.taskId
        } catch (e) {
            yield put(notificationActions.error(t`Could not get task id`))
            yield put(cohortEditActions.cohortAttritionFunnelLoadingSet({ cohortId: action.payload.cohortId, loading: false }))
        }

        if (taskId) {
            try {
                const eventChannel: EventChannel<any> = yield call(taskSocket, taskId, OpenAPI.TOKEN)
                const socketResponse = JSON.parse(yield take(eventChannel)) as {
                    taskId: string
                    status: string
                    result: any
                }
                if (socketResponse.status === 'SUCCESS') {
                    yield put(cohortEditActions.cohortAttritionFunnelSet({ cohortId: cohortId, attritionFunnel: socketResponse.result.sizes }))
                } else {
                    yield put(notificationActions.error(t`Could not generate attrition funnel.`))
                }
            } catch (error) {
                yield handleApiError(error)
            }
        }
        yield put(cohortEditActions.cohortAttritionFunnelLoadingSet({ cohortId: action.payload.cohortId, loading: false }))
    }
}

export type GetCohortAttritionFunnelSaga = ReturnType<typeof createGetCohortAttritionFunnelSaga>
