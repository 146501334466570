import { IdToken, User } from '@auth0/auth0-react'
import { jwtDecode } from 'jwt-decode'
import { Auth0ActionTypes, Auth0Actions } from './actions'

export interface Auth0State {
    idToken?: IdToken
    user?: User
    accessToken?: string
    permissions?: string[]
    refreshToken?: string
}

export const initialAuth0State: Auth0State = {}

export const getAuth0State = (state: { auth0: Auth0State }) => state.auth0

export function auth0Reducer(previousState: Auth0State | undefined, action: Auth0Actions): Auth0State {
    const state = previousState || initialAuth0State

    switch (action.type) {
        case Auth0ActionTypes.SET_AUTH0_SET_ID_TOKEN:
            return {
                ...state,
                idToken: action.payload.token
            }
        case Auth0ActionTypes.SET_AUTH0_SET_ACCESS_TOKEN:
            return {
                ...state,
                accessToken: action.payload.accessToken,
                permissions: action.payload.accessToken ? jwtDecode(action.payload.accessToken)['permissions'] : undefined
            }
        case Auth0ActionTypes.SET_AUTH0_SET_USER:
            return {
                ...state,
                user: action.payload.user
            }
        case Auth0ActionTypes.SET_AUTH0_SET_REFRESH_TOKEN:
            return {
                ...state,
                refreshToken: action.payload.refreshToken
            }
        default:
            return state
    }
}
