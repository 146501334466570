import { t } from '@lingui/macro'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Collapse, IconButton, styled, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useRef } from 'react'
import { DepthCheckTreeManager } from './DepthCheckTreeManager'
import { NestedCheckTreeManager } from './NestedCheckTreeManager'

const ChildCountSpan = styled('span')(({ theme }) => ({
    fontWeight: 'lighter',
    color: theme.palette.grey[500],
    paddingLeft: 5
}))

export enum Checked {
    N, // Unchecked
    Y, // Checked
    I, // Indeterminate
    D // Disabled
}

export interface TreeItem<T> {
    id: string
    source: T
    expanded: boolean
    order: string[] // The order in which children will appear in the tree
    children: Map<string, TreeItem<T>> // The actual children, keyed by the value in the order array
}

/**
 * A callback used to return a string value for a generic tree item, given a certain depth in the tree.
 *
 * @example For a tree depth level of 3, return the propery `item_level_3_label` for its display label.
 */

export interface TreeSelection<T> {
    source: T
    depth: number
}

export interface CheckTreeProps<T> {
    tree: TreeItem<T>
    manager: DepthCheckTreeManager<T> | NestedCheckTreeManager<T>
}

/**
 * A hierarchical, collapsable checkbox tree for selecting any type of resource. It is responsible for rendering only,
 * and requires a TreeDialogManager to manage its state.
 */
export const CheckTree = <T,>({ tree, manager }: CheckTreeProps<T>) => {
    return (
        <div>
            {tree.order.map((value) => (
                <CheckTreeNode key={value} node={tree.children.get(value)!} depth={0} manager={manager} />
            ))}
        </div>
    )
}

const BUTTON_SIZE = 14
const GAP_SIZE = 8

interface CheckTreeNodeProps<T> {
    node: TreeItem<T>
    depth: number
    manager: DepthCheckTreeManager<T> | NestedCheckTreeManager<T>
}

/**
 * A checkable node in the check tree. Renders itself and any children it may contain.
 */
const CheckTreeNode = <T,>({ node, depth, manager }: CheckTreeNodeProps<T>) => {
    const ref = useRef<HTMLInputElement>(null)

    const label = manager.getItemLabel(node.source, depth) || ''
    const childCount = manager.getItemChildCount ? manager.getItemChildCount(node.source, depth) : ''
    const hasChildren = !manager.ignoreChildren && node.order.length > 0
    const children = manager.ignoreChildren
        ? []
        : node.order.map((value) => {
              return <CheckTreeNode key={value} depth={depth + 1} node={node.children.get(value)!} manager={manager} />
          })
    const tooltip = manager.getItemTooltip(node.source, depth) || ''
    const displayStatus = manager.displayStatus(node.source, depth)
    useEffect(() => {
        if (ref.current) {
            ref.current.indeterminate = displayStatus === Checked.I
        }
    }, [displayStatus])
    if (label === '{component}') {
        return <Box>{children}</Box>
    } else {
        return (
            <div>
                <Box display='flex' alignItems='center' gap={1}>
                    {hasChildren && (
                        <Box flexShrink={0}>
                            <IconButton
                                sx={{ width: BUTTON_SIZE, height: BUTTON_SIZE }}
                                size='small'
                                onClick={() => {
                                    if (node.expanded) {
                                        manager.collapse({ source: node.source, depth })
                                    } else {
                                        manager.expand({ source: node.source, depth })
                                    }
                                }}
                                aria-label={node.expanded ? t`Collapse` : t`Expand`}
                                aria-expanded={node.expanded}
                            >
                                <ExpandMoreIcon
                                    sx={{
                                        transition: (theme) =>
                                            theme.transitions.create(['transform'], { duration: theme.transitions.duration.shortest }),
                                        transform: node.expanded ? undefined : 'rotate(-90deg)'
                                    }}
                                />
                            </IconButton>
                        </Box>
                    )}
                    {!hasChildren && <Box flexShrink={0} flexBasis={BUTTON_SIZE} />}
                    <div>
                        <input
                            ref={ref}
                            type='checkbox'
                            id={node.id}
                            disabled={displayStatus === Checked.D}
                            checked={displayStatus !== Checked.N}
                            style={{ width: BUTTON_SIZE, height: BUTTON_SIZE, margin: 0 }}
                            onChange={() => {
                                if (displayStatus === Checked.Y) {
                                    manager.unstage({ source: node.source, depth })
                                } else {
                                    manager.stage({ source: node.source, depth })
                                }
                            }}
                        />
                    </div>
                    <Box minWidth={0}>
                        <label htmlFor={node.id}>
                            <Typography noWrap title={tooltip}>
                                {label} {hasChildren && <ChildCountSpan>{childCount}</ChildCountSpan>}
                            </Typography>
                        </label>
                    </Box>
                </Box>
                {hasChildren && (
                    <Collapse in={node.expanded} mountOnEnter unmountOnExit role='group'>
                        <Box marginLeft={BUTTON_SIZE + GAP_SIZE + BUTTON_SIZE / 2 - BUTTON_SIZE / 2 + 'px'}>{children}</Box>
                    </Collapse>
                )}
            </div>
        )
    }
}
