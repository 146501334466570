import { CohortEditActionTypes, CohortEditActions } from './cohort-base-edit-actions'
import { CohortBaseEditState, initialCohortBaseEditState } from './cohort-base-edit-state'

export function cohortEditReducer(previousState: CohortBaseEditState | undefined, action: CohortEditActions): CohortBaseEditState {
    const state = previousState || initialCohortBaseEditState

    switch (action.type) {
        case CohortEditActionTypes.COHORT_EDIT_LOADING_SET: {
            return { ...state, ui: { ...state.ui, loading: action.payload.loading } }
        }
        case CohortEditActionTypes.COHORT_UPDATE_LOADING_SET: {
            return { ...state, ui: { ...state.ui, cohortUpdateLoading: action.payload.loading } }
        }
        case CohortEditActionTypes.COHORT_SET: {
            return { ...state, cohort: action.payload.cohort }
        }

        case CohortEditActionTypes.COHORT_CLEAR: {
            return initialCohortBaseEditState
        }
        case CohortEditActionTypes.COHORT_SIZE_SET: {
            if (state.cohort.id === action.payload.cohortId) {
                return { ...state, cohort: { ...state.cohort, cohortSize: action.payload.cohortSize, isStale: false } }
            } else {
                return state
            }
        }
        case CohortEditActionTypes.COHORT_SQL_LOADING_SET: {
            return { ...state, ui: { ...state.ui, cohortSqlLoading: action.payload.loading } }
        }
        case CohortEditActionTypes.COHORT_SQL_SET: {
            return { ...state, sql: action.payload.sql }
        }
        case CohortEditActionTypes.DEMOGRAPHICS_REFS_LOADING: {
            return { ...state, ui: { ...state.ui, demographicsDialog: { ...state.ui.demographicsDialog, loading: action.payload.loading } } }
        }
        case CohortEditActionTypes.SET_ANALYTICS_REPORTS_TASK_ID: {
            return { ...state, cohort: { ...state.cohort, summaryReportTaskId: action.payload.taskId } }
        }
        case CohortEditActionTypes.COHORT_ATTRITION_FUNNEL_SET: {
            if (state.cohort.id === action.payload.cohortId) {
                return { ...state, cohort: { ...state.cohort, attritionFunnel: action.payload.attritionFunnel } }
            } else {
                return state
            }
        }
        case CohortEditActionTypes.COHORT_ATTRITION_FUNNEL_LOADING_SET: {
            return { ...state, ui: { ...state.ui, cohortAttritionFunnelLoading: action.payload.loading } }
        }
        default:
            return state
    }
}
